//----------------------------------------------------
//      all the print styles
//----------------------------------------------------

@media print {
    @page {
        margin: 2cm;
    }

    body {
        color: #000;
        background-color: #fff;
        font-size: 13pt;
        line-height: 1.5;
    }

    //----------------------------------------------------
    //      hide all non-printy things
    //----------------------------------------------------

    .site-search,
    .site-nav-fake,
    .site-nav,
    .old-browser,
    .site-footer-nav,
    .site-social-links,
    .site-supporters,
    .logo-strapline,
    .secondary-nav,
    .content-share,
    .content-filters,
    .page-header-image-contain,
    .content-aside,
    .donate-feature-block {
        display: none;
    }

    //----------------------------------------------------
    //      fix logo so you can see site name
    //----------------------------------------------------

    .site-logo {
        text-indent: 0;
        white-space: wrap;
        overflow: visible;
        width: auto;
        height: auto;
        float: none;
        clear: both;
        background-image: none;
        font-size: 20pt;
        color: #000;
        font-weight: bold;
        padding-bottom: .5cm;
        border-bottom: 1px solid #000;
        margin-bottom: 1cm;
    }

    //----------------------------------------------------
    //      fix page headers
    //----------------------------------------------------

    .page-header {
        color: #000;
        height: auto;
        background-color: #fff;
        padding-bottom: .5cm;
        border-bottom: 1px solid #000;
        margin-bottom: 1cm;

        .contain {
            padding-top: 0;
            padding-bottom: 0;
            margin-bottom: 0;
        }

        h1,
        p {
            width: 100%;
            float: none;
            clear: both;
        }

        p {
            font-size: 15pt;
        }
    }

    //----------------------------------------------------
    //      accordion fixes
    //----------------------------------------------------

    .content-accordion-section {
        border-top: 0;

        &:last-of-type {
            border-bottom: 0;
        }
    }

    .content-accordion-text {
        padding: 0;
        margin: 0 0 .5cm;

        &[aria-hidden="true"] {
            visibility: visible !important;
            height: auto !important;
        }
    }

    .content-accordion-toggle {
        font-size: 15pt;
        line-height: 1.5;
        margin: 0 0 .25cm;
        padding: 0;
        color: #000;

        &:before {
            display: none;
        }

        &.active {
            &:before {
                display: none;
            }
        }
    }

    //----------------------------------------------------
    //      content area fixes
    //----------------------------------------------------

    .content {
        float: none;
        clear: both;
        width: 100%;
        padding-top: 0;
        margin-bottom: .5cm;
    }

    //----------------------------------------------------
    //      research project show sidebar
    //----------------------------------------------------

    .content-aside {
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 1cm;
    }

    .content-aside.content-aside-project {
        display: block;
        width: 100%;
        clear: both;
        margin-bottom: .5cm;

        > .link-button:only-of-type {
            display: none;
        }
    }

    .project-information {
        padding: 0;
        border: 0;
        margin-bottom: 0;
    }

    .project-lead-researcher {
        position: static;
        top: auto;
        left: auto;
        margin-bottom: .5cm;

        &:before,
        &:after {
            display: none;
        }

        img {
            position: static;
            top: auto;
            left: auto;
            transform: translate(0,0);
            min-width: 0;
            max-width: 100%;
        }
    }

    //----------------------------------------------------
    //      information sidebar for events
    //----------------------------------------------------

    .content-aside.content-aside-event {
        display: block;
        width: 100%;
        clear: both;
        margin-bottom: .5cm;

        > .link-button {
            display: none;
        }
    }

    .event-logo {
        clear: both;
        margin-top: 0;
        margin-bottom: .5cm;
    }

    .event-information {
        ul {
            margin-bottom: 0;
        }

        li {
            display: inline-block;
            margin-right: 1cm;
            margin-bottom: 1cm;
        }
    }
}
