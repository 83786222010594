//----------------------------------------------------
//      big quote block (homepage)
//----------------------------------------------------

.quote-block {
    background-image: url(../img/hex-bottom-right.png);
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: auto 160px;

    @include mq($until: tablet) {
        background-image: none;
    }
}

.quote {
    position: relative;
    height: 530px;

    @include mq($from: desktop, $until: wide) {
        height: 430px;
    }

    @include mq($from: tablet, $until: desktop) {
        height: 480px;
    }

    @include mq($from: mobileLandscape, $until: tablet) {
        height: auto;
    }

    @include mq($until: mobileLandscape) {
        height: auto;
    }
}

.quote-image {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: (1100/1600)*100%;
    z-index: 1;
    background-image: url(../img/quote-placeholder.jpg);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;

    &:after {
        content: '';
        display: block;
        height: 0;
        width: 0;
        border-left: 330px solid transparent;
        border-top: 550px solid #fff;
        position: absolute;
        right: 0;
        bottom: 0;
    }

    @include mq($from: tablet, $until: desktop) {
        width: 85%;
    }

    @include mq($from: desktop, $until: wide) {
        width: (800/1024)*100%;
    }

    @include mq($until: tablet) {
        position: relative;
        left: auto;
        top: auto;
        bottom: auto;
        height: 200px;
        width: 100%;

        &:after {
            border-left: 180px solid transparent;
            border-bottom: 0;
            border-top: 300px solid #fff;
            left: 85%;
            right: auto;
            top: 0;
        }
    }
}

.quote-content {
    position: absolute;
    right: 0;
    top: 50px;
    bottom: 0;
    width: (850/1600)*100%;
    padding: 40px 50px;
    z-index: 2;
    background-color: $orange;
    color: #fff;
    @include cf;

    blockquote {
        margin-bottom: 30px;
    }

    p {
        font-size: 42px;
        line-height: 45px;
        margin-bottom: 15px;
        font-weight: $font-weight-bold;
        max-width: 650px;

        &:before {
            content: '“';
        }

        &:after {
            content: '”';
        }
    }

    cite {
        display: block;
    }

    @include mq($from: desktop, $until: wide) {
        width: (550/1024)*100%;

        p {
            font-size: 28px;
            line-height: 31px;
        }
    }

    @include mq($from: tablet, $until: desktop) {
        width: (400/768)*100%;
        padding: 30px;
        top: 30px;

        p {
            font-size: 28px;
            line-height: 31px;
        }

        cite {
            font-size: 16px;
            line-height: (20/16)*100%;
        }
    }

    @include mq($until: tablet) {
        position: relative;
        top: auto;
        right: auto;
        width: 100%;
        padding: 20px;
        top: auto;
        bottom: auto;

        p {
            font-size: 30px;
            line-height: 33px;
        }
    }

    @include mq($from: mobileLandscape, $until: tablet) {
        height: auto;
    }

    @include mq($until: mobileLandscape) {
        height: auto;
    }
}

//----------------------------------------------------
//      alternate quote style
//----------------------------------------------------

.quote-slim {
    position: relative;
    padding-top: 50px;
    padding-bottom: 50px;
    @include cf;

    @include mq($from: tablet, $until: desktop) {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    @include mq($until: tablet) {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

.quote-slim-image {
    float: right;
    width: 270px;
    height: 234px;
    background-image: url(../img/quote-placeholder.jpg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    overflow: hidden;

    > div {
        background-image: url(../img/hex-mask.png);
        background-repeat: no-repeat;
        background-position: 0 50%;
        background-size: cover;
        width: 100%;
        height: 100%;
    }

    @include mq($until: tablet) {
        float: none;
        clear: both;
        position: relative;
        left: 50%;
        transform: translate(-50%,0);
        margin-bottom: 30px;
    }
}

.quote-slim-content {
    width: (890/1200)*100%;
    max-width: calc(100% - 310px);
    float: left;
    @include cf;

    blockquote {
        margin-bottom: 15px;
    }

    p {
        font-size: 28px;
        font-weight: bold;
        line-height: 30px;
        margin-bottom: 10px;
        position: relative;
        padding-top: 40px;
        color: $blue;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 40px;
            height: 30px;
            background-image: url(../img/quote-mark.png);
            background-repeat: no-repeat;
            background-position: 0 0;
            background-size: contain;
        }
    }

    cite {
        display: block;
        font-size: 16px;
        margin-bottom: 0;
    }

    @include mq($from: desktop, $until: wide) {
        h2 {
            font-size: 28px;
        }
    }

    @include mq($from: tablet, $until: desktop) {
        p {
            font-size: 28px;
        }

        cite {
            font-size: 16px;
            line-height: (20/16)*100%;
        }
    }

    @include mq($until: tablet) {
        width: 100%;
        max-width: 100%;
        float: none;
        clear: both;

        p {
            font-size: 30px;
        }
    }

    @include mq($from: mobileLandscape, $until: tablet) {
        height: auto;
    }

    @include mq($until: mobileLandscape) {
        height: auto;
    }
}
