//----------------------------------------------------
//      callout on homepage
//----------------------------------------------------

.callout {
    position: relative;
    height: 440px;

    @include mq($from: mobileLandscape, $until: tablet) {
        height: auto;
    }

    @include mq($until: mobileLandscape) {
        height: auto;
    }
}

.callout-image {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: (1100/1600)*100%;
    z-index: 1;
    background-image: url(../img/callout-placeholder.jpg);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;

    &:after {
        content: '';
        display: block;
        height: 0;
        width: 0;
        border-left: 330px solid transparent;
        border-top: 550px solid #fff;
        position: absolute;
        right: 0;
        bottom: 0;
    }

    @include mq($from: tablet, $until: desktop) {
        width: 85%;
    }

    @include mq($from: desktop, $until: wide) {
        width: (800/1024)*100%;
    }

    @include mq($until: tablet) {
        position: relative;
        left: auto;
        top: auto;
        bottom: auto;
        height: 200px;
        width: 100%;

        &:after {
            border-left: 180px solid transparent;
            border-bottom: 0;
            border-top: 300px solid #fff;
            left: 85%;
            right: auto;
            top: 0;
        }
    }
}

.callout-content {
    position: absolute;
    right: 0;
    top: 80px;
    bottom: 40px;
    width: (850/1600)*100%;
    padding: 40px 50px;
    z-index: 2;
    background-color: $orange;
    color: #fff;
    @include cf;

    h2 {
        font-size: $font-size-h1;
    }

    @include mq($from: desktop, $until: wide) {
        width: (550/1024)*100%;
        top: 40px;

        h2 {
            font-size: 28px;
        }
    }

    @include mq($from: tablet, $until: desktop) {
        width: (400/768)*100%;
        padding: 30px;
        top: 30px;
        bottom: 30px;

        h2 {
            font-size: 28px;
        }
    }

    @include mq($until: tablet) {
        position: relative;
        top: auto;
        right: auto;
        width: 100%;
        padding: 20px;
        top: auto;
        bottom: auto;

        h2 {
            font-size: 30px;
        }
    }

    @include mq($from: mobileLandscape, $until: tablet) {
        height: auto;
    }

    @include mq($until: mobileLandscape) {
        height: auto;
    }
}
