/* Form section */

.form {
    display: flex;
    transition: background .4s ease;
    svg {
        max-width: 100%;
    }

    .final-stage-form {
        display: none;
    }

    &.final-stage {
        .form-inside {
            background: $blue-dark;
            text-align: center;

            .form-wrapper {
                border-radius: 10px;

                @include mq($until: tablet) {
                    padding: 50px 0;
                }

                .form-output {
                    width: 0%;
                    height: 0px;
                    opacity: 0;
                    z-index: -1;
                }

                form {
                    width: 100%;
                }

                .donate-inside {
                    max-width: 700px;
                    width: 100%;
                    margin: 0 auto;

                    p.matched-donation-visible,
                    p.matched-donation-hidden {

                        @include mq($until: tablet) {
                            display: none;
                        }
                    }

                    .donation-amounts {
                        max-width: 600px;
                        margin: 20px auto 0;

                        @include mq($until: tablet) {
                            margin: 10px auto 0;
                        }
                    }

                    .donation-amount-label {
                        position: relative;
                        width: 80px;
                        height: 80px;
                        margin-right: 20px;
                        padding: 0;
                        font-size: 30px;
                        line-height: 80px;
                        font-weight: 600;
                        color: $pink;
                        background-color: white;
                        box-sizing: border-box;

                        @media screen and (max-width: 767px) {
                            width: 70px;
                            height: 70px;
                            margin-right: 5px;
                            line-height: 70px;
                            font-size: 28px;

                            &:nth-of-type(3) {
                                margin-right: 0;
                            }
                        }

                        &-other {
                            width: 110px;
                            margin-right: 0;

                            span {
                                position: relative;
                                font-size: 24px;
                                color: $pink;
                                bottom: 2px;
                            }

                            @media screen and (max-width: 767px) {
                                width: 230px;
                                height: 70px;
                                margin-top: 5px;
                                font-size: 22px;

                                span {
                                    bottom: 0;
                                }
                            }
                        }

                        &::before,
                        &::after {
                            display: none;
                        }
                    }

                    #donation-amount-other-input {
                        display: none;
                        position: absolute;
                        bottom: 10px;
                        left: 10px;
                        width: calc(100% - 20px);
                        padding: 0;
                        font-size: 24px;

                        @media screen and (max-width: 767px) {
                            width: 40%;
                            bottom: 11px;
                            left: 70px;
                            font-size: 40px;
                            border: 0;
                        }
                    }

                    #donation-amount-other:checked + .donation-amount-label {

                        span {
                            bottom: 11px;
                            color: white;
                        }

                        #donation-amount-other-input {
                            display: block;

                            &::-webkit-inner-spin-button,
                            &::-webkit-outer-spin-button {
                                -webkit-appearance: none;
                                margin: 0;
                            }

                            @media (max-width: 29.99em) {
                                line-height: 24px;
                            }
                        }

                        @media screen and (max-width: 767px) {
                            span {
                                bottom: 0;
                                right: 45px;
                            }
                        }
                    }

                    input[name="donation-amount"]:checked + .donation-amount-label {
                        color: white;
                        background-color: $pink;

                        @media screen and (max-width: 767px) {
                            background-color: #fff;
                            color: $blue-mid;
                        }
                    }

                    .donation-details-container {
                        position: relative;
                        min-height: 75px;
                        max-width: 500px;
                        margin: 30px auto 0;
                        padding: 15px;
                        border: 1px solid $pink;

                        @include mq($until: tablet) {
                            margin: 15px auto 0;
                        }

                        @media screen and (max-width: 500px) {
                            display: none;
                        }

                        &::before,
                        &::after {
                            content: "";
                            display: block;
                            position: absolute;
                            width: 0;
                            height: 0;
                            border-style: solid;
                            transition: left .2s ease;

                            @media screen and (max-width: 767px) {
                                display: none;
                            }
                        }

                        &::after {
                            top: -40px;
                            left: 161px;
                            border-color: transparent transparent $blue-dark transparent ;
                            border-width: 20px;
                        }

                        &::before {
                            top: -43px;
                            left: 160px;
                            border-color: transparent transparent $pink transparent;
                            border-width: 21px;
                        }

                        &.single {
                            .text-single {
                                display: block;
                            }
                        }

                        &.monthly {
                            .text-monthly {
                                display: block;
                            }
                        }

                        &[display-detail="first"] {

                            .donation-details-first {
                                display: flex;
                            }

                            &::before {
                                left: calc(56 / 500 * 100%);
                            }

                            &::after {
                                left: calc(57 / 500 * 100%);
                            }
                        }

                        &[display-detail="second"] {

                            .donation-details-second {
                                display: flex;
                            }

                            &::before {
                                left: calc(160 / 500 * 100%);
                            }

                            &::after {
                                left: calc(161 / 500 * 100%);
                            }
                        }

                        &[display-detail="third"] {

                            .donation-details-third {
                                display: flex;
                            }

                            &::before {
                                left: calc(265 / 500 * 100%);
                            }

                            &::after {
                                left: calc(266 / 500 * 100%);
                            }
                        }

                        &[display-detail="other"] {

                            .donation-details-other {
                                display: flex;
                            }

                            &::before {
                                left: calc(385 / 500 * 100%);
                            }

                            &::after {
                                left: calc(386 / 500 * 100%);
                            }
                        }
                    }

                    .donation-details {
                        display: none;
                        justify-content: space-around;
                        align-items: center;
                        position: relative;
                        height: 100%;
                        text-align: left;

                        .amount {
                            width: 50px;
                            font-size: 30px;
                            line-height: 30px;
                            margin-right: 25px;
                        }

                        .text-single,
                        .text-monthly {
                            display: none;
                            margin: 0;
                            font-size: 16px;
                            line-height: 22px;
                            max-width: 100%;

                            @media screen and (max-width: 500px) {
                                font-size: 14px;
                                line-height: 20px;
                                margin-left: 10px;
                            }
                        }

                        &-other {

                            .text-single,
                            .text-monthly {
                                text-align: center;
                            }
                        }
                    }
                }
            }

            h1 {
                color: $yellow;
                font-size: 42px;
                line-height: 46px;
                margin: 0 auto 15px;

                @media screen and (max-width: 1024px) {
                    font-size: 35px;
                }
            }

            p {
                font-size: 21px;
                line-height: 26px;
                max-width: 732px;
                margin: 0 auto 15px;
            }

            .pound {
                width: 100%;
                max-width: 375px;
                position: relative;
                display: inline-block;
                margin-bottom: 20px;

                &:before {
                    content: "£";
                    position: absolute;
                    left: 2px;
                    top: 2px;
                    background: #fff;
                    height: calc(100% - 4px);
                    width: 50px;
                    z-index: 999;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: $blue-dark;
                    font-size: 40px;
                    font-weight: bold;
                }
            }

            input[type=text],
            input[type=number] {
                font-size: 60px;
                color: $blue-dark;
                position: relative;
                font-weight: bold;
                text-align: center;
                width: 100%;
                padding: 5px 12.5px;

                &::placeholder {
                    color: $blue-dark;
                }

                ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                    color: $blue-dark;
                }

                ::-moz-placeholder { /* Firefox 19+ */
                    color: $blue-dark;
                }

                :-ms-input-placeholder { /* IE 10+ */
                    color: $blue-dark;
                }

                :-moz-placeholder { /* Firefox 18- */
                    color: $blue-dark;
                }
            }

            .site-nav-donate {
                margin: 20px auto;
                display: inline-block;
                float: none;

                @include mq($until: tablet) {
                    margin: 10px auto;
                }
            }
        }

        .buttons {
            display: none;
        }

        #nodonation {
            background: none;
            border: 0;
            color: #fff;
            text-decoration: underline;
            font-size: 16px;
        }

        .form-step-container {

            .step-5 {
                background: #fff;
                color: $blue-dark;
            }
        }

        #shineastarForm {
            display: none;
        }

        .final-stage-form {
            height: 100%;
            display: block;

            fieldset {
                opacity: 1;
                z-index: 1;
            }
        }
    }

    form {
        position: relative;
        width: 65%;
        display: flex;
        flex-grow: 1;
        align-items: flex-start;
        min-height: 200px;
        transition: .4s ease min-height;
        padding-bottom: 0;

        @include mq($from: tablet) {
            min-height: 250px;
        }

        @include mq($from: desktop) {
            width: 50%;
            min-height: initial;
        }

        h2 {
            font-size: 20px;

            @include mq($from: tablet) {
                font-size: 30px;
            }
        }

        .inputfile + label strong,
        .inputfile + label span {
            font-size: 14px;
            padding: 5.5px 12.5px;

            @include mq($from: tablet) {
                padding: 15px;
                font-size: 20px;
            }

            @include mq($from: desktop) {
                padding: 20px;
            }
        }
    }

    .fieldset-4-mobile {
        display: none;
    }

    &.fieldset-4-active_WRAP {

        form {
            margin-bottom: 70px;
            min-height: 250px;

            @include mq($from: mobileLandscape, $until: tablet) {
                min-height: 300px;
            }

            @include mq($from: tablet) {
                min-height: 350px;
            }
        }

        .form-output {
            background-image: none;

            @include mq($from: desktop) {
                background-image: url("../img/shineastar/StarShape.png");
            }
        }

        .fieldset-4-mobile {
            display: flex;

            @include mq($from: desktop) {
                display: none;
            }
        }

        .section-4 {

            h2 {
                display: none;

                @include mq($from: desktop) {
                    display: block;
                }
            }
        }
    }

    &.final-stage {

        form {
            margin-bottom: 0;
        }
    }

    .form-inside {
        background: #1661A1;
        height: 750px;
        max-height: 95%;
        position: relative;
        border-radius: 10px;
        width: 1366px;
        margin: auto auto 30px;
        padding: 50px 35px 0px;
        max-width: 95%;
        display: flex;
        flex-direction: column;

        @include mq($until: tablet) {
            height: 90vh; /**  Fallback for browsers that do not support Custom Properties **/
            height: calc(var(--vh, 1vh) * 90);
            min-height: 520px;
        }
    }

    .form-wrapper {
        height: 100%;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;

        @include mq($until: desktop) {
            flex-direction: column;
            justify-content: start;

            .fieldset-0-output {
                background-position: center;
            }
        }

        &.fieldset-3-active {

            .form-output {
                background-image: none;
            }
        }
    }

    .form-output {
        position: relative;
        height: 100%;
        width: 100%;
        background-image: url("../img/shineastar/StarShape.png");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        display: flex;
        flex-grow: 1;
        justify-content: center;
        align-items: center;

        @include mq($from: desktop) {
            width: 45%;
            z-index: 1;
        }

        .form-output-field {
            position: absolute;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            text-align: center;
            transition: .4s opacity ease, .4s transform ease;
            flex-direction: column;
            transform: scale(0);
            opacity: 0;
            left: 0;

            &.active {
                transform: scale(1);
                opacity: 1;
                display: flex;
            }

            &.fieldset-4-output {
                display: none;
                opacity: 0;

                @include mq($from: desktop) {
                    display: flex;
                    opacity: 1;
                }
            }

            &.fieldset-4-mobile {
                opacity: 1;
                transform: scale(1);

                h2 {
                    color: $yellow;
                }
            }

            h3,
            h4,
            p {
                max-width: 45%;
                margin: 0 auto;
                word-wrap: break-word;
            }

            h3, h4 {
                color: $yellow;
            }

            h3 {
                margin-bottom: 30px;
                margin-top: 40px;
                font-size: 26px;
            }

            p {
                margin-bottom: 20px;
            }

            h4 {
                font-size: 18px;
            }
        }
    }

    input,
    textarea {
        padding: 17.5px 12.5px;
        width: 100%;
        box-sizing: border-box;

        &::placeholder {
            color: $blue-dark;
            opacity: 1;
        }
    }

    select {
        padding: 10px 15px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        color: $blue-dark;
        outline: none;
        position: relative;
        background-image: url("../img/shineastar/chevron-down.png");
        background-repeat: no-repeat;
        background-position: 93% center;
        background-size: 17px;
        border-radius: 0px;
        width: 125px;
        border: 1px solid #979797;
        background-color: #fff;
    }

    textarea {
        height: 60px;
    }

    fieldset {
        padding: 10px 0;
        position: absolute;
        transition: .75s ease opacity, .75s ease top, .75s ease z-index;
        opacity: 0;
        border: 0;
        width: 100%;
        z-index: -1;

        @include mq($from: desktop) {
            top: -100px;

            &.section-4 {
                top: -50px;
            }
        }

        &.next-active {
            top: 100px;
        }

        &.active {
            display: block;
            opacity: 1;
            z-index: 1;
        }

        &.section-5 {
            top: 0;
            transform: none;
        }

        .remove-image {
            color: #fff;
            border: 0;
            background: none;
            text-decoration: underline;
            padding: 0;
            margin-top: 15px;
            font-size: 16px;

            @include mq($from: desktop) {
                font-size: 18px;
            }
        }
    }

    .section-4 {

        .flex {
            display: flex;
            justify-content: space-between;
            margin: 10px 0;

            input {
                width: 49%;
            }

            &.checkbox {
                align-items: center;
                padding-top: 0;
                display: block;

                input[type=checkbox] {
                    width: 25px;
                }

                span {
                    flex-grow: 1;
                }
            }
        }
    }

    .fieldset-0-output {
        width: 100%;
        height: 100%;
        background-image: url("../img/shineastar/CreateAStar2.png");
        background-position: center left;
        background-size: contain;
        background-repeat: no-repeat;
        transition: .4s ease transform;
        transform: scale(0);

        &.active {
            transform: scale(1);
        }
    }

    .fieldset-2-output {

        svg {
            max-width: 92.5%;
        }
    }

    .buttons {
        position: absolute;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 50%;
        transform: translateX(-50%);
        bottom: 50px;
        width: 100%;

        @include mq($from: desktop) {
            justify-content: flex-end;
            width: calc(100% - 120px);
        }

        .back-button {
            font-size: 16px;
            color: #fff;
            background: none;
            border: 0;
            text-decoration: underline;
            padding: 12.5px 20px;
            transition: .4s ease opacity;

            &.grey {
                opacity: 0;
            }
        }
    }

    .form-step-container {
        width: 100%;
        // position: absolute;
        height: 50px;
        margin-bottom: -25px;
        // left: 0;
        // bottom: 0;
        // transform: translateY(50%);
        display: flex;
        align-items: center;
        justify-content: center;
        // z-index: 2;

        @include mq($from: tablet) {
            //transform: translateY(0%);
        }

        @media screen and (max-width: 565px) {
            height: 30px;
            margin-bottom: -15px;
        }

        .form-step {
            background: $blue;
            color: #fff;
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50px;
            border: 8px solid $blue;
            margin: 0 5px;
            transition: color .4s ease, background .4s ease;
            font-weight: 600;

            &.active {
                background: #fff;
                color: $blue-dark;
            }
        }
    }

}
