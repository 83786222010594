//----------------------------------------------------
//      use if you need to check for things
//      in JS plugins - more reliable
//      than window width, etc
//----------------------------------------------------
.trigger-size {
    width: 0;
    height: 0;
    display: none;

    &.mobile {
        @include mq($until: mobileLandscape) {
            display: block;
        }
    }

    &.mobile-large {
        @include mq($from: mobileLandscape, $until: tablet) {
            display: block;
        }
    }

    &.tablet {
        @include mq($from: tablet, $until: desktop) {
            display: block;
        }
    }

    &.desktop {
        @include mq($from: desktop, $until: wide) {
            display: block;
        }
    }

    &.desktop-wide {
        @include mq($from: wide) {
            display: block;
        }
    }
}
