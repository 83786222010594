/* All stars section */

.allstar {
    display: flex;
    position: relative;
    background-image: url("../img/shineastar/shineastar-background-img.jpg");
    background-size: cover;
    overflow: hidden;
    background-color: $blue;
    min-height: 920px;

    @import "./_stars-gallery";

    @include mq($until: tablet) {
        margin-top: -60px;
    }

    &:before {
        content: "";
        display: block;
        width: 100%;
        height: 300px;
        position: absolute;
        top: -2px;
        left: 0;
        z-index: 1;
        background-image: linear-gradient(180deg, rgba(52, 116, 189, 0) 3%, rgba(52, 116, 189, .06) 8%, rgba(52, 116, 189, 0.12) 14%, rgba(52, 116, 189, 0.19) 21%, rgba(52, 116, 189, 0.26) 28%, rgba(52, 116, 189, 0.34) 36%, rgba(52, 116, 189, 0.42) 43%, rgba(52, 116, 189, 0.50) 51%, rgba(52, 116, 189, 0.58) 59%, rgba(52, 116, 189, 0.66) 67%, rgba(52, 116, 189, .74) 74%, rgba(52, 116, 189, .81) 81%, rgba(52, 116, 189, .88) 88%, rgba(52, 116, 189, .94) 94%, #3474BD 100%);
        transform: rotate(180deg);
    }

    .allstars-wrapper {
        position: relative;
        z-index: 1;
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }


    .allstars-header {
        order: 100;
        margin-bottom: -43px;
        margin-top: 43px;

        @include mq($until: tablet) {
            margin-top: 20px;
            margin-bottom: 30px;
        }
    }

    .currentSearch,
    .noResults {
        display: none;
        transform: translateY(-50%);
        color: $yellow;
        margin-bottom: 10px;
        text-align: center;
    }

    &.no-results {

        .noResults {
            display: block;
        }
    }

    &.filtered {

        .currentSearch {
            display: block;
        }

        .allstars-inside {
            display: flex;
        }

        .large-image {
            max-width: 650px;

            @include mq($until: tablet) {
                max-width: 85vw;
            }
        }

        .column {

            .star-image-container {
                width: 275px;

                @include mq($until: tablet) {
                    max-width: 175px;
                }
            }

            &:nth-child(2n + 2),
            &:nth-child(2n + 3),
            &-top-image,
            &-bottom-image {
                padding-top: 0 !important;
                padding-bottom: 0 !important;
            }
        }
    }

    .button-container {
        position: absolute;
        height: 130px;
        width: 150px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;

        &.left {
            left: 0;
        }

        &.right {
            right: 0;
        }

        .button-nav {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 130px;
            height: 130px;
            background-image: url("../img/shineastar/chevron-left-right-nobg.png");
            background-position: center center;
            background-repeat: no-repeat;
            background-color: rgba(11, 49, 87, .6);
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
            border: 0;
            transition: background-color .4s ease, opacity .4s ease;
            cursor: pointer;

            &:hover {
                background-color: rgba(11, 49, 87, 1);
            }

            &.disabled {
                opacity: 0;
                pointer-events: none;
            }

            &.right {
                left: auto;
                right: 0;
                transform: rotate(180deg) translate(0, 50%);
            }
        }
    }

    .allstars-inside {
        margin: auto;
        padding: 0 25px 160px 0;
        max-height: 100%;
        // width: 200%;
        transform: translateX(-0%);
        transition: .75s ease transform;
        text-align: center;
        align-items: center;

        @include mq($from: tablet) {
            display: flex;
            padding: 85px 0 105px;
        }

        img {
            max-width: 100%;
        }

        .column {
            position: relative;
            min-height: calc(100% - 150px);

            .star-image-container {
                min-width: 225px;
                justify-content: center;
                align-items: center;

                @include mq($until: tablet) {
                    min-width: 150px;
                }

                .name {
                    width: 100%;
                }

                svg {
                    width: 225px;
                    height: 225px;

                    @include mq($until: tablet) {
                        width: 150px;
                        height: 150px;
                    }
                }
            }

            &:nth-child(2n + 2) {
                bottom: 50px;

                @include mq($until: tablet) {
                    bottom: 0;
                }
            }

            &:nth-child(2n + 3) {
                top: 75px;

                @include mq($until: tablet) {
                    top: 65px;
                }
            }

            &-top-image {
                padding-bottom: 100px;

                @include mq($until: tablet) {
                    padding-bottom: 35px;
                }
            }

            &-bottom-image {
                padding-bottom: 125px;

                @include mq($until: tablet) {
                    padding-bottom: 0;
                }
            }
        }

        .large-image {
            padding-left: 20px;
            width: 525px;
            display: none;

            @include mq($from: tablet) {
                display: flex;
                flex-direction: column;
                text-align: center;
                align-items: center;
                justify-content: center;
            }

            svg {
                max-width: 575px;
                height: auto;
            }

            svg {
                display: none;
            }

            span.name {
                display: none;
                max-width: 575px;
            }
        }

        svg,
        img,
        .name {
            transition: .4s ease opacity;
        }
    }

    .search-container {
        width: fit-content;
        border: 2px solid $yellow;
        box-shadow: 0 0 10px 0 rgba(255,242,95,.75);
        z-index: 100;
        flex-direction: row;
        display: flex;
        height: 48px;
        max-width: 100%;

        @include mq($until: tablet) {
            flex-direction: column;
            height: initial;
        }

        &-wrapper {
            position: absolute;
            bottom: 0;
            max-width: 90%;
            left: 50%;
            z-index: 99;
            transform: translateX(-50%);

            @include mq($until: mobileLandscape) {
                bottom: 10px;
            }
        }

        .searchStarInput {
            background: 0;
            color: #FFFFFF;
            border: 0;
            padding: 0 14.5px;
            width: 306px;
            font-size: 20px;

            @include mq($until: tablet) {
                padding: 7.5px 14.5px;
            }

            &::placeholder {
                color: #FFFFFF;
            }
        }
    }

    .searchStarSubmit {
        background: $yellow;
        border: 2px solid $yellow;
        color: $blue-mid;
        box-shadow: 0 0 10px 0 rgba(255, 242, 95, .75);
        font-weight: bold;
        padding: 0 14.5px;
        font-size: 21px;
        border-radius: 0;

        @include mq($until: tablet) {
            padding: 5px 14.5px;
        }

        &:hover {

            &::after {
                opacity: 1;
            }
        }

        &::after {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background: radial-gradient(#FFD458 0%, #FFD458 55%, #FAC52C 75%, #ffc928 90%);
            opacity: 0;
            transition: .4s ease opacity;
            z-index: 1;
        }
    }

    .backofstar {
        display: none;
    }

    .name h3 {
        font-weight: normal;
        margin: 0;
        font-size: 18px;
        line-height: 1.5;
    }
}
