/*
    *
    *
    *

    START
    https://codepen.io/Mattgh9152/pen/PxpBxN

    *
    *
    *
    */

[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
    margin: auto;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    border: 0;
    clip: auto;
    height: auto;
    margin: auto;
    overflow: hidden;
    padding: auto;
    position: auto;
    width: auto;
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 24px;
    display: inline-block;
    color: #fff;

    &.donation-single-label {
        margin-right: 15px;
    }
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 22px;
    height: 22px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: "";
    width: 14px;
    height: 14px;
    background: $pink;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
}

[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

/*
*
*
*

END
https://codepen.io/Mattgh9152/pen/PxpBxN

*
*
*
*/
