//----------------------------------------------------
//      donate feature
//----------------------------------------------------

.donate-feature {
    position: relative;
    padding: 40px;
    background-color: $blue;
    color: #fff;
    margin: 0 0 40px;

    @include mq($from: tablet, $until: desktop) {
        padding: 20px 20px 100px;
        margin: 0 0 20px;
    }

    @include mq($until: tablet) {
        padding: 10px 10px 100px;
        margin: 0 0 20px;
    }

    &.active-regular {
        .donate-row:nth-child(1),
        .donate-row:nth-child(2),
        .donate-row:nth-child(3) {
            display: inline-block;
        }

        .donate-row:nth-child(4),
        .donate-row:nth-child(5),
        .donate-row:nth-child(6) {
            display: none;
        }
    }

    &.active-one {
        .donate-row:nth-child(1),
        .donate-row:nth-child(2),
        .donate-row:nth-child(3) {
            display: none;
        }

        .donate-row:nth-child(4),
        .donate-row:nth-child(5),
        .donate-row:nth-child(6) {
            display: inline-block;
        }
    }
}

.donate-buttons {
    @include cf;
    padding-bottom: 20px;
    clear: both;
}

.donate-button {
    font-size: 32px;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    float: left;
    width: (313/629)*100%;
    display: block;
    text-align: center;
    height: 60px;
    line-height: 60px;
    position: relative;
    background-color: $orange;
    color: #fff;
    border: 0;
    padding: 0;
    margin: 0 0 15px;
    transition: .5s background-color ease, .5s color ease;

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 0;
        background-color: transparent;
        background-image: url(../img/donate-arrow-down.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: 50% 100%;
        transition: .25s height ease;
    }

    &:last-of-type {
        float: right;
    }

    &:hover,
    &:focus {
        background-color: #fff;
        color: $orange;
        outline: 0;
    }

    &.active {
        background-color: #fff;
        color: $orange;

        &::after {
            height: 15px;
        }

        &:hover,
        &:focus {
            color: $blue;
        }
    }

    @include mq($until: tablet) {
        font-size: 20px;
        height: 46px;
        line-height: 46px;
    }

    @include mq($from: tablet, $until: desktop) {
        font-size: 24px;
        height: 46px;
        line-height: 46px;
    }
}

.donate-amount {
    padding-bottom: 35px;
    @include cf;
    clear: both;

    @include mq($from: tablet, $until: desktop) {
        padding: 0 0 25px;
    }

    @include mq($until: tablet) {
        padding: 0 10px 15px;
    }
}

.donate-row {
    display: inline-block;
    position: relative;
    overflow: hidden;
    margin-right: 34px;

    &.donate-row--other {
        margin-right: 0;
    }

    &--other {
        float: right;
        clear: right;

        .donate-radio + label {
            text-indent: 115%;
            overflow: hidden;
            white-space: nowrap;
            width: 35px;
            float: left;
        }
    }

    @include mq($from: tablet, $until: wide) {
        width: 48%;
        margin-right: 0;

        &:nth-of-type(4n+2) {
            float: right;
        }

        &--other {
            width: 48%;
        }
    }

    @include mq($until: tablet) {
        width: 31%;
        margin-right: 0;

        &--other {
            width: 100%;
            clear: both;
            float: none;
        }
    }
}

.donate-radio {
    width: 1px;
    height: 1px;
    position: absolute;
    top: -1px;
    left: -1px;
    overflow: hidden;
    border: 0;
    clip: rect(0 0 0 0);
    margin: -1px;
    padding: 0;

    & + label {
        position: relative;
        padding-left: 35px;
        display: block;
        font-size: 40px;
        line-height: 50px;
        height: 50px;
        cursor: pointer;
        transition: .5s font-weight ease;
        font-weight: $font-weight-light;

        &::before,
        &::after {
            content: '';
            display: block;
            position: absolute;
            border-radius: 50%;
            top: 50%;
            transform: translate(0,-50%);
        }

        &::before {
            width: 20px;
            height: 20px;
            left: 0;
            background-color: #fff;
            opacity: .2;
            transition: .5s opacity ease;
        }

        &::after {
            width: 10px;
            height: 10px;
            left: 5px;
            background-color: $blue;
            transition: .5s background-color ease;
        }
    }

    &:checked + label::before {
        opacity: 1;
    }

    &:checked + label::after {
        background-color: $orange;
    }

    @include mq($from: tablet, $until: desktop) {
        & + label {
            font-size: 24px;
            height: 44px;
            line-height: 44px;
            padding-left: 30px;
        }
    }

    @include mq($until: tablet) {
        & + label {
            font-size: 20px;
            height: 44px;
            line-height: 44px;
            padding-left: 30px;
        }
    }
}

.donate-input {
    height: 50px;
    line-height: 50px;
    color: $blue;
    background-color: #fff;
    padding: 0 5px;
    font-size: 40px;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    float: left;
    max-width: 154px;
    border: 0;
    font-weight: $font-weight-bold;
    letter-spacing: -2px;
    appearance: textfield;

    &::placeholder {
        color: $blue;
        opacity: 1;
    }

    &:focus {
        outline: 0;
        opacity: 1;
    }

    &:invalid {
        background-color: $pink;
        color: #fff;
    }

    @include mq($from: tablet, $until: desktop) {
        height: 44px;
        line-height: 44px;
        font-size: 24px;
        max-width: calc(100% - 35px);
    }

    @include mq($until: tablet) {
        height: 44px;
        line-height: 44px;
        font-size: 20px;
        max-width: calc(100% - 35px);
    }
}

.donate-content {
    @include cf;
    clear: both;
}

.donate-text {
    float: left;
    width: (388/629)*100%;
    position: relative;
    height: 50px;
    font-size: 16px;
    line-height: (19/16)*100%;

    p {
        margin: 0;
    }

    @include mq($until: desktop) {
        float: none;
        width: 100%;
        height: auto;
        overflow: visible;
        padding: 0 10px;
    }
}

.donate-link {
    float: right;
    width: (200/629)*100%;
    background-color: $pink;
    color: #fff;
    text-align: center;
    display: block;
    font-size: 18px;
    height: 50px;
    line-height: 50px;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    transition: .5s background-color ease;

    &:hover,
    &:focus {
        background-color: $orange;
        outline: 0;
    }

    @include mq($from: tablet, $until: desktop) {
        float: none;
        width: calc(100% - 40px);
        position: absolute;
        bottom: 20px;
        left: 20px;
    }

    @include mq($until: tablet) {
        float: none;
        width: calc(100% - 40px);
        position: absolute;
        bottom: 20px;
        left: 20px;
    }
}
