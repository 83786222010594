/* Donate section */
.donate {
    display: flex;
    margin-top: 50px;
    margin-bottom: 50px;

    @include mq($until: tablet) {
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        min-height: 609px;
    }

    @include mq($from: tablet) {
        margin-top: 0;
    }

    .donate-inside {
        background: $blue-dark;
        position: relative;
        border-radius: 10px;
        width: 1366px;
        margin: auto;
        padding: 40px 50px 50px;
        max-width: 95%;
        text-align: center;

        @include mq($until: tablet) {
            min-height: 90vh;
            min-height: calc(var(--vh, 1vh) * 90);

            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 30px 25px;
        }

        .donation-amounts {
            max-width: 600px;
            margin: 20px auto 0;
        }

        .donation-amount-label {
            position: relative;
            width: 80px;
            height: 80px;
            margin-right: 10px;
            padding: 0;
            font-size: 30px;
            line-height: 80px;
            font-weight: 600;
            color: $pink;
            background-color: white;
            box-sizing: border-box;

            @media screen and (max-width: 767px) {
                &:nth-of-type(3) {
                    margin-right: 0;
                }
            }

            @media screen and (max-width: 500px) {
                width: 70px;
                height: 70px;
                line-height: 70px;
                font-size: 28px;

                &:not(.donation-amount-label-other) {
                    margin-right: 5px;
                }
            }

            &-other {
                width: 110px;
                margin-right: 0;

                span {
                    position: relative;
                    font-size: 24px;
                    color: $pink;
                    bottom: 2px;
                }

                @media screen and (max-width: 767px) {
                    display: block;
                    margin: 10px auto 0;
                    font-size: 22px;

                    span {
                        bottom: 0;
                    }
                }

                @media screen and (min-width: 500px) and (max-width: 767px) {
                    width: 270px;
                }

                @media screen and (max-width: 500px) {
                    width: 230px;
                }
            }

            &::before,
            &::after {
                display: none;
            }
        }

        #donation-amount2-other-input {
            display: none;
            position: absolute;
            bottom: 10px;
            left: 10px;
            width: calc(100% - 20px);
            font-size: 24px;
            padding-left: 20px;

            @media screen and (max-width: 767px) {
                width: 40%;
                bottom: 0;
                height: 100%;
                left: 80px;
                font-size: 40px;
                border: 0;
                padding-left: 2px;
            }

            @media screen and (max-width: 550px) {
                width: 60%;
                left: 48px;
            }
        }

        .quid {
            display: none;
            position: absolute !important;
            top: 13px;
            left: 16px;
            bottom: initial !important;
            z-index: 999;
            color: $blue-mid !important;
        }

        #donation-amount2-other:checked + .donation-amount-label {

            .quid {
                display: block;

                @include mq($until: tablet) {
                    display: none;
                }
            }

            span {
                bottom: 11px;
                color: white;
            }

            #donation-amount2-other-input {
                display: block;

                &::-webkit-inner-spin-button,
                &::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
            }

            @media screen and (max-width: 767px) {
                span {
                    right: 45px;
                    bottom: 0;
                    z-index: 10;
                }
            }
        }

        input[name="donation-amount-2"]:checked + .donation-amount-label {
            color: white;
            background-color: $pink;

            @media screen and (max-width: 767px) {
                background-color: #fff;
                color: $blue-mid !important;
            }
        }

        .donation-details2-container {
            position: relative;
            min-height: 75px;
            max-width: 500px;
            margin: 30px auto 0;
            padding: 15px;
            border: 1px solid $pink;

            @include mq($until: tablet) {
                margin: 0 auto;
                min-height: 90px;
            }

            img {
                max-width: 100%;
            }

            &::before,
            &::after {
                content: "";
                display: block;
                position: absolute;
                width: 0;
                height: 0;
                border-style: solid;
                transition: left .2s ease;

                @media screen and (max-width: 767px) {
                    display: none;
                }
            }

            &::after {
                top: -40px;
                left: 161px;
                border-color: transparent transparent $blue-dark transparent ;
                border-width: 20px;
            }

            &::before {
                top: -43px;
                left: 160px;
                border-color: transparent transparent $pink transparent;
                border-width: 21px;
            }

            &.single {
                .text-single {
                    display: block;
                }
            }

            &.monthly {
                .text-monthly {
                    display: block;
                }
            }

            &[display-detail="matched-donation"] {

                .donation-details2-christmas-matched-donation{
                    display: flex;
                }

                &::before {
                    left: 56px;
                }

                &::after {
                    left: 57px;
                }
            }
            &[display-detail="first"] {

                .donation-details2-first {
                    display: flex;
                }

                &::before {
                    left: 56px;
                }

                &::after {
                    left: 57px;
                }
            }

            &[display-detail="second"] {

                .donation-details2-second {
                    display: flex;
                }

                &::before {
                    left: 160px;
                }

                &::after {
                    left: 161px;
                }
            }

            &[display-detail="third"] {

                .donation-details2-third {
                    display: flex;
                }

                &::before {
                    left: 265px;
                }

                &::after {
                    left: 266px;
                }
            }

            &[display-detail="other"] {

                .donation-details2-other {
                    display: flex;
                }

                &::before {
                    left: 385px;
                }

                &::after {
                    left: 386px;
                }
            }
        }

        .donation-details2 {
            display: none;
            justify-content: space-around;
            align-items: center;
            position: relative;
            height: 100%;
            text-align: left;

            .amount {
                width: 50px;
                font-size: 30px;
                line-height: 30px;
                margin-right: 20px;
            }

            .text-single,
            .text-monthly {
                display: none;
                max-width: 380px;
                margin: 0;
                font-size: 16px;
                line-height: 22px;

                @media screen and (max-width: 500px) {
                    font-size: 14px;
                    line-height: 20px;
                    margin-left: 10px;
                }
            }

            &-other {
                .text-single,
                .text-monthly {
                    text-align: center;
                }
            }
        }

        .pos-1 {
            top: 18%;

            @media screen and (max-width: 500px) {
                top: 7%;
                left: 2.5%;
            }
        }

        .pos-2 {
            top: 4%;
            left: 80%;
        }

        .pos-3 {
            top: 70%;
            left: 90%;

            @media screen and (max-width: 500px) {
                top: 80%;
                left: 72%;
            }
        }

        .pos-4 {
            left: 14%;

            @media screen and (max-width: 800px) {
                display: none;
            }
        }

        .pos-5 {
            top: 80%;
            left: 6%;

            @media screen and (max-width: 400px) {
                top: 50%;
            }
        }

        .shining-star-large {
            width: 185px;
            height: 250px;
        }

        .pos-8,
        .pos-9 {
            background-image: url("../img/shineastar/form-starGroup.svg");

            @include mq($until: tablet) {
                display: none;
            }
        }

        .pos-9 {
            left: 12.5%;
        }
    }

    h1 {
        color: $yellow;
        font-size: 60px;
        line-height: 72px;
        margin: 0 auto 20px;

        @include mq($until: tablet) {
            font-size: 42px;
            line-height: 44px;
            margin: 0 auto 10px;
        }
    }

    p {
        font-size: 24px;
        line-height: 32px;
        max-width: 600px;
        margin: 0 auto 25px;

        @include mq($until: tablet) {
            font-size: 16px;
            line-height: 21px;
        }
    }

    .pound {
        width: 100%;
        max-width: 375px;
        position: relative;
        display: inline-block;
        margin-bottom: 20px;

        &:before {
            content: '£';
            position: absolute;
            left: 2px;
            top: 2px;
            background: #fff;
            height: calc(100% - 4px);
            width: 50px;
            z-index: 999;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $blue-dark;
            font-size: 40px;
            font-weight: bold;
        }
    }

    input[type=text],
    input[type=number] {
        font-size: 60px;
        color: $blue-dark;
        position: relative;
        font-weight: bold;
        text-align: center;
        width: 100%;

        &::placeholder {
            color: $blue-dark;
        }

        ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: $blue-dark;
        }

        ::-moz-placeholder { /* Firefox 19+ */
            color: $blue-dark;
        }

        :-ms-input-placeholder { /* IE 10+ */
            color: $blue-dark;
        }

        :-moz-placeholder { /* Firefox 18- */
            color: $blue-dark;
        }
    }

    .site-nav-donate {
        margin: 40px auto 0;
        display: inline-block;
        float: none;
    }

}
