//----------------------------------------------------
//      button styles
//----------------------------------------------------

.link-button-wrapper,
.link-button {
    float: left;
    display: block;
    clear: both;
    text-transform: uppercase;
    line-height: 40px;
    padding-left: 20px;
    padding-right: 10px;
    padding-top: 0;
    padding-bottom: 0;
    border: 0;
    margin: 0 20px 0 0;
    position: relative;
    background-color: $orange;
    color: #fff;
    appearance: none;
    transition: .5s background-color ease;

    + .link-button {
        margin-top: 10px;
        //margin-bottom: 40px;
    }

    > input[type=submit] {
        background-color: transparent;

        &:hover {
            background-color: transparent;
        }
    }

    &:after {
        content: '';
        display: block;
        height: 0;
        width: 0;
        border-left: 16px solid $orange;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        position: absolute;
        right: -16px;
        top: 0;
        transition: .5s border-color ease;
    }

    &:hover {
        background-color: $blue;

        &:after {
            border-left-color: $blue;
        }
    }

    .additional-title {
        display: inline;
    }

    @include mq($until: mobileLandscape) {
        .additional-title {
            display: none;
        }
    }
}

.link-button-white {
    background-color: #fff;
    color: $orange;

    &:after {
        border-left-color: #fff;
    }

    &:hover {
        color: #fff;
    }

    &.link-button-back {
        &:after {
            border-right-color: #fff;
        }
    }
}

.link-button-pink {
    background-color: $pink;

    &:after {
        border-left-color: $pink;
    }

    &.link-button-back {
        &:after {
            border-right-color: $pink;
        }
    }
}

.link-button-back {
    margin: 0 0 0 20px;
    padding-left: 10px;
    padding-right: 20px;

    &:after {
        border-left: 0;
        border-right: 16px solid $orange;
        right: auto;
        left: -16px;
    }

    &:hover {
        &:after {
            border-right-color: $blue;
        }
    }
}

.link-button-large {
    line-height: 50px;

    &:after {
        border-top: 25px solid transparent;
        border-bottom: 25px solid transparent;
        border-left: 20px solid $orange;
        right: -20px;
    }

    &.link-button-white {
        &:after {
            border-left: 20px solid #fff;
        }

        &:hover {
            &:after {
                border-left-color: $blue;
            }
        }
    }
}

p > .link-button {
    display: inline-block;
    float: none;

    &:hover {
        color: #fff;
    }
}
