@import url("https://use.typekit.net/rmj1tpw.css");

//----------------------------------------------------
// Colours
//----------------------------------------------------

$orange: #EE5F1C;
$blue: #3474BD;
$pink: #E5007A;
$grey: #EBEBEB;
$grey-dark: #c5c5c5;
$grey-light: #F5F5F5;
$black: #0A0A0A;
$yellow: #FAC52C;
$yellow-light: #FFD458;
$blue-dark: #0B3157;
$blue-mid: #0B3B64;

$accessibility: $pink;

//----------------------------------------------------
//  fonts
//----------------------------------------------------

$font-sans: "proxima-nova", "Proxima Nova", "Helvetica Neue", helvetica, arial, verdana, sans-serif !default;
$font-serif: Georgia, "Times New Roman", Times, serif !default;
$font-mono: Monaco, Menlo, Consolas, "Courier New", monospace !default;
$font-heading: "proxima-nova", "Proxima Nova", "Helvetica Neue", helvetica, arial, verdana, sans-serif !default;

//----------------------------------------------------
//  base typography
//----------------------------------------------------

$font-size: 18px;
$font-size-h1: 44px;
$font-size-h2: 30px;
$font-size-h3: 24px;
$font-size-h4: 20px;
$font-size-h5: 18px;
$font-size-h6: 18px;

$line-height-base: 1.4;

$font-weight-light: lighter;
$font-weight-regular: normal;
$font-weight-bold: bold;
