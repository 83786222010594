//----------------------------------------------------
//      fundraising feature block (homepage)
//----------------------------------------------------

.fundraising-feature {
    padding-top: 80px;
    padding-bottom: 90px;
    margin-top: -80px;
    margin-bottom: -80px;
    @include cf;
    position: relative;
    z-index: 4;
    min-height: 500px;

    .contain {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    @include mq($from: desktop, $until: wide) {
        padding-top: 40px;
        padding-bottom: 40px;
        margin-top: 0;
        margin-bottom: 0;

        .contain {
            padding-top: 30px;
        }
    }

    @include mq($until: desktop) {
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
    }
}

.fundraising-feature-text {
    float: left;
    width: 500px;
    z-index: 5;
    position: relative;

    h2 {
        color: $blue;
        font-size: $font-size-h1;
    }

    @include mq($from: desktop, $until: wide) {
        width: 400px;

        h2 {
            font-size: 28px;
        }
    }

    @include mq($until: desktop) {
        width: 100%;
        float: none;
        clear: both;
        @include cf;
        margin-bottom: 40px;

        h2 {
            font-size: 28px;
        }
    }
}

.fundraising-feature-events {
    position: absolute;
    top: -20px;
    right: 20px;
    width: 600px;
    height: 440px;
    background-image: url(../img/faded-hexagons.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 100% 0;

    @include mq($from: desktop, $until: wide) {
        top: 0;
    }

    @include mq($until: desktop) {
        position: relative;
        top: auto;
        right: auto;
        width: 100%;
        clear: both;
        @include cf;
    }

    @include mq($from: tablet, $until: desktop) {
        height: 300px;
    }

    @include mq($until: tablet) {
        height: auto;
        background-image: none;
    }
}

.fundraising-feature-event {
    display: block;
    height: 100%;
    text-align: center;

    &:hover {
        .fundraising-feature-event-image {
            top: 20px;
            left: 40px;
        }

        .fundraising-feature-event-detail {
            background-color: darken($blue, 10%);
            left: 310px;
            top: 110px;

            &:before {
                border-right-color: darken($blue, 10%);
            }

            &:after {
                border-left-color: darken($blue, 10);
            }
        }
    }

    @include mq($from: tablet, $until: desktop) {
        &:hover {
            .fundraising-feature-event-image,
            .fundraising-feature-event-detail {
                top: auto;
            }

            .fundraising-feature-event-image {
                left: 33%;
                transform: translate(-50%,0);
            }

            .fundraising-feature-event-detail {
                left: 66%;
                transform: translate(-50%,0);
            }
        }
    }

    @include mq($until: tablet) {
        &:hover {
            .fundraising-feature-event-image,
            .fundraising-feature-event-detail {
                left: 50%;
                top: auto;
            }
        }
    }
}

.fundraising-feature-event-image {
    width: 334px;
    height: 290px;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    transition: .5s left ease, .5s top ease;

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(../img/hex-mask.png);
        background-repeat: no-repeat;
        background-position: 0 50%;
        background-size: contain;
        z-index: 2;
    }

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        min-width: 100%;
        z-index: 1;
    }

    @include mq($until: mobileLandscape) {
        width: 280px;
        height: 232px;

        &:after {
            background-size: cover;
        }
    }
}

.fundraising-feature-event-detail {
    background-color: $blue;
    color: #fff;
    padding: 30px 0;
    width: 174px;
    height: 290px;
    text-align: center;
    position: absolute;
    z-index: 3;
    left: 350px;
    top: 150px;
    transition: .5s background-color ease, .5s left ease, .5s top ease;

    &:before,
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        width: 0;
        height: 0;
        border-top: 145px solid transparent;
        border-bottom: 145px solid transparent;
        transition: .5s border-color ease;
    }

    &:before {
        left: -80px;
        border-right: 80px solid $blue;
    }

    &:after {
        right: -80px;
        border-left: 80px solid $blue;
    }

    time {
        display: block;
        left: 50%;
        position: relative;
        transform: translate(-50%,0);
        clear: both;
        background-color: #fff;
        color: $blue;
        width: 44px;
        height: 74px;
        line-height: 1;
        padding-top: 17px;
        text-transform: uppercase;
        text-align: center;

        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            width: 0;
            height: 0;
            border-top: 37px solid transparent;
            border-bottom: 37px solid transparent;
            z-index: 1;
        }

        &:before {
            left: -20px;
            border-right: 20px solid #fff;
        }

        &:after {
            right: -20px;
            border-left: 20px solid #fff;
        }

        span {
            display: block;
            font-size: 24px;
            font-weight: bold;
        }
    }

    time,
    h3,
    p {
        position: relative;
        z-index: 2;
    }

    h3 {
        font-size: $font-size-h4;
        line-height: 1;
        margin-bottom: 5px;
        margin-left: -20px;
        margin-right: -20px;
    }

    p {
        margin: 0;
        font-size: 16px;
        margin-left: -20px;
        margin-right: -20px;
    }

    @include mq($until: mobileLandscape) {
        width: 140px;
        height: 232px;
        padding: 10px 0 0;

        &:before,
        &:after {
            border-top: 116px solid transparent;
            border-bottom: 116px solid transparent;
        }

        &:before {
            left: -70px;
            border-right: 70px solid $blue;
        }

        &:after {
            right: -70px;
            border-left: 70px solid $blue;
        }

        h3 {
            font-size: 16px;
        }

        p {
            font-size: 14px;
        }
    }
}

@include mq($from: tablet, $until: desktop) {
    .fundraising-feature-event-image,
    .fundraising-feature-event-detail {
        position: absolute;
        top: auto;
    }

    .fundraising-feature-event-image {
        left: 33%;
        transform: translate(-50%,0);
    }

    .fundraising-feature-event-detail {
        left: 66%;
        transform: translate(-50%,0);
    }
}

@include mq($until: tablet) {
    .fundraising-feature-event-image,
    .fundraising-feature-event-detail {
        position: relative;
        left: 50%;
        top: auto;
        clear: both;
        margin-bottom: 20px;
        transform: translate(-50%,0);
    }
}
