
/*

START
Modified from: https://codepen.io/andreasstorm/pen/yjLGGN

*/

.cbx {
    margin: auto;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    border: 0;
    clip: auto;
    height: auto;
    overflow: hidden;
    padding: auto;
    position: auto;
    width: auto;
}

.cbx span {
    display: inline-block;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
}

.cbx span:first-child {
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 0px;
    transform: scale(1);
    vertical-align: middle;
    border: 0;
    background: #fff;
    transition: all .2s ease;
}

.cbx span:first-child svg {
    position: absolute;
    top: 3px;
    left: 2px;
    fill: none;
    stroke: #FFFFFF;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all .3s ease;
    transition-delay: .1s;
    transform: translate3d(0, 0, 0);
}

.cbx span:first-child:before {
    content: "";
    width: 100%;
    height: 100%;
    background: $blue-dark;
    display: block;
    transform: scale(0);
    opacity: 1;
    border-radius: 50%;
}

.cbx span:last-child {
    padding-left: 8px;
}

.cbx:hover span:first-child {
    border-color: $blue-dark;
}

.inp-cbx:checked + .cbx span:first-child {
    background: $blue-dark;
    border-color: $blue-dark;
    animation: wave 0.4s ease;
}

.inp-cbx:checked + .cbx span:first-child svg {
    stroke-dashoffset: 0;
}

.inp-cbx:checked + .cbx span:first-child:before {
    transform: scale(3.5);
    opacity: 0;
    transition: all 0.6s ease;
}

@keyframes wave {
    50% {
        transform: scale(0.9);
    }
}

/*
*
*
*

END
Modified from: https://codepen.io/andreasstorm/pen/yjLGGN

*
*
*
*/
