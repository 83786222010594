//----------------------------------------------------
//      header
//----------------------------------------------------

.site-header {
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #fff;
    @include cf;
    background-image: url(../img/mesh-grey-3.png);
    background-repeat: no-repeat;
    background-position: 100% 0;
    background-size: auto 140%;
    position: relative;
    z-index: 300;

    @include mq($until: tablet) {
        background-image: url(../img/mesh-grey.png);
    }
}

.site-logo {
    @include imgreplace(201px, 110px, '../img/logo-strapline.png');
    background-size: contain;
    float: left;
    transition: .5s opacity ease;

    &:hover {
        opacity: .5;
    }

    @include mq($until: tablet) {
        width: 128px;
        height: 70px;
    }
}

.site-search {
    float: right;
    position: relative;
    width: (348/1200)*100%;
    margin-top: 30px;

    fieldset {
        border: 0;
        padding: 0;
        margin: 0;
    }

    input {
        border: 2px solid $grey;
        height: 50px;
        line-height: 50px;
        background-color: #fff;
        font-size: 19px;
        font-family: $font-sans;
        color: $black;
        width: 100%;
        padding-right: 50px;
        padding-left: 15px;
        @include placeholder($grey);
        transition: .5s border ease;

        &:focus {
            border-color: $blue;
            @include placeholder(#fff);
        }
    }

    @include mq($until: tablet) {
        margin-top: 10px;
        width: 50%;
        max-width: 348px;
    }
}

.site-search-go {
    border: 0;
    background-color: transparent;
    padding: 0;
    margin: 0;
    background-image: url(../img/search.png);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 18px 18px;
    width: 46px;
    height: 46px;
    position: absolute;
    top: 2px;
    right: 2px;
    text-indent: 115%;
    overflow: hidden;
    white-space: nowrap;
    transition: .5s opacity ease;

    &:hover {
        opacity: .7;
    }
}

.mobile-nav-toggle {
    border: 0;
    width: calc(100% - 110px);
    padding-left: 50px;
    padding-right: 20px;
    padding-top: 0;
    padding-bottom: 0;
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    display: none;
    line-height: 50px;
    background-color: $blue;
    background-image: url(../img/mobile-menu.png);
    background-repeat: no-repeat;
    background-position: 20px 19px;
    background-size: 12px 12px;
    text-align: left;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    position: relative;
    z-index: 300;

    &.active {
        background-image: url(../img/mobile-menu-close.png);
        background-color: darken($blue, 10%);
    }

    @include mq($until: tablet) {
        display: block;
        float: left;
    }
}

.site-nav {
    clear: both;
    background-color: $blue;
    @include cf;
    position: relative;
    z-index: 100;
    top: 0;
    width: 100%;

    &.stuck {
        position: fixed;
    }

    @include mq($until: tablet) {
        background-color: transparent;
        position: relative;

        .contain {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.site-nav-fake {
    height: 50px;
    display: none;
    width: 100%;
    position: relative;

    &.show {
        display: block;
    }
}

.site-nav-links {
    list-style: none;
    float: left;
    @include cf;

    > li {
        float: left;
        margin-right: 40px;
        position: relative;

        a {
            color: #fff;
            font-size: 19px;
            text-align: center;
            line-height: 50px;
            display: block;

            &:hover {
                text-decoration: underline;
            }
        }

        &.active {
            a {
                font-weight: $font-weight-bold;
            }

            &:after {
                content: '';
                display: block;
                top: 100%;
                left: 50%;
                transform: translate(-50%,0);
                border-top: 14px solid $blue;
                border-left: 14px solid transparent;
                border-right: 14px solid transparent;
                position: absolute;
                z-index: 4;
            }
        }

        > ul {
            z-index: -1;
            opacity: 0;
            position: absolute;
            transition: .5s opacity linear;
            top: 100%;
            left: -20px;
            width: 260px;
            background-color: $blue;
            list-style: none;
            display: none;

            > li {
                a {
                    text-align: left;
                    padding-left: 20px;
                    padding-right: 20px;
                    font-size: 16px;
                    line-height: 19px;
                    padding-top: 15px;
                    padding-bottom: 15px;
                }
            }
        }

        &:hover {
            > ul {
                opacity: 1;
                z-index: 100;
            }
        }
    }

    @include mq($from: desktop, $until: wide) {
        > li {
            margin-right: 30px;

            a {
                font-size: 17px;
            }
        }
    }

    @include mq($from: tablet, $until: desktop) {
        > li {
            margin-right: 15px;

            a {
                font-size: 15px;
            }
        }
    }

    @include mq($until: tablet) {
        float: none;
        position: absolute;
        top: 52px;
        left: 0;
        width: 100%;
        z-index: 200;
        transform: translate(0,-200%);
        transition: .5s transform ease;

        &.active {
            transform: translate(0,0);
        }

        > li {
            float: none;
            margin-right: 0;
            background-color: $blue;

            &.active {
                background-color: $orange;

                &:after {
                    display: none;
                }

                a {
                    font-weight: $font-weight-regular;
                }
            }

            &:not(:last-child) {
                border-bottom: 2px solid rgba(255,255,255,.2);
            }

            /*&:last-child {
                width: calc(100% - 40px);
                position: relative;

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    right: -40px;
                    top: 0;
                    height: 0;
                    width: 0;
                    border-left: 40px solid $blue;
                    border-bottom: 50px solid transparent;
                }
            }*/

            a {
                font-size: 18px;
                text-align: left;
                line-height: 50px;
                padding-left: 20px;
                padding-right: 20px;

                &.has-subnav {
                    position: relative;

                    &:before,
                    &:after {
                        content: '';
                        display: block;
                        top: 50%;
                        transform: translate(0,-50%);
                        background-color: #fff;
                        position: absolute;
                    }

                    &:before {
                        width: 12px;
                        height: 2px;
                        right: 20px;
                    }

                    &:after {
                        width: 2px;
                        height: 12px;
                        right: 25px;
                        transition: .5s height ease;
                    }
                }

                &.subnav-active {
                    &:after {
                        height: 2px;
                    }

                    + ul {
                        display: block;
                    }
                }
            }

            > ul {
                position: relative;
                top: auto;
                left: auto;
                z-index: 1;
                opacity: 1;
                display: none;
                background-color: darken($blue, 10%);
                width: 100%;
                transition: .5s visibility ease;
                visibility: hidden;

                &[aria-hidden="true"] {
                    visibility: hidden;
                }

                &[aria-hidden="false"] {
                    visibility: visible;
                }

                .active {
                    background-color: darken($blue, 20%);
                }
            }
        }
    }
}

.site-nav-donate {
    background-color: $pink;
    color: #fff;
    padding-left: 40px;
    padding-right: 40px;
    text-transform: uppercase;
    line-height: 50px;
    float: right;
    font-size: 19px;
    font-weight: $font-weight-bold;
    transition: .5s background-color ease;

    &:hover {
        background-color: darken($pink, 5%);
    }

    @include mq($from: desktop, $until: wide) {
        padding-left: 30px;
        padding-right: 30px;
        font-size: 17px;
    }

    @include mq($from: tablet, $until: desktop) {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 15px;
    }

    @include mq($until: tablet) {
        width: 110px;
        font-size: 16px;
        text-align: center;
        padding: 0;
        border-bottom: 2px solid #fff;
        position: relative;
        z-index: 300;
    }
}
