//----------------------------------------------------
//      content accordion
//----------------------------------------------------

.content-accordion-section {
    border-top: 1px solid $grey;
    transition: .5s height ease;

    &:last-of-type {
        border-bottom: 1px solid $grey;
    }
}

.content-accordion-toggle {
    border: 0;
    padding: 15px 0 0 58px;
    font-size: 22px;
    line-height: 33px;
    margin: 0;
    text-align: left;
    background-color: #fff;
    color: $orange;
    font-weight: $font-weight-bold;
    width: 100%;
    display: block;
    position: relative;
    transition: .5s color ease;

    &:hover {
        color: $blue;
    }

    &:before {
        width: 38px;
        height: 33px;
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 15px;
        background-image: url(../img/hex-open.png);
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: contain;
        transition: .5s background ease;
    }

    &.active {
        &:before {
            background-image: url(../img/hex-close.png);
        }
    }
}

.content-accordion-text {
    padding: 15px 0 0 58px;
    overflow: hidden;
    transition: .5s height ease, .5s visibility;

    &[aria-hidden="true"] {
        visibility: hidden;
    }

    &[aria-hidden="false"] {
        visibility: visible;
    }

    img {
        max-width: 100%;
    }

    p {
        &:first-child {
            padding-top: 15px;
        }
    }

    h2 {
        font-size: $font-size-h4;
    }

    h3 {
        font-size: $font-size-h5;
        color: $black;
        margin-bottom: 5px;
    }

    @include mq($until: mobileLandscape) {
        padding: 15px 0 0 0;
    }
}

//----------------------------------------------------
//      fix no no javascript
//----------------------------------------------------

.no-js {
    .content-accordion-text {
        padding: 15px 0 0 15px;

        &[aria-hidden="true"] {
            visibility: visible;
            height: auto;
        }
    }

    .content-accordion-toggle {
        padding-left: 15px;

        &:before {
            display: none;
        }

        &.active {
            &:before {
                display: none;
            }
        }
    }
}
