//----------------------------------------------------
//      event application form styles
//----------------------------------------------------

.justgiving-title,
.paypal-title {
    float: right;
    clear: right;
    margin-top: 0;

    @include mq($until: desktop) {
        float: none;
        clear: both;
    }
}

.justgiving-title {
    @include imgreplace(250px, 44px, '../img/logo-justgiving.png');
}

.paypal-title {
    @include imgreplace(362px, 52px, '../img/paypal-logo-smaller.png');

    @include mq($until: mobileLandscape) {
        width: 240px;
        height: 35px;
    }
}

.link-button-contain {
    float: left;
    clear: left;

    .link-button {
        clear: left;
    }

    @include mq($until: desktop) {
        float: none;
        clear: both;
    }
}

.discount-code {
    font-size: 20px;
    line-height: 24px;
}

.transaction-id,
.transaction-total,
.fundraising-page-title {
    font-weight: $font-weight-bold;
}

.transaction-id {
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
}

//----------------------------------------------------
//      breadcrumbs
//----------------------------------------------------

.breadcrumb {
    height: 50px;
    margin-bottom: 50px;
}

.breadcrumb-step {
    float: left;
    display: block;
    background-color: $orange;
    position: relative;
    height: 100%;
    padding: 0 20px 0 60px;
    margin-right: 23px;
    line-height: 50px;
    color: #fff;

    &:before,
    &:after {
        content: '';
        position: absolute;
        top: 0;
    }

    &:before {
        left: -20px;
        display: none;
        z-index: 1;
        border-top: 25px solid $orange;
        border-bottom: 25px solid $orange;
        border-left: 20px solid transparent;
    }

    &:after {
        right: -20px;
        z-index: 2;
        border-top: 25px solid transparent;
        border-bottom: 25px solid transparent;
        border-left: 20px solid $orange;
    }

    &:not(:first-of-type) {
        &:before {
            display: block;
        }
    }

    .breadcrumb-step-text {
        display: none;
    }

    @include mq($from: wide) {
        .breadcrumb-step-text {
            display: block;
        }
    }

    @include mq($until: tablet) {
        padding: 0 0 0 40px;
        font-size: 16px;
    }

    @include mq($from: tablet, $until: wide) {
        padding: 0 0 0 60px;
    }
}

.breadcrumb-step-current,
.breadcrumb-step-complete {
    background-color: $blue;

    &:after {
        border-left-color: $blue;
    }

    .breadcrumb-step-index {
        color: $blue;
    }

    & + .breadcrumb-step-complete,
    & + .breadcrumb-step-current {
        background-color: $blue;

        &:after {
            border-left-color: $blue;
        }

        &:before {
            border-top-color: $blue;
            border-bottom-color: $blue;
        }
    }
}

.breadcrumb-step-current {
    @include mq($until: wide) {
        .breadcrumb-step-text {
            display: block;
        }
    }
}

.breadcrumb-step-index {
    position: absolute;
    background-color: #fff;
    color: $orange;
    height: 34px;
    width: 20px;
    text-align: center;
    display: block;
    top: 8px;
    left: 20px;
    line-height: 35px;

    &:before,
    &:after {
        content: '';
        display: block;
        height: 0;
        position: absolute;
        top: 0;
        border-top: 17px solid transparent;
        border-bottom: 17px solid transparent;
    }

    &:before {
        left: -10px;
        border-right: 10px solid #fff;
    }

    &:after {
        right: -10px;
        border-left: 10px solid #fff;
    }

    @include mq($until: tablet) {
        height: 20px;
        width: 10px;
        font-size: 12px;
        line-height: 20px;
        top: 15px;

        &:before,
        &:after {
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
        }

        &:before {
            left: -6px;
            border-right: 6px solid #fff;
        }

        &:after {
            right: -6px;
            border-left: 6px solid #fff;
        }
    }
}

//----------------------------------------------------
//      forms
//----------------------------------------------------

.field-required {
    color: $pink;
    font-weight: $font-weight-bold;
    font-size: $font-size - 2px;
    padding-left: 10px;
    line-height: 1;
    display: inline-block;
}

.field-required-off {
    display: none;
}

.event-application-form,
.contact-form,
.bcrt-form,
article form {
    .error {
        display: block;
        font-size: 14px;
        color: $orange;
        font-weight: $font-weight-bold;
        margin-bottom: 10px;
        line-height: 1;
        clear: left;
        float: left;
    }

    p {
        margin-bottom: 10px;
    }

    fieldset {
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 2px solid $orange;
        padding: 0 0 40px 0;
        margin: 0 0 30px 0;

        > div {
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    legend {
        font-size: 30px;
        line-height: 1;
        display: block;
        clear: both;
        font-weight: $font-weight-bold;
        color: $orange;
        margin-bottom: 30px;
    }

    label {
        display: block;
        clear: both;
        margin-bottom: 10px;
        cursor: pointer;

        a {
            color: $orange;
            transition: .5s color ease;

            &:hover {
                color: $blue;
            }
        }
    }

    input[type=submit] {
        appearance: none;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        appearance: none;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

    input[type=text],
    input[type=email],
    input[type=number],
    input[type=tel],
    input[type=date],
    textarea {
        border: 2px solid #ccc;
        background-color: #fff;
        font-family: $font-sans;
        color: $black;
        font-size: 18px;
        @include placeholder($grey-dark);
        transition: .5s border-color ease;

        &:focus {
            @include placeholder(#fff);
            border-color: $blue;
            outline: 0;
        }
    }

    input[type=text],
    input[type=email],
    input[type=number],
    input[type=tel],
    input[type=date] {
        line-height: 46px;
        padding: 0 15px;
        height: 50px;
    }

    textarea {
        width: 100%;
        height: 150px;
        padding: 15px;
    }

    select {
        appearance: none;
        background: transparent;
        border: 0;
        border-radius: 0;
        width: 100%;
        height: 100%;
        line-height: 20px;
    }

    .select-wrapper {
        height: 50px;
        border: 2px solid #ccc;
        padding: 0 15px;
        //background-color: transparentize(#EBEBEB, .7);
        background-color: #F9F9F9;
        position: relative;

        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
            pointer-events: none;
        }

        &:before {
            top: 0;
            right: 0;
            width: 35px;
            height: 46px;
            background-color: #F9F9F9;
        }

        &:after {
            top: 18px;
            right: 17px;
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 10px solid $black;
        }
    }

    @include mq($until: mobileLandscape) {
        legend {
            font-size: 22px;
        }
    }
}

.bcrt-formalicious.bcrt-form {
    overflow:hidden;

    fieldset:last-of-type {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: 0;
    }

    .link-button,
    .link-button-wrapper {
        float: right;
        clear: right;
    }

    .link-button-wrapper {
        @include cf;
    }

    .link-button-back {
        float: left;
        clear: left;
    }
}


.bcrt-form-go-pay {
    legend {
        font-weight: $font-weight-regular;
        color: $black;
        line-height: 52px;

        @include mq($until: mobileLandscape) {
            line-height: 1.1;
        }
    }

    fieldset {
        padding-bottom: 0;
        border-bottom: 0;
    }

    .fee-amount {
        font-weight: $font-weight-bold;
        color: $orange;
    }

    .link-button {
        clear: left;
    }
}

.form-field-block {
    margin-bottom: 30px;
    @include cf;

    &.error-state {
        background-color: $grey;
        border: 2px solid $orange;
    }

    small {
        display: block;
        float: left;
        clear: left;
        margin-top: 5px;
        color: #ccc;
    }

    > input,
    > label {
        width: (344/710)*100%;
        float: left;
        clear: left;
    }

    > div {
        float: left;
        width: (344/710)*100%;

        &:nth-child(2n + 1) {
            margin-right: (20/710)*100%;
        }

        > input,
        > label {
            width: 100%;
        }
    }

    > .form-field-date-short,
    > .form-field-date-long {
        float: left;
        clear: none;
        margin-right: (20/710)*100%;

        &:first-of-type {
            clear: left;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }

    > .form-field-date-short {
        width: 64px;
    }

    > .form-field-date-long {
        width: 80px;
    }

    @include mq($until: mobileLandscape) {
        > div {
            width: 100%;
            margin-bottom: 20px;

            &:nth-child(2n + 1) {
                margin-right: 0;
            }
        }

        > input,
        > label {
            width: 100%;
        }

        .invisible {
            visibility: visible;
        }
    }
}

.form-field-block-long {
    width: 100%;
    float: none;

    .form-field-block & {
        > input,
        > label,
        > textarea,
        > div {
            width: 100%;
            float: none;
        }
    }

    > input,
    > label,
    > textarea,
    > div {
        width: 100%;
        float: none;
    }
}

.form-field-block-stacked {
    > div {
        width: 100%;
        float: none;
        margin-bottom: 20px;

        input {
            width: (344/710)*100%;
        }
    }

    @include mq($until: mobileLandscape) {
        > div {
            input {
                width: 100%;
            }
        }
    }
}

.form-field-block-checkbox,
.form-field-block-radio {
    position: relative;
    @include cf;
    margin-bottom: 20px;
    clear: left;

    input[type=checkbox],
    input[type=radio] {
        width: 20px;
        height: 20px;
        overflow: hidden;
        appearance: none;
        border: 0;
        background-color: transparent;
        position: absolute;
        top: 0;
        left: 0;

        &:focus {
            outline: 0;

            + label {
                &:before {
                    border-color: $blue;
                }
            }
        }

        &:checked {
            outline: 0;

            + label {
                &:before {
                    border-color: $blue;
                }

                &:after {
                    opacity: 1;
                }
            }
        }
    }

    > label {
        position: relative;
        padding-left: 35px;
        cursor: pointer;
        line-height: 27px;
        margin-bottom: 0;

        &:before,
        &:after {
            display: block;
            position: absolute;
        }

        &:before {
            content: '';
            border: 2px solid #ccc;
            background-color: #fff;
            width: 20px;
            height: 20px;
            transition: .5s border-color ease;
            left: 0;
            top: 2px;
        }

        &:after {
            width: 0;
            height: 0;
            opacity: 0;
            overflow: hidden;
            text-align: center;
            line-height: 20px;
            transition: .5s width ease, .5s height ease, .5s opacity ease;
        }

        &.field-group-label {
            padding-left: 0;
            display: block;
            font-weight: bold;
            margin-bottom: 10px;
            cursor: default;

            &:before,
            &:after {
                display: none;
            }
        }
    }
}

.form-field-block-checkbox {
    input[type=checkbox] {
        width: 20px;
        height: 20px;
        overflow: hidden;
        appearance: none;
        border: 0;
        background-color: transparent;
        position: absolute;
        top: 0;
        left: 0;

        &:focus {
            outline: 0;

            + label {
                &:before {
                    border-color: $blue;
                }
            }
        }

        &:checked {
            outline: 0;

            + label {
                &:before {
                    border-color: $blue;
                }

                &:after {
                    opacity: 1;
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

    > label {
        &:before,
        &:after {
            left: 0;
            top: 2px;
        }

        &:after {
            content: '✔';
            color: $orange;
        }
    }
}

.form-field-block-radio {
    input[type=radio] {
        &:checked {
            outline: 0;

            + label {
                &:before {
                    border-color: $blue;
                }

                &:after {
                    opacity: 1;
                }
            }
        }
    }

    > label {
        &:before,
        &:after {
            left: 0;
            top: 2px;
        }

        &:before {
            border-radius: 50%;
        }

        &:after {
            content: '';
            background-color: $orange;
            border-radius: 50%;
            top: 7px;
            left: 5px;
            width: 10px;
            height: 10px;
        }
    }
}

.form-block-information {
    @include cf;
    margin-bottom: 30px;
    font-size: 22px;
    font-weight: $font-weight-bold;

    > span {
        display: block;
        margin-bottom: 10px;
        font-size: 18px;
        line-height: 18px;
        font-weight: normal;
    }

    ul {
        font-weight: normal;
        font-size: 16px;
        margin: 0;
    }
}

.form-field-block-names {
    > div {
        margin-bottom: 20px;
        display: none;

        &.active {
            display: block;
        }
    }
}

.currency-input-wrapper {
    position: relative;
    height: 50px;

    &:before {
        content: '£';
        position: absolute;
        top: 14px;
        left: 15px;
        font-size: 18px;
    }

    > input[type=number] {
        padding-left: 30px;
        width: 100%;
    }
}

//----------------------------------------------------
//      important info block
//----------------------------------------------------

.important-information {
    background-color: $grey;
    color: #fff;
    padding: 30px;
    margin-bottom: 40px;

    h3 {
        color: $orange;
        font-size: 30px;
        margin-top: 0;
    }

    p {
        color: $black;
        margin-bottom: 0;
    }
}

//----------------------------------------------------
//      conference/ball specifics
//----------------------------------------------------

#adultnames,
#childnames {
    display: none;

    &.active {
        display: block;
    }
}

//----------------------------------------------------
//      share story specific stuff
//      also for event apply in memory of
//----------------------------------------------------

#i-have-had,
#they-have-had,
#lost-someone,
#in-memory-block,
#special-fund-block {
    display: none;

    &.active {
        display: block;
    }
}
