//----------------------------------------------------
//      newsletter signup form
//----------------------------------------------------

.newsletter-signup {
    background-color: $blue;
    background-image: url(../img/mesh-white-2.png);
    background-repeat: no-repeat;
    background-position: -50px 0;
    background-size: auto 130%;
    padding-top: 60px;
    padding-bottom: 60px;

    legend {
        color: #fff;
        font-size: 28px;
        font-weight: $font-weight-bold;
        line-height: 1;
        display: block;
        margin-bottom: 40px;
    }

    fieldset {
        padding: 0;
        border: 0;
        @include cf;
    }

    input {
        border: 2px solid #fff;
        line-height: 46px;
        height: 50px;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 0;
        padding-bottom: 0;
        width: 100%;
        @include placeholder($black);
        transition: .5s border-color ease;
        border-radius: 0;

        &:focus {
            border-color: $pink;
            @include placeholder(#fff);
        }
    }

    button {
        width: calc(100% - 20px);

        &:hover {
            background-color: #fff;
            color: $orange;

            &:after {
                border-left-color: #fff;
            }
        }
    }

    div {
        width: (270/1200)*100%;
        float: left;
        margin-right: (40/1200)*100%;

        &:last-child {
            margin-right: 0;
        }
    }

    &.slim {
        background-image: none;
        padding: 30px 30px 10px;

        form {
            padding: 0;
        }

        fieldset {
            margin-bottom: 0;
        }

        input {
            border: 2px solid #fff;
            width: 100%;

            &:focus {
                border-color: $pink;
            }
        }

        button {
            width: calc(100% - 20px);
        }

        div {
            width: (335/710)*100%;
            margin-right: (40/710)*100%;
            margin-bottom: 20px;

            &:nth-child(2n+2) {
                margin-right: 0;
            }
        }
    }

    @include mq($from: tablet, $until: desktop) {
        &.slim {
            div {
                width: 100%;
                margin-right: 0;
                margin-bottom: 20px;
            }
        }
    }

    @include mq($from: mobileLandscape, $until: tablet) {
        div {
            width: 48%;
            margin-bottom: 20px;

            &:nth-child(odd) {
                margin-right: 4%;
            }

            &:nth-child(even) {
                margin-right: 0;
            }
        }
    }

    @include mq($until: mobileLandscape) {
        div {
            width: 100%;
            margin-bottom: 20px;
            margin-right: 0;
        }

        &.slim {
            div {
                width: 100%;
                margin-right: 0;
                margin-bottom: 20px;
            }
        }
    }
}
