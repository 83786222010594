//----------------------------------------------------
//      basic typography
//----------------------------------------------------

body {
    font-family: $font-sans;
    font-size: $font-size;
    color: $black;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.site-header,
.site-nav,
.site-footer {
    flex: 0 0 auto;
}

main {
    flex: 1 1 auto;
}

p {
    margin-bottom: 30px;
    line-height: (26/18)*100%;

    &.content-intro {
        font-size: 22px;
        line-height: (30/24)*100%;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-heading;
    font-weight: $font-weight-bold;
    line-height: $line-height-base;
    margin-top: 20px;
    margin-bottom: 0;
}

@each $header, $size in (h1: $font-size-h1, h2: $font-size-h2, h3: $font-size-h3, h4: $font-size-h4, h5: $font-size-h5, h6: $font-size-h6) {
    #{$header} {
        font-size: $size;
    }
}

h2 {
    line-height: (54/47)*100%;
    margin-top: 0;
    margin-bottom: 20px;
}

article {
    ul,
    ol,
    p,
    h2,
    h3,
    h4,
    h5,
    h6,
    table,
    blockquote {
        clear: both;
    }

    em {
        font-style: italic;
    }

    hr {
        border: 1px solid $grey;
        border-width: 1px 0 0 0;
        display: block;
        clear: both;
        margin-bottom: 30px;
    }

    blockquote {
        margin-bottom: 30px;
        font-size: 26px;
        line-height: (30/26)*100%;
        margin-bottom: 40px;
        position: relative;
        padding-top: 40px;
        color: $blue;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 40px;
            height: 30px;
            background-image: url(../img/quote-mark.png);
            background-repeat: no-repeat;
            background-position: 0 0;
            background-size: contain;
        }

        p {
            margin: 0;
            line-height: (32/26)*100%;
        }

        p + cite {
            margin-top: 10px;
            display: block;
        }

        @include mq($from: tablet, $until: desktop) {
            font-size: 24px;
            line-height: (28/24)*100%;
        }

        @include mq($until: tablet) {
            font-size: 22px;
            line-height: (26/22)*100%;
        }
    }

    cite {
        font-size: 18px;
        color: $black;
        display: block;
        line-height: (26/18)*100%;
    }

    ul {
        list-style: none;
        margin-bottom: 30px;

        li {
            margin-bottom: 25px;
            position: relative;
            padding: 0 0 0 20px;
            line-height: (26/18)*100%;

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 8px;
                left: 0;
                background-color: $orange;
                width: 8px;
                height: 8px;
            }
        }

        ul {
            margin: 20px 0;

            li {
                margin-bottom: 15px;
            }
        }

        h2,
        h3 {
            margin: 0 0 10px;
        }
    }

    ol {
        list-style: none;
        counter-reset: listblock;
        margin-bottom: 30px;

        li {
            padding: 0 0 0 24px;
            position: relative;
            margin-bottom: 25px;
            line-height: (26/18)*100%;

            &:before {
                counter-increment: listblock;
                content: counter(listblock);
                display: block;
                position: absolute;
                top: 2px;
                left: 0;
                color: $orange;
                font-size: 18px;
                line-height: 1;
                font-weight: $font-weight-bold;
            }
        }
    }

    > time {
        display: block;
        font-weight: $font-weight-bold;
        margin-bottom: 20px;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $blue;
    }

    table {
        border-collapse: collapse;
        border: 0;
        width: 100%;
        margin: 0 0 30px 0;

        th,
        td {
            text-align: left;
            padding: 15px;
        }

        th {
            font-weight: $font-weight-bold;
            border-bottom: 3px solid $black;
        }

        td {
            border-bottom: 1px solid $grey;
        }
    }

    img {
        vertical-align: bottom;
        max-width: 100%;
    }

    @include mq($until: mobileLandscape) {
        table {
            th,
            td {
                padding: 4px;
                font-size: 16px;
            }
        }
    }
}

h3 {
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 30px;
}

h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
    a {
        color: $orange;
        transition: .5s color ease;

        &:hover {
            color: $blue;
        }
    }
}

//----------------------------------------------------
//  other base styles
//----------------------------------------------------

a {
    text-decoration: none;

    &:hover {

    }
}

//----------------------------------------------------
//      accessibliity thing
//----------------------------------------------------

a,
button,
input,
select,
textarea,
label {
    &:focus {
        outline: 3px inset $accessibility;
    }

    .no-focus & {
        outline: 0;
    }
}

//----------------------------------------------------
//      standard container divs
//----------------------------------------------------

.contain,
.contain-large {
    position: relative;
    margin: 0 auto;
    @include cf;
}

.contain {
    max-width: 1240px;
    padding-left: 20px;
    padding-right: 20px;
}

.contain-large {
    max-width: 1600px;
}

//----------------------------------------------------
//      old IE warning
//----------------------------------------------------

.old-browser {
    padding: 20px;
    background-color: $blue;
    color: #fff;
    font-size: 16px;
    line-height: 19px;
    position: fixed;
    top: 200px;
    left: 50%;
    margin-left: -180px;
    width: 560px;
    display: none;
    text-align: center;

    p {
        margin: 0 0 15px 0;

        &:last-child {
            margin: 0;
        }
    }

    small {
        display: block;
        margin-top: 20px;
        line-height: 12px;
        font-size: 12px;
    }

    &.visible {
        display: block;
    }
}

.old-browser-accept {
    border: 0;
    padding: 10px 20px;
    background-color: $orange;
    color: #fff;
    text-align: center;
    font-size: 16px;
    line-height: 16px;
    display: inline-block;

    &:hover,
    &:focus {
        background-color: $pink;
        color: #fff;
    }
}

//----------------------------------------------------
//      clear main in case of floats
//----------------------------------------------------

main {
    @include cf;

    .news & {
        background-image: url(../img/hex-bottom-right.png);
        background-position: 100% 100%;
        background-repeat: no-repeat;
        background-size: auto 214px;
    }

    @include mq($until: desktop) {
        .news & {
            background-image: none;
        }
    }
}
