.shineastar {
    background: $blue;
    width: 100%;
    overflow-x: hidden;

    @import "./_banner";

    @import "./_allstar";

    @import "./_donate";

    @import "./_form";

    @import "./_starOverlay";

    /* Custom keyframes */

    @-webkit-keyframes shineYourStar {

        0% {
            opacity: 0;
            box-shadow: 0px 0px 10px 5px rgba(250, 214, 38, .5);
        }

        50% {
            opacity: 1;
            box-shadow: 0px 0px 20px 10px rgba(250, 214, 38, .5);
        }

        100% {
            opacity: 0;
            box-shadow: 0px 0px 10px 5px rgba(250, 214, 38, .5);
        }
    }

    @-moz-keyframes shineYourStar {

        0% {
            opacity: 0;
            box-shadow: 0px 0px 10px 5px rgba(250, 214, 38, .5);
        }

        50% {
            opacity: 1;
            box-shadow: 0px 0px 20px 10px rgba(250, 214, 38, .5);
        }

        100% {
            opacity: 0;
            box-shadow: 0px 0px 10px 5px rgba(250, 214, 38, .5);
        }
    }

    @-o-keyframes shineYourStar {

        0% {
            opacity: 0;
            box-shadow: 0px 0px 10px 5px rgba(250, 214, 38, .5);
        }

        100% {
            opacity: 1;
            box-shadow: 0px 0px 20px 10px rgba(250, 214, 38, .5);
        }

        100% {
            opacity: 0;
            box-shadow: 0px 0px 10px 5px rgba(250, 214, 38, .5);
        }
    }

    @keyframes shineYourStar {

        0% {
            opacity: 0;
            box-shadow: 0px 0px 10px 5px rgba(250, 214, 38, .5);
        }

        50% {
            opacity: 1;
            box-shadow: 0px 0px 20px 10px rgba(250, 214, 38, .5);
        }

        100% {
            opacity: 0;
            box-shadow: 0px 0px 10px 5px rgba(250, 214, 38, .5);
        }
    }

    button:focus,
    input:focus,
    select:focus,
    textarea:focus,
    a:focus,
    label:focus,
    input[type=submit]:focus {
        box-shadow: 0px 0px 5px 4px #7ED321, inset 0px 0px 0px 2px white;
        outline: none;
    }

    &:not(.user-is-tabbing) button:focus,
    &:not(.user-is-tabbing) input:focus,
    &:not(.user-is-tabbing) select:focus,
    &:not(.user-is-tabbing) textarea:focus,
    &:not(.user-is-tabbing) a:focus,
    &:not(.user-is-tabbing) label:focus {
        box-shadow: none;
        outline: none;
    }

    header {
        position: fixed;
        width: 100%;
        background: transparent;

        .contain {
            max-width: 1366px;
            padding-left: 0;
            padding-right: 0;
        }

        &.site-header {
            padding-top: 35px;

            .social-donate {
                height: 100px;
                margin-top: 0;
                transition: .5s ease height;
            }

            .both-logo {
                position: relative;
                width: 210px;
                height: 110px;
                display: inline-block;
                float: left;
                transition: .5s opacity ease, .5s width ease, .5s height ease;

                a {
                    position: absolute;
                    transition: .5s opacity ease, .5s width ease, .5s height ease;
                }

                .site-logo-fixed {
                    display: block;
                    text-indent: 110%;
                    white-space: nowrap;
                    overflow: hidden;
                    width: 201px;
                    height: 110px;
                    background-color: transparent;
                    background-image: url("../img/shineastar/logo-shineastar-fixed.png");
                    background-position: 0 0;
                    background-repeat: no-repeat;
                    background-size: contain;
                    float: left;
                    position: absolute;
                    left: -1px;
                    top: -3px;
                    opacity: 0;
                }
            }

            &.scrolled {

                .both-logo {
                    width: 60px;

                    a {
                        height: 60px;
                        width: 50px;
                    }

                    .site-logo {
                        opacity: 0;
                    }

                    .site-logo-fixed {
                        opacity: 1;
                    }
                }

                .social-donate {
                    height: 60px;
                }
            }

        }
    }

    .background-video {
        position: absolute;
        min-width: 100%;
        min-height: 100%;
    }

    main {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        a,
        span {
            color: #fff;

            &.thin {
                font-weight: 400;
            }
        }
    }

    section {
        min-height: 100vh;
    }

    .landing {
        background-image: url("../img/shineastar/shineastar-background-img.jpg");
        background-size: cover;
        background-position: center;
        position: relative;
        overflow: hidden;
        min-height: 540px;

        height: 100vh; /**  Fallback for browsers that do not support Custom Properties **/
        height: calc(var(--vh, 1vh) * 100);
    }

    .big-button {
        background-color: $yellow;
        text-transform: uppercase;
        line-height: 1;
        padding: 20px 40px;
        font-size: $font-size-h2;
        font-weight: bold;
        background: radial-gradient($yellow 0%, $yellow 50%, $yellow 75%, $yellow 90%);
        transition: .5s background-color ease, .5s background ease;
        color: $blue-dark;
        border: 0;
        position: relative;

        &:after {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background: radial-gradient(#FFD458 0%, #FFD458 55%, #FAC52C 75%, #ffc928 90%);
            opacity: 0;
            transition: .4s ease opacity;
            z-index: 1;
        }

        .text {
            color: $blue-dark;
            position: relative;
            z-index: 2;
            display: block;
        }

        &:hover {
            background-color: transparent;
        }

        &:hover:after {
            opacity: 1;
        }

        &.smaller {
            font-size: 20px;
            padding: 12.5px 20px;
        }

        &:disabled,
        &[disabled] {
            background: $blue;
            color: rgba(255, 255, 255, .5);
            border: 0;

            span {
                color: rgba(255, 255, 255, .5);
            }

            &:hover {
                background-color: $blue;
            }

            &:hover:after {
                opacity: 0;
            }
        }

    }

    label,
    legend {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    .site-header {
        background-color: transparent;
        background-image: linear-gradient(0deg, rgba(8, 1, 62, 0) 0%, #08013E 100%);

        .site-logo {
            background-image: url("../img/shineastar/logo-shineastar.png");
        }

        .social-donate {
            float: right;
            clear: right;
            line-height: 32px;
            margin-top: 52px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .site-nav-donate {
            margin-left: 10px;
        }

        .shineastar-social {
            margin-top: 0;

            .social-facebook {
                background-image: url("../img/shineastar/shineastar-social-facebook.png");
                display: none;
            }

            .social-twitter {
                background-image: url("../img/shineastar/shineastar-social-twitter.png");
                display: none;
            }
        }
    }

    .shining-star {
        width: 50px;
        height: 50px;
        position: absolute;
        background-image: url("../img/shineastar/shineastar-small-star.png");
        background-size: cover;
        background-repeat: no-repeat;
        top: 20%;
        left: 12.5%;

        &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 1px;
            height: 1px;
            border-radius: 50%;
            -webkit-animation: shineYourStar 2.5s infinite; /* Safari 4+ */
            -moz-animation: shineYourStar 2.5s infinite; /* Fx 5+ */
            -o-animation: shineYourStar 2.5s infinite; /* Opera 12+ */
            animation: shineYourStar 2.5s infinite; /* IE 10+, Fx 29+ */
        }

        &.shining-star-large {
            background-image: url("../img/shineastar/banner-starGroup.svg");
            width: 150px;
            height: 150px;
        }

        &.pos-2 {
            top: 85%;
            left: 5%;
        }

        &.pos-2:after {
            -webkit-animation: shineYourStar 3s infinite; /* Safari 4+ */
            -moz-animation: shineYourStar 3s infinite; /* Fx 5+ */
            -o-animation: shineYourStar 3s infinite; /* Opera 12+ */
            animation: shineYourStar 3s infinite; /* IE 10+, Fx 29+ */
        }

        &.pos-3 {
            top: 80%;
            left: 60%;
        }

        &.pos-3:after {
            -webkit-animation: shineYourStar 4s infinite; /* Safari 4+ */
            -moz-animation: shineYourStar 4s infinite; /* Fx 5+ */
            -o-animation: shineYourStar 4s infinite; /* Opera 12+ */
            animation: shineYourStar 4s infinite; /* IE 10+, Fx 29+ */
        }

        &.pos-4 {
            top: 9%;
            left: 36%;
        }

        &.pos-4:after {
            -webkit-animation: shineYourStar 5s infinite; /* Safari 4+ */
            -moz-animation: shineYourStar 5s infinite; /* Fx 5+ */
            -o-animation: shineYourStar 5s infinite; /* Opera 12+ */
            animation: shineYourStar 5s infinite; /* IE 10+, Fx 29+ */
        }

        &.pos-5 {
            top: 40%;
            left: 80%;
        }

        &.pos-5:after {
            -webkit-animation: shineYourStar 1s infinite; /* Safari 4+ */
            -moz-animation: shineYourStar 1s infinite; /* Fx 5+ */
            -o-animation: shineYourStar 1s infinite; /* Opera 12+ */
            animation: shineYourStar 1s infinite; /* IE 10+, Fx 29+ */
        }

        &.pos-6 {
            top: 25%;
            left: 70%;
        }

        &.pos-6:after {
            -webkit-animation: shineYourStar 1.75s infinite; /* Safari 4+ */
            -moz-animation: shineYourStar 1.75s infinite; /* Fx 5+ */
            -o-animation: shineYourStar 1.75s infinite; /* Opera 12+ */
            animation: shineYourStar 1.75s infinite; /* IE 10+, Fx 29+ */
        }

        &.pos-7 {
            top: 75%;
            left: 85%;
        }

        &.pos-7:after {
            -webkit-animation: shineYourStar 1s infinite; /* Safari 4+ */
            -moz-animation: shineYourStar 1s infinite; /* Fx 5+ */
            -o-animation: shineYourStar 1s infinite; /* Opera 12+ */
            animation: shineYourStar 1s infinite; /* IE 10+, Fx 29+ */
        }

        &.pos-8 {
            top: 55%;
            left: 70%;

            @include mq($until: tablet) {
                display: none;
            }

            &::after {
                top: 78%;
                left: 60%;
            }
        }

        &.pos-9 {
            transform: scaleX(-1);
            top: 55%;
            left: 17%;

            @include mq($until: tablet) {
                display: none;
            }

            &::after {
                top: 78%;
                left: 61%;
            }
        }
    }

    /* Responsive */

    @include mq($from: mobile, $until: tablet) {

        section {

            &.form {
                padding-bottom: 50px;
            }
        }
    }

    @media screen and (max-width: 63.99em) {

        header {

            .site-social-links {
                position: none;
                float: none;
                clear: none;
                text-align: inherit;
                font-size: inherit;
                height: auto;
                margin-top: 0;
                left: auto;
                width: auto;
                -ms-transform: none;
                transform: none;
                padding-top: 0;
                margin-bottom: 0;
            }
        }
    }

    @media screen and (max-width: 63.99em) and (min-width: 48em) {

        .site-nav-donate {
            padding-left: 40px;
            padding-right: 40px;
            font-size: 19px;
        }
    }

    @media screen and (max-width: 47.99em) {

        .site-nav-donate {
            border-bottom: 0;
        }

        section .site-nav-donate {
            padding: 12.5px 20px;
            width: auto;
        }
    }

    @media screen and (max-width: 1366px) {

        header .contain {
            padding-left: 20px;
            padding-right: 20px;
        }

        .shineastar-logo {
            background-image: none;
        }
    }

    @media screen and (max-width: 1023px) {

        .form {

            .form-inside {
                padding: 30px 25px 0px;
            }

            &.final-stage {

                form {

                    &.final-stage-form {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        h1 {
                            padding: 0 20px;
                        }
                    }
                }

                fieldset {
                    &.section-5 {
                        padding-top: 10px;
                        top: 0;
                        position: static;
                    }
                }

                .form-output {
                    height: 0px;
                }
            }

            input,
            textarea,
            select {
                padding: 5.5px 12.5px;
            }

            select {
                margin-top: 10px;
            }

            p {
                margin-bottom: 0;
            }

            h2 {
                margin-bottom: 12.5px;
            }
        }

        .allstar {
            min-height: 900px;

            @include mq($until: tablet) {
                min-height: 650px;
            }

            .button-container {
                width: 75px;
                height: 75px;

                .button-nav {
                    width: 75px;
                    height: 75px;
                    background-size: 30px;
                }
            }
        }
    }

    // note
    @media screen and (max-width: 767px) {

        .form {

            #donate-title {
                font-size: 21px;
                line-height: 24px;
                max-width: 85%;
                padding-top: 10px;
            }
        }

        .shineastar-logo {
            background-position: 0 0, 100% 0 !important;
            background-size: 340px, 340px !important;

            .main-logo {
                display: none;
            }
        }

        .form {

            form {
                width: 85%;
            }

            .form-inside {
                //max-height: 90%;
            }

            p {
                font-size: 16px;
                line-height: 17px;
            }
        }

        .allstar {
            min-height: 650px;
        }
    }

    @media screen and (max-width: 565px) {

        header {
            position: absolute;
        }

        header.site-header {

            &.scrolled {

                .both-logo {

                    .site-logo {
                        opacity: 1;
                        width: 128px;
                        height: 70px;
                    }

                    .site-logo-fixed {
                        opacity: 0;
                    }
                }

                .social-donate {
                    height: 100px;
                }
            }
        }

        .donate h1,
        .form.final-stage form.final-stage-form h1,
        h1 {
            font-size: 34px;
            padding: 0;
            line-height: 41px;
        }

        .form.final-stage form.final-stage-form p,
        p {
            font-size: 18px;
            line-height: 21px;
        }

        .form.final-stage form.final-stage-form .section-5 {
            padding-top: 0;
        }

        .form.final-stage .form-output {
            height: 0;
        }

        .form .form-wrapper.fieldset-3-active .form-output {

            svg {
                width: 300px;
            }
        }

        .big-button {

            &.smaller {
                padding: 9.5px 20px;
            }
        }

        .form .form-step-container .form-step {
            height: 25px;
            width: 25px;
            border: 3px solid #3474BD;
            font-size: 12px;
        }

        .form {

            form {
                width: 100%;
            }

            h2 {
                font-size: 20px;
                text-align: center;
            }

            p,
            span {
                font-size: 13px;
            }

            p,
            span,
            select {
                text-align: center;
            }


            input,
            textarea {
                font-size: 16px;
            }

            .form-wrapper {

                .form-output {

                    .form-output-field {

                        h3 {
                            font-size: 16px;
                            margin-bottom: 0;
                            margin-top: 10px;
                        }

                        p {
                            margin-bottom: 5px;
                            font-size: 12px;
                        }

                        h4 {
                            font-size: 14px;
                        }
                    }
                }
            }

            &.final-stage {

                .form-inside {

                    .form-wrapper {
                        padding: 0;
                    }

                    p {
                        margin-bottom: 15px;
                    }
                }
            }
        }

        .allstar {

            .allstars-inside {

                .large-image {
                    min-width: 200px;

                    svg {
                        max-width: 300px;
                        // max-width: 285px;
                    }
                }
            }
        }
    }

    @include mq($until: mobileLandscape) {

        header {
            position: absolute;

            &.site-header .both-logo {
                width: 80px;

                a {
                    width: 70px;
                }

                .site-logo {
                    text-indent: 300%;
                    width: 70px;
                }
            }
        }

        .shineastar-logo {
            background-size: 340px, 340px !important;
        }

        header.site-header .social-donate {
            height: 50px;
        }

        .site-nav-donate {
            width: 73px;
            font-size: 14px;
            border: 0;
            line-height: 26px;
        }

        .form {

            .form-inside {
                padding: 10px;
            }

            .section-4 .flex {
                margin: 0 0 5px;
            }

            select {
                margin-bottom: 5px;
            }

            input[type=email] {
                margin-bottom: 5px;
            }

            &.final-stage {

                form.final-stage-form {

                    input[type=text],
                    input[type=number] {
                        font-size: 42px;
                        line-height: 48px;
                    }

                    h1 {
                        font-size: 22px;
                        line-height: 24px;
                    }

                    p {
                        font-size: 16px;
                        line-height: 18px;
                    }

                    #nodonation {
                        font-size: 14px;
                    }

                    [type="radio"]:checked + label.donation-single-label,
                    [type="radio"]:checked + label.donation-single-label {
                        margin-right: 0;
                    }
                }
            }
        }

        .allstar {

            .allstars-inside {
                // grid-template-columns: 375px repeat(auto-fill, 200px);

                .large-image {
                    svg {
                        // max-width: 355px;
                        max-width: 285px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 390px) {

        .allstar {

            .allstars-inside {

                .large-image {
                    padding-left: 0;

                    svg {
                        max-width: 275px;
                        width: 100%;
                    }
                }
            }
        }
    }

    @import "./_customInputFileField";

    @import "./_customCheckbox";

    @import "./_customRadioButton";

    html.no-cssgrid .shineastar .allstar .allstars-inside {
        display: flex;

        .large-image {
            max-width: 650px;

            img {
                width: 650px;
                height: 628px;
            }
        }
    }

    &:not(.matched-donation) {

        .matched-donation-visible {
            display: none !important;
        }
    }

    .donation-details-flex {
        display: flex;
    }

    &.matched-donation {

        .matched-donation-hide {
            display: none !important;
        }

        .matched-donation-visible {
            display: initial;
        }

        .matched-donation-explanation {
            text-align: center;
        }

        .form.final-stage .form-inside .form-wrapper .donate-inside .donation-details-container {
            display: block;
            border-color: $yellow;
            margin-bottom: 10px;
            max-width: 97%;
            width: 475px;
            box-sizing: border-box;

            @media screen and (max-width: 767px) {
                width: 300px;
                border: 0;
                padding: 0;
            }

            img {
                max-width: 100%;
            }

            &::before {
                border-color: transparent transparent $yellow transparent;
            }
        }

        .form.final-stage .form-inside .site-nav-donate {
            line-height: 2.5;
        }

        .form.final-stage .form-inside p {
            margin-bottom: 25px;
            display: block;
        }

        .donate {

            p {
                display: block;
                max-width: 732px;
            }

            .site-nav-donate {
                display: none;

                @media screen and (max-width: 768px) {
                    line-height: 1.25;
                }

                &.matched-donation-visible {
                    display: inline-block;
                    margin-top: 15px;
                }
            }

            .donate-inside {

                .donation-details2-container {
                    border-color: $yellow;
                    background: $blue-dark;
                    position: relative;
                    z-index: 2;

                    @media screen and (max-width: 767px) {
                        border: 0;
                        background: transparent;

                        &::before {
                            border: 0;
                        }
                    }

                    &::before {
                        border-color: transparent transparent $yellow transparent;
                    }
                }
            }
        }
    } /* Matched donation specific closing tag */

    .donation-amount-other-label {

        .mobile {
            display: none;
        }

        .desktop {
            display: inline;
        }
    }

    @media screen and (max-width: 767px) {

        .donation-amount-first,
        .donation-amount2-first,
        .donation-amount-second,
        .donation-amount2-second,
        .donation-amount-third,
        .donation-amount2-third {
            display: none !important;
        }
    }

    @media screen and (max-width: 767px) {

        .donation-amount-other-label {

            .mobile {
                display: inline;
                color: $blue-mid !important;
            }

            .desktop {
                display: none;
            }
        }

        .donation-details-second,
        .donation-details2-second {
            display: none !important;
        }

        .donation-details-other,
        .donation-details2-other {
            display: block !important;
            margin: 0 auto;

            p {
                margin: 0 auto !important;
            }
        }
    }

    .ie-error-p {
        display: none;
    }

    &.ie-error {

        main section,
        .site-header {
            display: none;
        }

        main {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .ie-error-p {
            width: 650px;
            max-width: 95%;
            text-align: center;
            margin: 0 auto;
        }
    }
}
