//----------------------------------------------------
//      footer
//----------------------------------------------------

.site-footer {
    padding-top: 40px;
    padding-bottom: 50px;
    background-color: #fff;
    position: relative;

    &:before {
        content: '';
        display: block;
        width: 100%;
        height: 10px;
        position: absolute;
        top: 0;
        left: 0;
        background: #3474bd;
        background: linear-gradient(60deg, #3474bd 0%, #3474bd 60%, #ee5f1c 60%, #ee5f1c 100%);
    }

    .contain {
        @include cf;
    }
}

.logo-strapline {
    @include imgreplace(188px, 100px, '../img/logo-strapline.png');
    float: right;

    @include mq($until: desktop) {
        float: none;
        clear: both;
        left: 50%;
        position: relative;
        transform: translate(-50%,0);
        margin-bottom: 40px;
        width: 150px;
        height: 80px;
    }
}

.site-copyright {
    float: left;
    margin-bottom: 30px;
    font-size: 17px;
    line-height: 26px;

    span {
        font-weight: $font-weight-bold;
        display: block;
    }

    @include mq($from: desktop, $until: wide) {
        font-size: 15px;
        line-height: 24px;
    }

    @include mq($until: desktop) {
        font-size: 14px;
        line-height: 23px;
        float: none;
        text-align: center;
        margin-bottom: 20px;
    }
}

.site-footer-nav {
    float: left;
    clear: left;
    margin-bottom: 35px;

    ul {
        list-style: none;
        @include cf;
    }

    li {
        float: left;
        position: relative;

        &:not(:last-child) {
            margin-right: 10px;
            padding-right: 15px;

            &:after {
                display: block;
                content: '';
                width: 5px;
                height: 5px;
                background-color: $orange;
                position: absolute;
                right: 0;
                top: 9px;
            }
        }
    }

    a {
        display: block;
        color: $black;
        transition: .5s color ease;
        font-size: 17px;

        &:hover {
            color: $orange;
        }
    }

    @include mq($from: desktop, $until: wide) {
        a {
            font-size: 15px;
        }
    }

    @include mq($until: desktop) {
        float: none;
        clear: both;
        margin-bottom: 20px;

        ul {
            text-align: center;
        }

        li {
            float: none;
            display: inline-block;
        }

        a {
            font-size: 14px;
        }
    }
}

.site-social-links {
    float: right;
    clear: right;
    @include cf;
    line-height: 32px;
    margin-top: 52px;

    a {
        display: block;
        float: left;
        margin-left: 10px;
        text-indent: 115%;
        overflow: hidden;
        white-space: nowrap;
        width: 38px;
        height: 32px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 0 0;
        transition: .5s transform ease;

        &:hover {
            transform: translate(0,-3px);
        }

        &:first-child {
            margin-left: 0;
        }
    }

    @include mq($until: desktop) {
        position: relative;
        float: none;
        clear: both;
        text-align: center;
        font-size: 14px;
        height: 28px;
        margin-bottom: 40px;
        margin-top: 0;
        left: 50%;
        width: 182px;
        transform: translate(-50%,0);
        padding-top: 0;
    }
}

.social-facebook {
    background-image: url(../img/social-facebook.png);
}

.social-twitter {
    background-image: url(../img/social-twitter.png);
}

.social-youtube {
    background-image: url(../img/social-youtube.png);
}

.social-flickr {
    background-image: url(../img/social-flickr.png);
}

.social-instagram {
    background-image: url(../img/social-instagram.png);
}

.site-supporters {
    float: left;
    clear: left;
    @include cf;

    span {
        text-indent: 115%;
        overflow: hidden;
        white-space: nowrap;
        height: 55px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 0 0;
        display: block;
        float: left;

        &:not(:last-child) {
            margin-right: 50px;
        }
    }

    @include mq($from: tablet, $until: desktop) {
        float: none;
        clear: both;
        text-align: center;

        span {
            float: none;
            display: inline-block;
        }
    }

    @include mq($until: desktop) {
        float: none;
        clear: both;
        text-align: center;

        span {
            height: 36px;
            display: inline-block;
            float: none;

            &:not(:last-child) {
                margin-right: 20px;
            }
        }
    }
}

.supporter-fr {
    width: 126px;
    background-image: url(../img/logo-fr.png);

    @include mq($until: desktop) {
        width: 80px;
    }
}

.supporter-amrc {
    width: 112px;
    background-image: url(../img/logo-amrc.png);

    @include mq($until: desktop) {
        width: 66px;
    }
}

.supporter-tis {
    width: 112px;
    background-image: url(../img/logo-tis.png);

    @include mq($until: desktop) {
        width: 70px;
    }
}

.no-script-warning {
    background-color: $blue;
    color: #fff;
    text-align: center;
    padding: 20px;
    margin: 0;
    line-height: 1;
}
