/* Banner section */

.shineastar-logo {
    width: 1366px;
    position: relative;
    max-width: 100%;
    height: 100vh;
    background-image: url(../img/shineastar/home-star-left.png), url(../img/shineastar/home-star-right.png);
    margin: 0 auto;
    background-position: 0 -40%, 100% -35%;
    background-size: 340px, 340px;
    background-repeat: no-repeat;
    transition: background-position .4s ease-in-out, background-size .4s ease-out;

    .main-logo {
        opacity: 0;
        transition: opacity .4s ease-in-out;
        position: fixed;
        top: -30px;
        left: 50%;
        transform: translate(-50%);
        width: 250px;
        z-index: 999;
    }

    .shineastar-logotitle-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .shineastar-logo-container {
        background-image: url("../img/shineastar/shineastar-logo.png");
        transition: background-position .4s ease;
        height: 60%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center bottom;
        z-index: 299;
    }
}

.banner-text {
    width: 100%;
    max-width: 550px;
    margin: 0 auto;
    text-align: center;
    padding-top: 0;
    padding-bottom: 25px;

    h1 {
        font-size: 36px;
        line-height: 44px;
        padding: 0 10px;
        position: relative;
        z-index: 10;

        @include mq($until: tablet) {
            margin-top: 0;
            font-size: 24px;
            line-height: 31px;
        }
    }

    a {
        clear: both;

        &.seeall {
            display: block;
            text-decoration: underline;
        }
    }

    .big-button {
        display: inline-block;
        margin: 35px 0;
        box-shadow: 0 0 10px 0 rgba(255, 242, 95, .75);

        @include mq($until: tablet) {
            margin: 15px 0;
            font-size: 21px;
            padding: 15px 35px;
        }
    }
}
