//----------------------------------------------------
//      homepage carousel
//----------------------------------------------------

.carousel {
    position: relative;
    height: 530px;

    @include mq($from: desktop, $until: wide) {
        height: 430px;
    }

    @include mq($from: tablet, $until: desktop) {
        height: 380px;
    }

    @include mq($from: mobileLandscape, $until: tablet) {
        height: 640px;
    }

    @include mq($until: mobileLandscape) {
        height: 580px;
    }
}

.carousel-slide-images {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: (1100/1600)*100%;
    z-index: 1;

    &:after {
        content: '';
        display: block;
        height: 0;
        width: 0;
        border-left: 330px solid transparent;
        border-bottom: 550px solid #fff;
        position: absolute;
        right: 0;
        top: 0;
    }

    .carousel-slide {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-image: url(../img/slide-placeholder.jpg);
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        transition: 1.5s opacity ease;
        opacity: 0;
    }

    .carousel-slide-active {
        opacity: 1;
    }

    .no-js & {
        .carousel-slide:first-of-type {
            opacity: 1;
        }
    }

    @include mq($from: tablet, $until: desktop) {
        width: 85%;
    }

    @include mq($from: desktop, $until: wide) {
        width: (800/1024)*100%;
    }

    @include mq($from: mobileLandscape, $until: tablet) {
        position: relative;
        left: auto;
        top: auto;
        bottom: auto;
        height: 320px;
        width: 100%;
        overflow: hidden;

        &:after {
            display: none;
        }
    }

    @include mq($until: mobileLandscape) {
        position: relative;
        left: auto;
        top: auto;
        bottom: auto;
        height: 200px;
        width: 100%;
        overflow: hidden;

        &:after {
            display: none;
        }
    }
}

.carousel-slide-contents {
    position: absolute;
    right: 0;
    top: 40px;
    bottom: 80px;
    width: (850/1600)*100%;
    overflow: hidden;
    z-index: 2;
    color: #fff;
    background-color: $orange;

    .carousel-slide {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 40px 50px;
        background-color: $orange;
        left: 100%;
        transition: .5s left ease, .5s opacity ease;
        opacity: 0;
    }

    .carousel-slide-active {
        left: 0;
        opacity: 1;
        z-index: 2;
    }

    .carousel-slide-inactive {
        opacity: 0;
        z-index: -1;
    }

    .no-js & {
        .carousel-slide:first-of-type {
            left: 0;
            opacity: 1;
            z-index: 0;
        }
    }

    h2,
    p {
        max-width: 650px;
    }

    h2 {
        font-size: $font-size-h1;
    }

    .link-button {
        position: absolute;
        float: none;
        left: 50px;
        bottom: 40px;
    }

    @include mq($from: desktop, $until: wide) {
        width: (550/1024)*100%;
        bottom: 40px;

        h2 {
            font-size: 28px;
        }
    }

    @include mq($from: tablet, $until: desktop) {
        width: (400/768)*100%;
        top: 30px;
        bottom: 30px;

        .carousel-slide {
            padding: 30px;
        }

        h2 {
            font-size: 28px;
            margin-bottom: 10px;
        }

        p {
            line-height: (20/16)*100%;
            margin-bottom: 10px;
        }

        .link-button {
            bottom: 30px;
            left: 30px;
        }
    }

    @include mq($until: tablet) {
        position: relative;
        top: auto;
        right: auto;
        width: 100%;
        padding: 20px;
        top: auto;
        bottom: auto;

        .carousel-slide {
            padding: 20px;
        }

        h2 {
            font-size: 28px;
        }

        .link-button {
            bottom: 20px;
            left: 20px;
        }
    }

    @include mq($from: mobileLandscape, $until: tablet) {
        height: 320px;
    }

    @include mq($until: mobileLandscape) {
        height: 380px;
    }
}

.carousel-progress {
    position: absolute;
    right: 50px;
    bottom: 110px;
    z-index: 4;
    @include cf;

    > span {
        display: block;
        float: left;
        margin-right: 5px;
        text-indent: 115%;
        white-space: nowrap;
        overflow: hidden;
        width: 16px;
        height: 16px;
        background-color: transparent;
        background-image: url(../img/carousel-hex.png);
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 16px auto;
        position: relative;

        &.active {
            background-image: url(../img/carousel-hex-filled.png);
        }
    }

    .no-js & {
        display: none;
    }

    @include mq($from: desktop, $until: wide) {
        bottom: 80px;
    }

    @include mq($from: tablet, $until: desktop) {
        bottom: 60px;
        right: 30px;
    }

    @include mq($until: tablet) {
        bottom: 20px;
        right: 20px;
    }
}
