//----------------------------------------------------
//      article block styles
//----------------------------------------------------

.content {
    float: left;
    width: calc(100% - 490px);
    padding-top: 50px;
    padding-bottom: 50px;

    &.latest-block {
        width: 100%;
        float: none;
    }

    &.latest-block-slim {
        width: (710/1200)*100%;
        float: left;
    }

    .news & {
        img {
            //Redactor adds inline styles, so overwrite them to keep things responsive.
            width: auto !important;
            height: auto !important;
        }
    }

    iframe {
        max-width: 100%;
    }

    @include mq($from: desktop, $until: wide) {
        width: calc(100% - 408px);

        &.latest-block-slim {
            width: calc(100% - 408px);
        }
    }

    @include mq($from: tablet, $until: desktop) {
        width: calc(100% - 310px);

        &.latest-block-slim {
            width: calc(100% - 310px);
        }
    }

    @include mq($until: tablet) {
        width: 100%;
        padding-top: 40px;

        &.latest-block-slim {
            width: 100%;
        }
    }
}

//----------------------------------------------------
//      top level landing listing block
//----------------------------------------------------

.landing-listings {
    list-style: none;
}

.landing-listing {
    margin-bottom: 40px;
    padding-left: 0;

    > a {
        display: block;
        height: 100%;
        @include cf;

        &:hover,
        &:focus {
            outline: 0;

            h2 {
                color: $blue;
            }

            .link-button {
                background-color: $blue;

                &:after {
                    border-left-color: $blue;
                }
            }
        }

        &:focus {
            .landing-listing-image {
                transition: .5s transform ease;
                transform: translate(0,-20px);

                @include mq($until: tablet) {
                    transform: translate(-50%,-20px);
                }
            }
        }
    }

    &:before {
        background-color: transparent;
        display: table;
        position: static;
        top: auto;
        left: auto;
        width: 0;
        height: 0;
        transform: translate(0,0);
    }

    &:last-child {
        margin-bottom: 0;

        .landing-listing-detail {
            padding-bottom: 0;
            border: 0;
        }
    }

    &.no-link {
        @include cf;

        > a {
            &:hover {
                h2 {
                    color: $orange;
                }
            }
        }

        .landing-listing-detail {
            p:last-child {
                margin-bottom: 0;
            }
        }
    }

    &.no-image {
        .landing-listing-detail {
            width: 100%;
            float: none;
        }
    }

    .search-results & {
        &:before {
            content: '';
        }

        &:last-child {
            .landing-listing-detail {
                padding-bottom: 40px;
                border-bottom: 1px solid $grey;
                margin-bottom: 40px;
            }
        }
    }
}

.landing-listing-image {
    float: left;
    width: 194px;
    height: 168px;
    position: relative;
    margin-right: 30px;
    overflow: hidden;

    /*&:before,
    &:after {
        content: '';
        display: block;
        width: 0;
        border-top: 84px solid #fff;
        border-bottom: 84px solid #fff;
        position: absolute;
        top: 0;
        z-index: 4;
    }

    &:before {
        border-right: 45px solid transparent;
        left: -1px;
    }

    &:after {
        border-left: 45px solid transparent;
        right: 0;
    }*/

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(../img/hex-mask.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0 0;
    }

    img {
        vertical-align: bottom;
        max-width: 220px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        min-width: 100%;
    }

    @include mq($from: tablet, $until: desktop) {
        width: 96px;
        height: 84px;

        /*&:before,
        &:after {
            border-top-width: 42px;
            border-bottom-width: 42px;
        }

        &:before {
            border-right-width: 22px;
        }

        &:after {
            border-left-width: 22px;
        }*/

        .landing-listings-the-team & {
            float: none;
            clear: both;
            left: 50%;
            transform: translate(-50%,0);
            margin-bottom: 40px;
            width: 194px;
            height: 168px;
            margin-right: 0;

            /*&:before,
            &:after {
                border-top-width: 84px;
                border-bottom-width: 84px;
            }

            &:before {
                border-right-width: 45px;
            }

            &:after {
                border-left-width: 45px;
            }*/
        }
    }

    @include mq($until: tablet) {
        float: none;
        clear: both;
        left: 50%;
        transform: translate(-50%,0);
        margin-bottom: 40px;
        margin-right: 0;
    }
}

.landing-listing-detail {
    @include cf;
    float: left;
    width: calc(100% - 224px);
    padding-bottom: 40px;
    border-bottom: 1px solid $grey;
    min-height: 168px;

    h2 {
        font-size: 30px;
        line-height: 1;
        color: $orange;
        margin-bottom: 10px;
        transition: .5s color ease;
    }

    p {
        color: $black;
        margin-bottom: 20px;
    }

    .no-image & {
        width: 100%;
        min-height: 0;
        float: none;
    }

    @include mq($from: tablet, $until: desktop) {
        h2 {
            font-size: 24px;
        }
    }

    @include mq($from: tablet, $until: desktop) {
        width: calc(100% - 126px);

        h2 {
            font-size: 24px;
        }

        .landing-listings-the-team & {
            width: 100%;
            float: none;
            clear: both;
        }
    }

    @include mq($until: tablet) {
        float: none;
        clear: both;
        width: 100%;
        text-align: center;

        .link-button {
            left: 50%;
            transform: translate(-50%,0);
            backface-visibility: hidden;
        }

        h2 {
            font-size: 24px;
        }
    }
}

//----------------------------------------------------
//      gallery
//----------------------------------------------------

.gallery-block {
    padding-top: 30px;
    border-top: 1px solid $grey;
    @include cf;

    h2 {
        color: $blue;
        font-size: 30px;
        margin-bottom: 20px;
    }

    a {
        float: left;
        width: 33%;
        margin-right: .5%;
        margin-bottom: 1px;
        padding: 0;
        transition: .5s opacity ease;

        &:hover {
            opacity: .6;
        }

        &:before {
            display: none;
        }

        &:nth-of-type(3n + 3) {
            margin-right: 0;
        }

        img {
            vertical-align: bottom;
            max-width: 100%;
        }
    }

    @include mq($until: mobileLandscape) {
        a {
            width: 49.5%;
            margin-right: 1%;

            &:nth-of-type(3n + 3) {
                margin-right: 1%;
            }

            &:nth-of-type(2n + 2) {
                margin-right: 0;
            }

            &:last-of-type {
                margin-right: 0;
            }
        }
    }

    @include mq($from: mobileLandscape, $until: tablet) {
        a {
            width: 33%;
            margin-right: .5%;
        }
    }
}

//----------------------------------------------------
//      print and share page
//----------------------------------------------------

.content-share {
    margin-top: 50px;
    border-top: 1px solid $grey;
    padding-top: 20px;
    @include cf;
}

.social-print {
    padding: 0 0 0 58px;
    line-height: 32px;
    color: $black;
    float: left;
    position: relative;
    display: block;
    background-color: transparent;
    border: 0;

    &:after {
        content: '';
        display: block;
        background-image: url(../img/social-print.png);
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: auto 100%;
        height: 32px;
        width: 38px;
        position: absolute;
        left: 0;
        top: 0;
        transition: .5s transform ease;
    }

    &:hover {
        &:after {
            transform: translate(0,-3px);
        }
    }
}

.social-share {
    @include cf;
    float: right;

    > span {
        float: left;
        margin-right: 10px;
        line-height: 32px;
    }

    button,
    a {
        border: 0;
        padding: 0;
        margin-left: 10px;
        background-color: transparent;
        text-indent: 115%;
        overflow: hidden;
        white-space: nowrap;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 0 0;
        width: 38px;
        height: 32px;
        display: block;
        float: left;
        position: relative;
        transition: .5s transform ease;

        &:hover {
            transform: translate(0,-3px);
        }
    }
}

.social-email {
    background-image: url(../img/social-email.png);
}

@include mq($until: tablet) {
    .social-print,
    .social-share {
        font-size: 16px;

        .additional-title {
            display: none;
        }
    }

    .social-share {
        > span {
            margin-right: 0;
        }
    }
}

//----------------------------------------------------
//      disclaimer under some content
//----------------------------------------------------

.information-disclaimer {
    margin-top: 40px;
    padding-top: 20px;
    border-top: 1px solid $grey;
}

//----------------------------------------------------
//      search and pagination
//----------------------------------------------------

.search-info {
    padding-bottom: 20px;
    margin-bottom: 50px;
    border-bottom: 1px solid $grey;
}

.search-pagination {
    @include cf;

    li {
        float: left;
        padding: 0;
        margin-right: 10px;

        &:before {
            display: none;
        }
    }

    a,
    span {
        line-height: 40px;
        padding-left: 10px;
        padding-right: 10px;
        display: block;
        text-align: center;
    }

    a {
        background-color: $orange;
        color: #fff;
        transition: .5s background-color ease;

        &:hover {
            color: #fff;
            background-color: $blue;
        }
    }

    span {
        background-color: $grey;
        color: $black;
    }
}

//----------------------------------------------------
//      download grid
//----------------------------------------------------

.downloads-grid {
    //@include cf;
    display: flex;
    flex-wrap: wrap;

    > li {
        //float: left;
        //width: 32%;
        padding: 0;
        margin-right: 2%;
        flex: 0 1 32%;
        display: flex;
        align-items: stretch;

        &:nth-child(3n + 3) {
            margin-right: 0;
        }

        &:before {
            display: none;
        }
    }

    .latest-block-item {
        //min-height: 535px;
        border: 1px solid $blue;
        flex: 1 1 100%;
    }

    @include mq($from: mobileLandscape, $until: desktop) {
        > li {
            //width: 49%;
            flex: 0 1 49%;

            &:nth-child(3n + 3) {
                margin-right: 2%;
            }

            &:nth-child(2n+2) {
                margin-right: 0;
            }
        }
    }

    /*@include mq($from: tablet, $until: desktop) {
        .latest-block-item {
            min-height: 520px;
        }
    }

    @include mq($from: mobileLandscape, $until: tablet) {
        .latest-block-item {
            min-height: 626px;
        }
    }*/

    @include mq($until: mobileLandscape) {
        > li {
            //float: none;
            //width: 100%;
            margin-right: 0;
            flex: 1 1 100%;

            .latest-news-item {
                //min-height: 0;
                flex: 1 1 auto;
            }
        }
    }
}
