//----------------------------------------------------
//      video block
//----------------------------------------------------

.video-list {

}

.video-list-item {
    padding-bottom: 40px;
    margin-bottom: 40px;
    border-bottom: 1px solid $grey;

    &:last-child {
        border: 0;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    h2 {

    }

    blockquote {
        margin-bottom: 0;

        p {
            margin-bottom: 0;
        }
    }
}

.video-list-item-embed {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    overflow: hidden;
    margin-bottom: 30px;

    > iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}
