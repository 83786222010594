.x2020x__stars {
    // width: 5000px;
    display: grid;
    grid-template-columns: repeat(auto-fill, var(--vwThird));
    grid-template-rows: 1fr 1fr 1fr;
    grid-auto-flow: column;

    @include mq($from: tablet) {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        grid-template-rows: 1fr 1fr 1fr 1fr;
        grid-auto-flow: column;
    }

    &--star {
        width: 125px;
        height: 125px;
        justify-self: center;
        cursor: pointer;
        transform: translate3d(0,0,0);

        @include mq($from: mobileLandscape, $until: tablet) {
            width: 150px;
            height: 150px;
        }

        @include mq($until: tablet) {

            &:nth-child(5n+1) {
                grid-column: auto;
                grid-row: 1;
            }

            &:nth-child(5n+2) {
                grid-column: auto;
                grid-row: 3;
            }

            &:nth-child(5n+3) {
                grid-column: auto;
                grid-row: 2;
            }

            &:nth-child(5n+4) {
                grid-column: auto;
                grid-row: 1
            }

            &:nth-child(5n+5) {
                grid-column: auto;
                grid-row: 3;
            }
        }

        @include mq($from: tablet) {
            width: 175px;
            height: 175px;

            &:nth-child(4n+1) {
                grid-column: auto;
                grid-row: 1;
            }

            &:nth-child(4n+2) {
                grid-column: auto;
                grid-row: 3;
            }

            &:nth-child(4n+3) {
                grid-column: auto;
                grid-row: 2;
                margin-top: -15px;
            }

            &:nth-child(4n+4) {
                grid-column: auto;
                grid-row: 4;
                margin-top: -15px;
            }
        }

        svg {
            max-width: 100%;
            max-height: 100%;
        }
    }
}
