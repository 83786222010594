//----------------------------------------------------
//      jobs
//----------------------------------------------------

.job-listings {

}

.job-listing {
    padding-bottom: 40px;
    border-bottom: 1px solid $grey;
    margin-bottom: 40px;

    &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: 0;
    }
}

.job-detail-title {
    font-weight: bold;
}
