//----------------------------------------------------
//      sidebar blocks
//----------------------------------------------------

.content-aside {
    float: right;
    width: 348px;
    padding-top: 140px;
    padding-bottom: 50px;
    @include cf;
    position: relative;

    > .link-button {
        clear: both;
        margin-bottom: 10px;

        + .link-button {
            margin-top: 0;
        }
    }

    &.content-aside-short,
    &.content-aside-project,
    &.content-aside-contact,
    &.content-side-news {
        padding-top: 50px;
    }

    &.mobile-only {
        display: none;
    }

    @include mq($from: tablet, $until: desktop) {
        padding-top: 110px;
        width: 270px;
    }

    @include mq($until: tablet) {
        float: none;
        clear: both;
        width: 100%;
        padding-top: 40px;
        padding-bottom: 0;
        //margin-bottom: 20px;
        border-bottom: 1px solid $grey;

        .link-button-back {
            display: none;
        }

        &.mobile-only {
            display: block;
        }
    }
}


//----------------------------------------------------
//      sub nav
//----------------------------------------------------

.secondary-nav {
    padding-left: 20px;
    border-left: 2px solid $orange;
    margin-bottom: 40px;
    @include cf;

    h2 {
        color: $blue;
        font-size: 22px;
        padding-bottom: 10px;
        border-bottom: 1px solid $grey;
        font-weight: $font-weight-bold;
        margin-top: 0;
        margin-bottom: 15px;

        span {
            display: block;
            font-size: 17px;
            font-weight: normal;
        }
    }

    ul {
        list-style: none;

        ul {
            margin: 10px 0 20px 0;

            li {
            }

            a {
                color: transparentize($black, .2);
                font-size: $font-size - 2px;

                &:before,
                &:after {
                    display: none;
                }

                &:hover {
                    color: $orange;
                }
            }
        }
    }

    li {
        margin-bottom: 20px;

        &:last-of-type {
            margin-bottom: 0;
        }

        li {
            margin-bottom: 10px;
        }
    }

    .active {
        position: relative;

        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
        }

        &:before {
            top: 1px;
            width: 20px;
            height: 18px;
            background-color: #fff;
            left: -30px;
        }

        &:after {
            top: 7px;
            width: 12px;
            height: 6px;
            background-color: $orange;
            left: -27px;
        }

        > a {
            color: $orange;
            font-weight: $font-weight-bold;
            position: relative;

            &:before,
            &:after {
                content: '';
                display: block;
                position: absolute;
                left: -27px;
                height: 0;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
            }

            &:before {
                border-bottom: 4px solid $orange;
                top: 3px;
            }

            &:after {
                border-top: 4px solid $orange;
                top: 13px;
            }

            &:hover {
                color: $blue;
            }
        }

        .active {
            &:before {
                top: 3px;
                width: 10px;
                height: 11px;
                background-color: #fff;
                left: -26px;
            }

            &:after {
                top: 7px;
                width: 8px;
                height: 3px;
                background-color: $orange;
                left: -25px;
            }

            a {
                &:before,
                &:after {
                    border-left: 4px solid transparent;
                    border-right: 4px solid transparent;
                    left: -25px;
                }

                &:before {
                    top: 4px;
                    border-bottom: 3px solid $orange;
                }

                &:after {
                    top: 10px;
                    border-top: 3px solid $orange;
                }

                &:hover {
                    color: $blue;
                }
            }
        }
    }

    a {
        display: block;
        color: $black;
        font-size: 17px;
    }
}

//----------------------------------------------------
//      patient stories block
//----------------------------------------------------

.patient-stories-callout {
    background-color: $blue;
    color: #fff;
    clear: both;

    @include mq($until: tablet) {
        display: none;
    }
}

.patient-stories-callout-image-grid {
    @include cf;

    img {
        float: left;
        width: 33.333333%;
    }
}

.patient-stories-callout-content {
    padding: 25px 20px;
    @include cf;

    h2 {
        font-size: 30px;
        line-height: 1;
        margin-bottom: 20px;
    }

    p {
        margin-bottom: 20px;
    }

    .link-button {
        &:hover {
            background-color: $pink;

            &:after {
                border-left-color: $pink;
            }
        }
    }

    @include mq($from: tablet, $until: desktop) {
        h2 {
            font-size: 24px;
        }
    }
}

//----------------------------------------------------
//      patient story sidebar links
//----------------------------------------------------

.patient-stories-more-information {
    padding: 25px 20px;
    @include cf;
    background-color: $blue;
    color: #fff;
    margin-bottom: 40px;

    h2 {
        font-size: 30px;
        line-height: 1;
        margin-bottom: 20px;
        clear: both;
    }

    p {
        margin-bottom: 20px;
    }

    .link-button {
        &:hover {
            background-color: $pink;

            &:after {
                border-left-color: $pink;
            }
        }
    }

    @include mq($from: tablet, $until: desktop) {
        h2 {
            font-size: 24px;
        }
    }
}

//----------------------------------------------------
//      event details sidebar stuff
//----------------------------------------------------

.event-logo {
    text-align: left;
    margin-bottom: 30px;
    margin-top: 0;
    clear: both;

    img {
        vertical-align: bottom;
        max-width: 100%;
    }

    .content-aside-apply & {
        margin-top: 0;
    }
}

.event-information,
.contact-information {
    clear: both;

    > span {
        //border-top: 1px solid $grey;
        //padding-top: 30px;
        margin-bottom: 20px;
        color: $orange;
    }

    ul {
        list-style: none;
        margin-bottom: 30px;

        &:last-of-type {
            margin-bottom: 50px;
        }
    }

    li {
        padding-left: 68px;
        position: relative;
        line-height: 22px;
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: auto 38px;

        &:not(:last-child) {
            margin-bottom: 26px;
        }

        &.contact-phone {
            line-height: 38px;
        }

        &.contact-location {
            span {
                line-height: 38px;
            }
        }
    }

    span {
        font-weight: $font-weight-bold;
        display: block;
    }

    /*.content-aside-apply & {
        ul {
            margin-bottom: 0;
        }
    }*/

    @include mq($from: mobileLandscape, $until: desktop) {
        .content-aside-apply & {
            ul {
                + ul {
                    padding-top: 40px;
                }

                &:last-of-type {
                    margin-bottom: 40px;
                }
            }
        }
    }

    @include mq($until: mobileLandscape) {
        .content-aside-apply & {
            ul {
                + ul {
                    padding-top: 40px;
                }

                &:last-of-type {
                    margin-bottom: 40px;
                }
            }
        }
    }
}

.event-date {
    background-image: url(../img/event-date.png);
}

.event-location,
.contact-location {
    background-image: url(../img/event-location.png);
}

.event-target {
    background-image: url(../img/event-target.png);
}

.event-fee {
    background-image: url(../img/event-fee.png);
}

.event-distance {
    background-image: url(../img/event-distance.png);
}

.contact-phone {
    background-image: url(../img/icon-phone.png);
}

//----------------------------------------------------
//      research project blocks
//----------------------------------------------------

.project-information,
.tribute-information {
    @include cf;
    padding-left: 20px;
    padding-top: 190px;
    border-left: 2px solid $orange;
    margin-bottom: 40px;
    position: relative;

    &.no-image {
        padding-top: 0;
    }

    ul {
        list-style: none;
    }

    li {
        margin-bottom: 20px;

        &:last-of-type {
            margin-bottom: 0;
        }

        > span {
            display: block;
            font-weight: $font-weight-bold;
            margin-bottom: 5px;
        }
    }
}

.project-lead-researcher,
.tribute-image {
    position: absolute;
    top: 0;
    left: -42px;
    width: 182px;
    height: 156px;
    overflow: hidden;

    &:before,
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        border-top: 78px solid #fff;
        border-bottom: 78px solid #fff;
        height: 0;
        z-index: 2;
    }

    &:before {
        left: 0;
        border-right: 40px solid transparent;
    }

    &:after {
        right: 0;
        border-left: 40px solid transparent;
    }

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        min-width: 100%;
    }

    @include mq($until: tablet) {
        left: 20px;
    }
}
