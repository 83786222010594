//----------------------------------------------------
//      page header and image block
//----------------------------------------------------

.page-header {
    background-color: $orange;
    background-image: url(../img/mesh-orange.png);
    background-repeat: no-repeat;
    background-position: 0 80%;
    background-size: auto 180%;
    color: #fff;
    height: 245px;

    .contain {
        @include cf;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    h1,
    p {
        float: left;
        clear: left;
        width: 50%;
    }

    h1 {
        margin-top: 0;
        line-height: 1.1;
    }

    p {
        font-size: 23px;
        line-height: 28px;
        margin: 0;
    }

    h1 + p {
        margin-top: 15px;
    }

    &.page-header-wide {
        h1,
        p {
            width: (1000/1200)*100%;
        }
    }

    @include mq($from: desktop, $until: superwide) {
        h1,
        p {
            width: (540/1024)*100%;
        }

        h1 {
            font-size: 38px;
        }

        p {
            font-size: 20px;
            line-height: 24px;
        }
    }

    @include mq($from: tablet, $until: desktop) {
        //height: auto;

        h1,
        p {
            width: (540/1024)*100%;
        }

        h1 {
            font-size: $font-size-h3;
        }

        p {
            font-size: 20px;
            line-height: 24px;
        }
    }

    @include mq($until: tablet) {
        height: auto;

        h1,
        p {
            width: 100%;
            float: none;
            clear: both;
        }

        h1 {
            font-size: $font-size-h3;
        }

        p {
            font-size: 18px;
            line-height: 22px;
        }
    }
}

.page-header-image {
    position: absolute;
    right: 20px;
    top: -62px;
    width: 348px;
    height: 408px;
    overflow: hidden;
    background-image: url(../img/page-header-placeholder.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;

    &:before,
    &:after {
        content: '';
        display: block;
        position: absolute;
        left: -2px;
        width: 352px;
        height: 100%;
        background-repeat: no-repeat;
        background-size: 100% auto;
    }

    &:before {
        top: 0;
        background-image: url(../img/hexagon-overlay-orange.svg);
        background-position: 0 0;
    }

    &:after {
        bottom: 0;
        background-image: url(../img/hexagon-overlay.png);
        background-position: 0 100%;
    }

    @include mq($from: tablet, $until: desktop) {
        width: 270px;
        height: 330px;
        top: -7px;

        &:before,
        &:after {
            width: 274px;
        }
    }

    @include mq($until: tablet) {
        display: none;
    }
}

.page-header-short {
    height: auto;

    .contain {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

.page-header-research {
    h1,
    p {
        float: none;
        clear: both;
        width: 100%;
    }
}

.page-header-research-landing {
    h1,
    p {
        width: 50%;
    }

    @include mq($from: desktop, $until: superwide) {
        h1,
        p {
            width: auto;
        }
    }

    @include mq($from: tablet, $until: desktop) {
        h1,
        p {
            width: auto;
        }
    }

    @include mq($until: tablet) {
        h1,
        p {
            width: 100%;
        }
    }
}

//----------------------------------------------------
//      filtering
//----------------------------------------------------

.content-filters {
    float: right;
    @include cf;
    margin-top: 10px;

    > span {
        display: block;
        float: left;
        line-height: 37px;
        font-weight: $font-weight-bold;
        font-size: 22px;
        margin-right: 20px;
    }

    @include mq($from: tablet, $until: desktop) {
        margin-top: 0;
    }

    @include mq($until: desktop) {
        margin-top: 20px;
        float: left;
        clear: both;
    }
}

.content-filter-select {
    float: left;
    height: 37px;
    border: 0;
    padding: 0;
    background-color: #fff;
    position: relative;
    width: 220px;

    + .content-filter-select {
        margin-left: 15px;
    }

    &:before,
    &:after {
        content: '';
        display: block;
        position: absolute;
        pointer-events: none;
    }

    &:before {
        top: 0;
        right: 0;
        height: 37px;
        width: 45px;
        background: linear-gradient(to left, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 60%, rgba(255,255,255,0.6) 70%, rgba(255,255,255,0) 100%);
    }

    &:after {
        top: 14px;
        right: 10px;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 10px solid $orange;
    }

    button {
        display: block;
        border: 0;
        background-color: transparent;
        padding: 0 25px 0 15px;
        text-align: left;
        margin: 0;
        line-height: 37px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:first-letter {
            display: block;
            text-transform: uppercase;
        }
    }

    ul {
        position: absolute;
        z-index: 100;
        top: 100%;
        left: 0;
        width: 100%;
        height: 0;
        opacity: 0;
        overflow: hidden;
        background-color: #fff;
        border-width: 0 2px 2px 2px;
        transition: .5s height ease, .5s opacity ease;

        &.active {
            height: auto;
            opacity: 1;
        }
    }

    li {
        padding: 10px 15px;

        &:before {
            display: none;
        }
    }

    @include mq($until: tablet) {
        margin-left: 0;
        float: left;
        clear: left;
        margin-top: 10px;

        + .content-filter-select {
            margin-left: 0;
            float: left;
            clear: left;
            margin-top: 10px;
        }
    }
}
