//----------------------------------------------------
//      latest news / latest research block
//----------------------------------------------------

.latest-block {
    padding-top: 50px;
    padding-bottom: 50px;

    > .link-button {
        left: 50%;
        width: 240px;
        margin-left: -120px;
    }

    ul {
        list-style: none;
        margin-bottom: 40px;
        display: flex;
        flex-wrap: wrap;

        li {
            flex: 0 1 (270/1200)*100%;
            margin-right: (40/1200)*100%;
            padding-left: 0;
            background-color: $blue;
            display: flex;
            //flex-direction: column;
            align-items: stretch;

            &:before {
                display: none;
            }

            &:last-child,
            &:nth-of-type(4n + 4) {
                margin-right: 0;
            }
        }
    }

    &.latest-block-home {
        ul {
            li {
                background-color: #fff;
                display: block;
            }
        }
    }

    &.latest-block-slim {
        ul {
            li {
                flex: 0 1 (335/710)*100%;
                //width: (335/710)*100%;
                margin-right: (40/710)*100%;

                &:last-child,
                &:nth-of-type(2n + 2) {
                    margin-right: 0;
                }
            }
        }
    }

    .no-flexbox & {
        ul {
            @include cf;

            li {
                float: left;
                min-height: 640px;
            }
        }
    }

    @include mq($from: tablet, $until: desktop) {
        ul {
            li {
                flex: 0 1 32%;
                //width: 32%;
                margin-right: 2%;
                margin-bottom: 40px;

                &:nth-of-type(4n + 4) {
                    margin-right: 2%;
                }

                &:nth-of-type(3n + 3) {
                    margin-right: 0;
                }
            }
        }

        &.latest-block-home {
            ul {
                li {
                    &:nth-of-type(3) {
                        margin-right: 0;
                    }

                    &:last-of-type {
                        display: none;
                    }
                }
            }
        }
    }

    @include mq($from: mobileLandscape, $until: tablet) {
        ul {
            li {
                flex: 0 1 48%;
                //width: 48%;
                margin-right: 4%;
                margin-bottom: 40px;

                &:nth-child(even) {
                    margin-right: 0;
                }
            }
        }

        &.latest-block-slim {
            ul {
                li {
                    flex: 0 1 48%;
                    //width: 48%;
                    margin-right: 4%;

                    &:last-child,
                    &:nth-of-type(2n + 2) {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    @include mq($until: mobileLandscape) {
        ul {
            li {
                float: none;
                flex: 1 1 100%;
                //width: 100%;
                margin-right: 0;
                margin-bottom: 40px;
            }
        }

        &.latest-block-slim {
            ul {
                li {
                    float: none;
                    flex: 1 1 100%;
                    //width: 100%;
                    margin-right: 0;
                    margin-bottom: 40px;

                    &:last-child,
                    &:nth-of-type(2n + 2) {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

.latest-block-item {
    display: block;
    color: #fff;
    background-color: $blue;
    padding-bottom: 80px;
    position: relative;
    //height: 100%;
    //min-height: 420px;
    flex: 1 1 100%;

    /*.news &,
    .patient-stories & {
        min-height: 500px;
    }

    .research & {
        min-height: 530px;
    }*/

    img {
        vertical-align: bottom;
        width: 100%;

        + h2 {
            margin-top: 20px;
        }
    }

    time {
        display: block;
        left: 50%;
        position: relative;
        transform: translate(-50%,0);
        clear: both;
        background-color: $orange;
        color: #fff;
        width: 44px;
        height: 74px;
        line-height: 1;
        padding-top: 10px;
        font-size: 15px;
        text-transform: uppercase;
        text-align: center;
        margin-top: -37px;
        margin-bottom: 15px;
        transition: .5s background-color ease;

        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            width: 0;
            height: 0;
            border-top: 37px solid transparent;
            border-bottom: 37px solid transparent;
            transition: .5s border-color ease;
        }

        &:before {
            left: -20px;
            border-right: 20px solid $orange;
        }

        &:after {
            right: -20px;
            border-left: 20px solid $orange;
        }

        span {
            display: block;
            font-size: 20px;
            font-weight: bold;
        }
    }

    p,
    h2,
    h3 {
        padding-left: 20px;
        padding-right: 20px;
        color: #fff;
    }

    p {
        margin-bottom: 20px;
        font-size: 16px;
        line-height: (23/16)*100%;
    }

    h2 {
        color: #fff;
        margin-bottom: 10px;
        font-size: 22px;
        line-height: (25/22)*100%;
        transition: .5s color ease;
    }

    h3 {
        color: #fff;
        margin-bottom: 10px;
        margin-top: 0;
        font-size: 18px;
        font-weight: bold;
        line-height: 1;
    }

    .link-button {
        position: absolute;
        bottom: 20px;
        left: 20px;
    }

    &.latest-block-item-home {
        background-color: #fff;
        padding-bottom: 0;
        //min-height: 0;

        p,
        h2 {
            padding-left: 0;
            padding-right: 0;
        }

        p {
            color: $black;
        }

        h2 {
            color: $orange;
            margin-bottom: 10px;
        }

        &:hover {
            p {
                color: $black;
            }

            h2 {
                color: $blue;
            }

            time {
                background-color: $blue;

                &:before {
                    border-right-color: $blue;
                }

                &:after {
                    border-left-color: $blue;
                }
            }
        }
    }

    &:hover {
        h2,
        p {
            color: #fff;
        }

        .link-button {
            background-color: #fff;
            color: $orange;

            &:after {
                border-left-color: #fff;
            }
        }
    }

    .no-flexbox & {
        padding-bottom: 20px;
        @include cf;

        .link-button {
            margin-left: 20px;
        }
    }

    @include mq($from: tablet, $until: desktop) {
        h2 {
            font-size: 18px;
            line-height: 21px;
        }
    }

    @include mq($until: tablet) {
        //min-height: 0;
        flex: 1 1 auto;

        .news &,
        .patient-stories &,
        .research & {
            min-height: 0;
        }
    }
}
