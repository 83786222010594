@charset "UTF-8";
@import url("https://use.typekit.net/rmj1tpw.css");
.hidden {
  display: none;
  visibility: hidden;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.invisible {
  visibility: hidden;
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video,
hr,
main {
  margin: 0;
  padding: 0;
  border: none;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

blockquote,
q {
  quotes: none;
}

q {
  quotes: '\201C' '\201D' '\2018' '\2019';
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

dfn {
  font-style: italic;
}

hr {
  display: block;
  box-sizing: content-box;
  height: 0;
  border-top: 1px solid #ccc;
}

pre,
code,
kbd,
samp {
  font-family: monospace, sans-serif;
  font-size: 1em;
}

pre {
  white-space: pre-wrap;
}

small {
  font-size: 80%;
}

b,
strong,
th {
  font-weight: bold;
}

sup,
sub {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0;
  padding: 10px;
}

legend {
  border: 0;
  padding: 0;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
}

button,
input {
  line-height: normal;
}

button,
select {
  text-transform: none;
}

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

input[type='search'] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td {
  vertical-align: top;
}

img {
  -ms-interpolation-mode: bicubic;
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

/*!
 * baguetteBox.js
 * @author  feimosi
 * @version 1.8.0
 * @url https://github.com/feimosi/baguetteBox.js
 */
#baguetteBox-overlay {
  display: none;
  opacity: 0;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  background-color: #222;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-transition: opacity .5s ease;
  transition: opacity .5s ease;
}

#baguetteBox-overlay.visible {
  opacity: 1;
}

#baguetteBox-overlay .full-image {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
}

#baguetteBox-overlay .full-image figure {
  display: inline;
  margin: 0;
  height: 100%;
}

#baguetteBox-overlay .full-image img {
  display: inline-block;
  width: auto;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  vertical-align: middle;
  -moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}

#baguetteBox-overlay .full-image figcaption {
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  line-height: 1.8;
  white-space: normal;
  color: #ccc;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.6);
  font-family: sans-serif;
}

#baguetteBox-overlay .full-image:before {
  content: "";
  display: inline-block;
  height: 50%;
  width: 1px;
  margin-right: -1px;
}

#baguetteBox-slider {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  white-space: nowrap;
  -webkit-transition: left .4s ease, -webkit-transform .4s ease;
  transition: left .4s ease, -webkit-transform .4s ease;
  transition: left .4s ease, transform .4s ease;
  transition: left .4s ease, transform .4s ease, -webkit-transform .4s ease, -moz-transform .4s ease;
}

#baguetteBox-slider.bounce-from-right {
  -webkit-animation: bounceFromRight .4s ease-out;
  animation: bounceFromRight .4s ease-out;
}

#baguetteBox-slider.bounce-from-left {
  -webkit-animation: bounceFromLeft .4s ease-out;
  animation: bounceFromLeft .4s ease-out;
}

@-webkit-keyframes bounceFromRight {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -30px;
  }
  100% {
    margin-left: 0;
  }
}

@keyframes bounceFromRight {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -30px;
  }
  100% {
    margin-left: 0;
  }
}

@-webkit-keyframes bounceFromLeft {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 30px;
  }
  100% {
    margin-left: 0;
  }
}

@keyframes bounceFromLeft {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 30px;
  }
  100% {
    margin-left: 0;
  }
}

.baguetteBox-button#next-button, .baguetteBox-button#previous-button {
  top: 50%;
  top: calc(50% - 30px);
  width: 44px;
  height: 60px;
}

.baguetteBox-button {
  position: absolute;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin: 0;
  border: 0;
  -moz-border-radius: 15%;
  border-radius: 15%;
  background-color: #323232;
  background-color: rgba(50, 50, 50, 0.5);
  color: #ddd;
  font: 1.6em sans-serif;
  -webkit-transition: background-color .4s ease;
  transition: background-color .4s ease;
}

.baguetteBox-button:focus, .baguetteBox-button:hover {
  background-color: rgba(50, 50, 50, 0.9);
}

.baguetteBox-button#next-button {
  right: 2%;
}

.baguetteBox-button#previous-button {
  left: 2%;
}

.baguetteBox-button#close-button {
  top: 20px;
  right: 2%;
  right: calc(2% + 6px);
  width: 30px;
  height: 30px;
}

.baguetteBox-button svg {
  position: absolute;
  left: 0;
  top: 0;
}

/*
    Preloader
    Borrowed from http://tobiasahlin.com/spinkit/
*/
.baguetteBox-spinner {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
}

.baguetteBox-double-bounce1,
.baguetteBox-double-bounce2 {
  width: 100%;
  height: 100%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: #fff;
  opacity: .6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: bounce 2s infinite ease-in-out;
  animation: bounce 2s infinite ease-in-out;
}

.baguetteBox-double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes bounce {
  0%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes bounce {
  0%, 100% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    transform: scale(1);
  }
}

.trigger-size {
  width: 0;
  height: 0;
  display: none;
}

@media (max-width: 29.99em) {
  .trigger-size.mobile {
    display: block;
  }
}

@media (min-width: 30em) and (max-width: 47.99em) {
  .trigger-size.mobile-large {
    display: block;
  }
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .trigger-size.tablet {
    display: block;
  }
}

@media (min-width: 64em) and (max-width: 79.99em) {
  .trigger-size.desktop {
    display: block;
  }
}

@media (min-width: 80em) {
  .trigger-size.desktop-wide {
    display: block;
  }
}

body {
  font-family: "proxima-nova", "Proxima Nova", "Helvetica Neue", helvetica, arial, verdana, sans-serif;
  font-size: 18px;
  color: #0A0A0A;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.site-header,
.site-nav,
.site-footer {
  flex: 0 0 auto;
}

main {
  flex: 1 1 auto;
}

p {
  margin-bottom: 30px;
  line-height: 144.444%;
}

p.content-intro {
  font-size: 22px;
  line-height: 125%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "proxima-nova", "Proxima Nova", "Helvetica Neue", helvetica, arial, verdana, sans-serif;
  font-weight: bold;
  line-height: 1.4;
  margin-top: 20px;
  margin-bottom: 0;
}

h1 {
  font-size: 44px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 18px;
}

h2 {
  line-height: 114.894%;
  margin-top: 0;
  margin-bottom: 20px;
}

article ul,
article ol,
article p,
article h2,
article h3,
article h4,
article h5,
article h6,
article table,
article blockquote {
  clear: both;
}

article em {
  font-style: italic;
}

article hr {
  border: 1px solid #EBEBEB;
  border-width: 1px 0 0 0;
  display: block;
  clear: both;
  margin-bottom: 30px;
}

article blockquote {
  margin-bottom: 30px;
  font-size: 26px;
  line-height: 115.385%;
  margin-bottom: 40px;
  position: relative;
  padding-top: 40px;
  color: #3474BD;
}

article blockquote:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 30px;
  background-image: url(../img/quote-mark.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
}

article blockquote p {
  margin: 0;
  line-height: 123.077%;
}

article blockquote p + cite {
  margin-top: 10px;
  display: block;
}

@media (min-width: 48em) and (max-width: 63.99em) {
  article blockquote {
    font-size: 24px;
    line-height: 116.667%;
  }
}

@media (max-width: 47.99em) {
  article blockquote {
    font-size: 22px;
    line-height: 118.182%;
  }
}

article cite {
  font-size: 18px;
  color: #0A0A0A;
  display: block;
  line-height: 144.444%;
}

article ul {
  list-style: none;
  margin-bottom: 30px;
}

article ul li {
  margin-bottom: 25px;
  position: relative;
  padding: 0 0 0 20px;
  line-height: 144.444%;
}

article ul li:before {
  content: '';
  display: block;
  position: absolute;
  top: 8px;
  left: 0;
  background-color: #EE5F1C;
  width: 8px;
  height: 8px;
}

article ul ul {
  margin: 20px 0;
}

article ul ul li {
  margin-bottom: 15px;
}

article ul h2,
article ul h3 {
  margin: 0 0 10px;
}

article ol {
  list-style: none;
  counter-reset: listblock;
  margin-bottom: 30px;
}

article ol li {
  padding: 0 0 0 24px;
  position: relative;
  margin-bottom: 25px;
  line-height: 144.444%;
}

article ol li:before {
  counter-increment: listblock;
  content: counter(listblock);
  display: block;
  position: absolute;
  top: 2px;
  left: 0;
  color: #EE5F1C;
  font-size: 18px;
  line-height: 1;
  font-weight: bold;
}

article > time {
  display: block;
  font-weight: bold;
  margin-bottom: 20px;
}

article h2,
article h3,
article h4,
article h5,
article h6 {
  color: #3474BD;
}

article table {
  border-collapse: collapse;
  border: 0;
  width: 100%;
  margin: 0 0 30px 0;
}

article table th,
article table td {
  text-align: left;
  padding: 15px;
}

article table th {
  font-weight: bold;
  border-bottom: 3px solid #0A0A0A;
}

article table td {
  border-bottom: 1px solid #EBEBEB;
}

article img {
  vertical-align: bottom;
  max-width: 100%;
}

@media (max-width: 29.99em) {
  article table th,
  article table td {
    padding: 4px;
    font-size: 16px;
  }
}

h3 {
  font-size: 22px;
  line-height: 27px;
  margin-bottom: 30px;
}

h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
p a,
ul a,
ol a {
  color: #EE5F1C;
  transition: .5s color ease;
}

h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
p a:hover,
ul a:hover,
ol a:hover {
  color: #3474BD;
}

a {
  text-decoration: none;
}

a:focus,
button:focus,
input:focus,
select:focus,
textarea:focus,
label:focus {
  outline: 3px inset #E5007A;
}

.no-focus a, .no-focus
button, .no-focus
input, .no-focus
select, .no-focus
textarea, .no-focus
label {
  outline: 0;
}

.contain,
.contain-large {
  position: relative;
  margin: 0 auto;
}

.contain:before, .contain:after,
.contain-large:before,
.contain-large:after {
  content: ' ';
  display: table;
}

.contain:after,
.contain-large:after {
  clear: both;
}

.contain {
  max-width: 1240px;
  padding-left: 20px;
  padding-right: 20px;
}

.contain-large {
  max-width: 1600px;
}

.old-browser {
  padding: 20px;
  background-color: #3474BD;
  color: #fff;
  font-size: 16px;
  line-height: 19px;
  position: fixed;
  top: 200px;
  left: 50%;
  margin-left: -180px;
  width: 560px;
  display: none;
  text-align: center;
}

.old-browser p {
  margin: 0 0 15px 0;
}

.old-browser p:last-child {
  margin: 0;
}

.old-browser small {
  display: block;
  margin-top: 20px;
  line-height: 12px;
  font-size: 12px;
}

.old-browser.visible {
  display: block;
}

.old-browser-accept {
  border: 0;
  padding: 10px 20px;
  background-color: #EE5F1C;
  color: #fff;
  text-align: center;
  font-size: 16px;
  line-height: 16px;
  display: inline-block;
}

.old-browser-accept:hover, .old-browser-accept:focus {
  background-color: #E5007A;
  color: #fff;
}

main:before, main:after {
  content: ' ';
  display: table;
}

main:after {
  clear: both;
}

.news main {
  background-image: url(../img/hex-bottom-right.png);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: auto 214px;
}

@media (max-width: 63.99em) {
  .news main {
    background-image: none;
  }
}

.site-header {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #fff;
  background-image: url(../img/mesh-grey-3.png);
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: auto 140%;
  position: relative;
  z-index: 300;
}

.site-header:before, .site-header:after {
  content: ' ';
  display: table;
}

.site-header:after {
  clear: both;
}

@media (max-width: 47.99em) {
  .site-header {
    background-image: url(../img/mesh-grey.png);
  }
}

.site-logo {
  display: block;
  text-indent: 110%;
  white-space: nowrap;
  overflow: hidden;
  width: 201px;
  height: 110px;
  background-color: transparent;
  background-image: url("../img/logo-strapline.png");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-size: contain;
  float: left;
  transition: .5s opacity ease;
}

.site-logo:hover {
  opacity: .5;
}

@media (max-width: 47.99em) {
  .site-logo {
    width: 128px;
    height: 70px;
  }
}

.site-search {
  float: right;
  position: relative;
  width: 29%;
  margin-top: 30px;
}

.site-search fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}

.site-search input {
  border: 2px solid #EBEBEB;
  height: 50px;
  line-height: 50px;
  background-color: #fff;
  font-size: 19px;
  font-family: "proxima-nova", "Proxima Nova", "Helvetica Neue", helvetica, arial, verdana, sans-serif;
  color: #0A0A0A;
  width: 100%;
  padding-right: 50px;
  padding-left: 15px;
  transition: .5s border ease;
}

.site-search input:-moz-placeholder {
  color: #EBEBEB;
}

.site-search input::-moz-placeholder {
  color: #EBEBEB;
}

.site-search input:-ms-input-placeholder {
  color: #EBEBEB;
}

.site-search input::-webkit-input-placeholder {
  color: #EBEBEB;
}

.site-search input:focus {
  border-color: #3474BD;
}

.site-search input:focus:-moz-placeholder {
  color: #fff;
}

.site-search input:focus::-moz-placeholder {
  color: #fff;
}

.site-search input:focus:-ms-input-placeholder {
  color: #fff;
}

.site-search input:focus::-webkit-input-placeholder {
  color: #fff;
}

@media (max-width: 47.99em) {
  .site-search {
    margin-top: 10px;
    width: 50%;
    max-width: 348px;
  }
}

.site-search-go {
  border: 0;
  background-color: transparent;
  padding: 0;
  margin: 0;
  background-image: url(../img/search.png);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 18px 18px;
  width: 46px;
  height: 46px;
  position: absolute;
  top: 2px;
  right: 2px;
  text-indent: 115%;
  overflow: hidden;
  white-space: nowrap;
  transition: .5s opacity ease;
}

.site-search-go:hover {
  opacity: .7;
}

.mobile-nav-toggle {
  border: 0;
  width: calc(100% - 110px);
  padding-left: 50px;
  padding-right: 20px;
  padding-top: 0;
  padding-bottom: 0;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  display: none;
  line-height: 50px;
  background-color: #3474BD;
  background-image: url(../img/mobile-menu.png);
  background-repeat: no-repeat;
  background-position: 20px 19px;
  background-size: 12px 12px;
  text-align: left;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  position: relative;
  z-index: 300;
}

.mobile-nav-toggle.active {
  background-image: url(../img/mobile-menu-close.png);
  background-color: #295b95;
}

@media (max-width: 47.99em) {
  .mobile-nav-toggle {
    display: block;
    float: left;
  }
}

.site-nav {
  clear: both;
  background-color: #3474BD;
  position: relative;
  z-index: 100;
  top: 0;
  width: 100%;
}

.site-nav:before, .site-nav:after {
  content: ' ';
  display: table;
}

.site-nav:after {
  clear: both;
}

.site-nav.stuck {
  position: fixed;
}

@media (max-width: 47.99em) {
  .site-nav {
    background-color: transparent;
    position: relative;
  }
  .site-nav .contain {
    padding-left: 0;
    padding-right: 0;
  }
}

.site-nav-fake {
  height: 50px;
  display: none;
  width: 100%;
  position: relative;
}

.site-nav-fake.show {
  display: block;
}

.site-nav-links {
  list-style: none;
  float: left;
}

.site-nav-links:before, .site-nav-links:after {
  content: ' ';
  display: table;
}

.site-nav-links:after {
  clear: both;
}

.site-nav-links > li {
  float: left;
  margin-right: 40px;
  position: relative;
}

.site-nav-links > li a {
  color: #fff;
  font-size: 19px;
  text-align: center;
  line-height: 50px;
  display: block;
}

.site-nav-links > li a:hover {
  text-decoration: underline;
}

.site-nav-links > li.active a {
  font-weight: bold;
}

.site-nav-links > li.active:after {
  content: '';
  display: block;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  border-top: 14px solid #3474BD;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  position: absolute;
  z-index: 4;
}

.site-nav-links > li > ul {
  z-index: -1;
  opacity: 0;
  position: absolute;
  transition: .5s opacity linear;
  top: 100%;
  left: -20px;
  width: 260px;
  background-color: #3474BD;
  list-style: none;
  display: none;
}

.site-nav-links > li > ul > li a {
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
  line-height: 19px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.site-nav-links > li:hover > ul {
  opacity: 1;
  z-index: 100;
}

@media (min-width: 64em) and (max-width: 79.99em) {
  .site-nav-links > li {
    margin-right: 30px;
  }
  .site-nav-links > li a {
    font-size: 17px;
  }
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .site-nav-links > li {
    margin-right: 15px;
  }
  .site-nav-links > li a {
    font-size: 15px;
  }
}

@media (max-width: 47.99em) {
  .site-nav-links {
    float: none;
    position: absolute;
    top: 52px;
    left: 0;
    width: 100%;
    z-index: 200;
    transform: translate(0, -200%);
    transition: .5s transform ease;
  }
  .site-nav-links.active {
    transform: translate(0, 0);
  }
  .site-nav-links > li {
    float: none;
    margin-right: 0;
    background-color: #3474BD;
    /*&:last-child {
                width: calc(100% - 40px);
                position: relative;

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    right: -40px;
                    top: 0;
                    height: 0;
                    width: 0;
                    border-left: 40px solid $blue;
                    border-bottom: 50px solid transparent;
                }
            }*/
  }
  .site-nav-links > li.active {
    background-color: #EE5F1C;
  }
  .site-nav-links > li.active:after {
    display: none;
  }
  .site-nav-links > li.active a {
    font-weight: normal;
  }
  .site-nav-links > li:not(:last-child) {
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  }
  .site-nav-links > li a {
    font-size: 18px;
    text-align: left;
    line-height: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .site-nav-links > li a.has-subnav {
    position: relative;
  }
  .site-nav-links > li a.has-subnav:before, .site-nav-links > li a.has-subnav:after {
    content: '';
    display: block;
    top: 50%;
    transform: translate(0, -50%);
    background-color: #fff;
    position: absolute;
  }
  .site-nav-links > li a.has-subnav:before {
    width: 12px;
    height: 2px;
    right: 20px;
  }
  .site-nav-links > li a.has-subnav:after {
    width: 2px;
    height: 12px;
    right: 25px;
    transition: .5s height ease;
  }
  .site-nav-links > li a.subnav-active:after {
    height: 2px;
  }
  .site-nav-links > li a.subnav-active + ul {
    display: block;
  }
  .site-nav-links > li > ul {
    position: relative;
    top: auto;
    left: auto;
    z-index: 1;
    opacity: 1;
    display: none;
    background-color: #295b95;
    width: 100%;
    transition: .5s visibility ease;
    visibility: hidden;
  }
  .site-nav-links > li > ul[aria-hidden="true"] {
    visibility: hidden;
  }
  .site-nav-links > li > ul[aria-hidden="false"] {
    visibility: visible;
  }
  .site-nav-links > li > ul .active {
    background-color: #1e436d;
  }
}

.site-nav-donate {
  background-color: #E5007A;
  color: #fff;
  padding-left: 40px;
  padding-right: 40px;
  text-transform: uppercase;
  line-height: 50px;
  float: right;
  font-size: 19px;
  font-weight: bold;
  transition: .5s background-color ease;
}

.site-nav-donate:hover {
  background-color: #cc006c;
}

@media (min-width: 64em) and (max-width: 79.99em) {
  .site-nav-donate {
    padding-left: 30px;
    padding-right: 30px;
    font-size: 17px;
  }
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .site-nav-donate {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 15px;
  }
}

@media (max-width: 47.99em) {
  .site-nav-donate {
    width: 110px;
    font-size: 16px;
    text-align: center;
    padding: 0;
    border-bottom: 2px solid #fff;
    position: relative;
    z-index: 300;
  }
}

.site-footer {
  padding-top: 40px;
  padding-bottom: 50px;
  background-color: #fff;
  position: relative;
}

.site-footer:before {
  content: '';
  display: block;
  width: 100%;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
  background: #3474bd;
  background: linear-gradient(60deg, #3474bd 0%, #3474bd 60%, #ee5f1c 60%, #ee5f1c 100%);
}

.site-footer .contain:before, .site-footer .contain:after {
  content: ' ';
  display: table;
}

.site-footer .contain:after {
  clear: both;
}

.logo-strapline {
  display: block;
  text-indent: 110%;
  white-space: nowrap;
  overflow: hidden;
  width: 188px;
  height: 100px;
  background-color: transparent;
  background-image: url("../img/logo-strapline.png");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  float: right;
}

@media (max-width: 63.99em) {
  .logo-strapline {
    float: none;
    clear: both;
    left: 50%;
    position: relative;
    transform: translate(-50%, 0);
    margin-bottom: 40px;
    width: 150px;
    height: 80px;
  }
}

.site-copyright {
  float: left;
  margin-bottom: 30px;
  font-size: 17px;
  line-height: 26px;
}

.site-copyright span {
  font-weight: bold;
  display: block;
}

@media (min-width: 64em) and (max-width: 79.99em) {
  .site-copyright {
    font-size: 15px;
    line-height: 24px;
  }
}

@media (max-width: 63.99em) {
  .site-copyright {
    font-size: 14px;
    line-height: 23px;
    float: none;
    text-align: center;
    margin-bottom: 20px;
  }
}

.site-footer-nav {
  float: left;
  clear: left;
  margin-bottom: 35px;
}

.site-footer-nav ul {
  list-style: none;
}

.site-footer-nav ul:before, .site-footer-nav ul:after {
  content: ' ';
  display: table;
}

.site-footer-nav ul:after {
  clear: both;
}

.site-footer-nav li {
  float: left;
  position: relative;
}

.site-footer-nav li:not(:last-child) {
  margin-right: 10px;
  padding-right: 15px;
}

.site-footer-nav li:not(:last-child):after {
  display: block;
  content: '';
  width: 5px;
  height: 5px;
  background-color: #EE5F1C;
  position: absolute;
  right: 0;
  top: 9px;
}

.site-footer-nav a {
  display: block;
  color: #0A0A0A;
  transition: .5s color ease;
  font-size: 17px;
}

.site-footer-nav a:hover {
  color: #EE5F1C;
}

@media (min-width: 64em) and (max-width: 79.99em) {
  .site-footer-nav a {
    font-size: 15px;
  }
}

@media (max-width: 63.99em) {
  .site-footer-nav {
    float: none;
    clear: both;
    margin-bottom: 20px;
  }
  .site-footer-nav ul {
    text-align: center;
  }
  .site-footer-nav li {
    float: none;
    display: inline-block;
  }
  .site-footer-nav a {
    font-size: 14px;
  }
}

.site-social-links {
  float: right;
  clear: right;
  line-height: 32px;
  margin-top: 52px;
}

.site-social-links:before, .site-social-links:after {
  content: ' ';
  display: table;
}

.site-social-links:after {
  clear: both;
}

.site-social-links a {
  display: block;
  float: left;
  margin-left: 10px;
  text-indent: 115%;
  overflow: hidden;
  white-space: nowrap;
  width: 38px;
  height: 32px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  transition: .5s transform ease;
}

.site-social-links a:hover {
  transform: translate(0, -3px);
}

.site-social-links a:first-child {
  margin-left: 0;
}

@media (max-width: 63.99em) {
  .site-social-links {
    position: relative;
    float: none;
    clear: both;
    text-align: center;
    font-size: 14px;
    height: 28px;
    margin-bottom: 40px;
    margin-top: 0;
    left: 50%;
    width: 182px;
    transform: translate(-50%, 0);
    padding-top: 0;
  }
}

.social-facebook {
  background-image: url(../img/social-facebook.png);
}

.social-twitter {
  background-image: url(../img/social-twitter.png);
}

.social-youtube {
  background-image: url(../img/social-youtube.png);
}

.social-flickr {
  background-image: url(../img/social-flickr.png);
}

.social-instagram {
  background-image: url(../img/social-instagram.png);
}

.site-supporters {
  float: left;
  clear: left;
}

.site-supporters:before, .site-supporters:after {
  content: ' ';
  display: table;
}

.site-supporters:after {
  clear: both;
}

.site-supporters span {
  text-indent: 115%;
  overflow: hidden;
  white-space: nowrap;
  height: 55px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  display: block;
  float: left;
}

.site-supporters span:not(:last-child) {
  margin-right: 50px;
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .site-supporters {
    float: none;
    clear: both;
    text-align: center;
  }
  .site-supporters span {
    float: none;
    display: inline-block;
  }
}

@media (max-width: 63.99em) {
  .site-supporters {
    float: none;
    clear: both;
    text-align: center;
  }
  .site-supporters span {
    height: 36px;
    display: inline-block;
    float: none;
  }
  .site-supporters span:not(:last-child) {
    margin-right: 20px;
  }
}

.supporter-fr {
  width: 126px;
  background-image: url(../img/logo-fr.png);
}

@media (max-width: 63.99em) {
  .supporter-fr {
    width: 80px;
  }
}

.supporter-amrc {
  width: 112px;
  background-image: url(../img/logo-amrc.png);
}

@media (max-width: 63.99em) {
  .supporter-amrc {
    width: 66px;
  }
}

.supporter-tis {
  width: 112px;
  background-image: url(../img/logo-tis.png);
}

@media (max-width: 63.99em) {
  .supporter-tis {
    width: 70px;
  }
}

.no-script-warning {
  background-color: #3474BD;
  color: #fff;
  text-align: center;
  padding: 20px;
  margin: 0;
  line-height: 1;
}

.page-header {
  background-color: #EE5F1C;
  background-image: url(../img/mesh-orange.png);
  background-repeat: no-repeat;
  background-position: 0 80%;
  background-size: auto 180%;
  color: #fff;
  height: 245px;
}

.page-header .contain {
  padding-top: 50px;
  padding-bottom: 50px;
}

.page-header .contain:before, .page-header .contain:after {
  content: ' ';
  display: table;
}

.page-header .contain:after {
  clear: both;
}

.page-header h1,
.page-header p {
  float: left;
  clear: left;
  width: 50%;
}

.page-header h1 {
  margin-top: 0;
  line-height: 1.1;
}

.page-header p {
  font-size: 23px;
  line-height: 28px;
  margin: 0;
}

.page-header h1 + p {
  margin-top: 15px;
}

.page-header.page-header-wide h1,
.page-header.page-header-wide p {
  width: 83.333%;
}

@media (min-width: 64em) and (max-width: 89.99em) {
  .page-header h1,
  .page-header p {
    width: 52.734%;
  }
  .page-header h1 {
    font-size: 38px;
  }
  .page-header p {
    font-size: 20px;
    line-height: 24px;
  }
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .page-header h1,
  .page-header p {
    width: 52.734%;
  }
  .page-header h1 {
    font-size: 24px;
  }
  .page-header p {
    font-size: 20px;
    line-height: 24px;
  }
}

@media (max-width: 47.99em) {
  .page-header {
    height: auto;
  }
  .page-header h1,
  .page-header p {
    width: 100%;
    float: none;
    clear: both;
  }
  .page-header h1 {
    font-size: 24px;
  }
  .page-header p {
    font-size: 18px;
    line-height: 22px;
  }
}

.page-header-image {
  position: absolute;
  right: 20px;
  top: -62px;
  width: 348px;
  height: 408px;
  overflow: hidden;
  background-image: url(../img/page-header-placeholder.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.page-header-image:before, .page-header-image:after {
  content: '';
  display: block;
  position: absolute;
  left: -2px;
  width: 352px;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% auto;
}

.page-header-image:before {
  top: 0;
  background-image: url(../img/hexagon-overlay-orange.svg);
  background-position: 0 0;
}

.page-header-image:after {
  bottom: 0;
  background-image: url(../img/hexagon-overlay.png);
  background-position: 0 100%;
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .page-header-image {
    width: 270px;
    height: 330px;
    top: -7px;
  }
  .page-header-image:before, .page-header-image:after {
    width: 274px;
  }
}

@media (max-width: 47.99em) {
  .page-header-image {
    display: none;
  }
}

.page-header-short {
  height: auto;
}

.page-header-short .contain {
  padding-top: 40px;
  padding-bottom: 40px;
}

.page-header-research h1,
.page-header-research p {
  float: none;
  clear: both;
  width: 100%;
}

.page-header-research-landing h1,
.page-header-research-landing p {
  width: 50%;
}

@media (min-width: 64em) and (max-width: 89.99em) {
  .page-header-research-landing h1,
  .page-header-research-landing p {
    width: auto;
  }
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .page-header-research-landing h1,
  .page-header-research-landing p {
    width: auto;
  }
}

@media (max-width: 47.99em) {
  .page-header-research-landing h1,
  .page-header-research-landing p {
    width: 100%;
  }
}

.content-filters {
  float: right;
  margin-top: 10px;
}

.content-filters:before, .content-filters:after {
  content: ' ';
  display: table;
}

.content-filters:after {
  clear: both;
}

.content-filters > span {
  display: block;
  float: left;
  line-height: 37px;
  font-weight: bold;
  font-size: 22px;
  margin-right: 20px;
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .content-filters {
    margin-top: 0;
  }
}

@media (max-width: 63.99em) {
  .content-filters {
    margin-top: 20px;
    float: left;
    clear: both;
  }
}

.content-filter-select {
  float: left;
  height: 37px;
  border: 0;
  padding: 0;
  background-color: #fff;
  position: relative;
  width: 220px;
}

.content-filter-select + .content-filter-select {
  margin-left: 15px;
}

.content-filter-select:before, .content-filter-select:after {
  content: '';
  display: block;
  position: absolute;
  pointer-events: none;
}

.content-filter-select:before {
  top: 0;
  right: 0;
  height: 37px;
  width: 45px;
  background: linear-gradient(to left, white 0%, white 60%, rgba(255, 255, 255, 0.6) 70%, rgba(255, 255, 255, 0) 100%);
}

.content-filter-select:after {
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 10px solid #EE5F1C;
}

.content-filter-select button {
  display: block;
  border: 0;
  background-color: transparent;
  padding: 0 25px 0 15px;
  text-align: left;
  margin: 0;
  line-height: 37px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.content-filter-select button:first-letter {
  display: block;
  text-transform: uppercase;
}

.content-filter-select ul {
  position: absolute;
  z-index: 100;
  top: 100%;
  left: 0;
  width: 100%;
  height: 0;
  opacity: 0;
  overflow: hidden;
  background-color: #fff;
  border-width: 0 2px 2px 2px;
  transition: .5s height ease, .5s opacity ease;
}

.content-filter-select ul.active {
  height: auto;
  opacity: 1;
}

.content-filter-select li {
  padding: 10px 15px;
}

.content-filter-select li:before {
  display: none;
}

@media (max-width: 47.99em) {
  .content-filter-select {
    margin-left: 0;
    float: left;
    clear: left;
    margin-top: 10px;
  }
  .content-filter-select + .content-filter-select {
    margin-left: 0;
    float: left;
    clear: left;
    margin-top: 10px;
  }
}

.content {
  float: left;
  width: calc(100% - 490px);
  padding-top: 50px;
  padding-bottom: 50px;
}

.content.latest-block {
  width: 100%;
  float: none;
}

.content.latest-block-slim {
  width: 59.167%;
  float: left;
}

.news .content img {
  width: auto !important;
  height: auto !important;
}

.content iframe {
  max-width: 100%;
}

@media (min-width: 64em) and (max-width: 79.99em) {
  .content {
    width: calc(100% - 408px);
  }
  .content.latest-block-slim {
    width: calc(100% - 408px);
  }
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .content {
    width: calc(100% - 310px);
  }
  .content.latest-block-slim {
    width: calc(100% - 310px);
  }
}

@media (max-width: 47.99em) {
  .content {
    width: 100%;
    padding-top: 40px;
  }
  .content.latest-block-slim {
    width: 100%;
  }
}

.landing-listings {
  list-style: none;
}

.landing-listing {
  margin-bottom: 40px;
  padding-left: 0;
}

.landing-listing > a {
  display: block;
  height: 100%;
}

.landing-listing > a:before, .landing-listing > a:after {
  content: ' ';
  display: table;
}

.landing-listing > a:after {
  clear: both;
}

.landing-listing > a:hover, .landing-listing > a:focus {
  outline: 0;
}

.landing-listing > a:hover h2, .landing-listing > a:focus h2 {
  color: #3474BD;
}

.landing-listing > a:hover .link-button, .landing-listing > a:focus .link-button {
  background-color: #3474BD;
}

.landing-listing > a:hover .link-button:after, .landing-listing > a:focus .link-button:after {
  border-left-color: #3474BD;
}

.landing-listing > a:focus .landing-listing-image {
  transition: .5s transform ease;
  transform: translate(0, -20px);
}

@media (max-width: 47.99em) {
  .landing-listing > a:focus .landing-listing-image {
    transform: translate(-50%, -20px);
  }
}

.landing-listing:before {
  background-color: transparent;
  display: table;
  position: static;
  top: auto;
  left: auto;
  width: 0;
  height: 0;
  transform: translate(0, 0);
}

.landing-listing:last-child {
  margin-bottom: 0;
}

.landing-listing:last-child .landing-listing-detail {
  padding-bottom: 0;
  border: 0;
}

.landing-listing.no-link:before, .landing-listing.no-link:after {
  content: ' ';
  display: table;
}

.landing-listing.no-link:after {
  clear: both;
}

.landing-listing.no-link > a:hover h2 {
  color: #EE5F1C;
}

.landing-listing.no-link .landing-listing-detail p:last-child {
  margin-bottom: 0;
}

.landing-listing.no-image .landing-listing-detail {
  width: 100%;
  float: none;
}

.search-results .landing-listing:before {
  content: '';
}

.search-results .landing-listing:last-child .landing-listing-detail {
  padding-bottom: 40px;
  border-bottom: 1px solid #EBEBEB;
  margin-bottom: 40px;
}

.landing-listing-image {
  float: left;
  width: 194px;
  height: 168px;
  position: relative;
  margin-right: 30px;
  overflow: hidden;
  /*&:before,
    &:after {
        content: '';
        display: block;
        width: 0;
        border-top: 84px solid #fff;
        border-bottom: 84px solid #fff;
        position: absolute;
        top: 0;
        z-index: 4;
    }

    &:before {
        border-right: 45px solid transparent;
        left: -1px;
    }

    &:after {
        border-left: 45px solid transparent;
        right: 0;
    }*/
}

.landing-listing-image:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../img/hex-mask.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
}

.landing-listing-image img {
  vertical-align: bottom;
  max-width: 220px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .landing-listing-image {
    width: 96px;
    height: 84px;
    /*&:before,
        &:after {
            border-top-width: 42px;
            border-bottom-width: 42px;
        }

        &:before {
            border-right-width: 22px;
        }

        &:after {
            border-left-width: 22px;
        }*/
  }
  .landing-listings-the-team .landing-listing-image {
    float: none;
    clear: both;
    left: 50%;
    transform: translate(-50%, 0);
    margin-bottom: 40px;
    width: 194px;
    height: 168px;
    margin-right: 0;
    /*&:before,
            &:after {
                border-top-width: 84px;
                border-bottom-width: 84px;
            }

            &:before {
                border-right-width: 45px;
            }

            &:after {
                border-left-width: 45px;
            }*/
  }
}

@media (max-width: 47.99em) {
  .landing-listing-image {
    float: none;
    clear: both;
    left: 50%;
    transform: translate(-50%, 0);
    margin-bottom: 40px;
    margin-right: 0;
  }
}

.landing-listing-detail {
  float: left;
  width: calc(100% - 224px);
  padding-bottom: 40px;
  border-bottom: 1px solid #EBEBEB;
  min-height: 168px;
}

.landing-listing-detail:before, .landing-listing-detail:after {
  content: ' ';
  display: table;
}

.landing-listing-detail:after {
  clear: both;
}

.landing-listing-detail h2 {
  font-size: 30px;
  line-height: 1;
  color: #EE5F1C;
  margin-bottom: 10px;
  transition: .5s color ease;
}

.landing-listing-detail p {
  color: #0A0A0A;
  margin-bottom: 20px;
}

.no-image .landing-listing-detail {
  width: 100%;
  min-height: 0;
  float: none;
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .landing-listing-detail h2 {
    font-size: 24px;
  }
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .landing-listing-detail {
    width: calc(100% - 126px);
  }
  .landing-listing-detail h2 {
    font-size: 24px;
  }
  .landing-listings-the-team .landing-listing-detail {
    width: 100%;
    float: none;
    clear: both;
  }
}

@media (max-width: 47.99em) {
  .landing-listing-detail {
    float: none;
    clear: both;
    width: 100%;
    text-align: center;
  }
  .landing-listing-detail .link-button {
    left: 50%;
    transform: translate(-50%, 0);
    backface-visibility: hidden;
  }
  .landing-listing-detail h2 {
    font-size: 24px;
  }
}

.gallery-block {
  padding-top: 30px;
  border-top: 1px solid #EBEBEB;
}

.gallery-block:before, .gallery-block:after {
  content: ' ';
  display: table;
}

.gallery-block:after {
  clear: both;
}

.gallery-block h2 {
  color: #3474BD;
  font-size: 30px;
  margin-bottom: 20px;
}

.gallery-block a {
  float: left;
  width: 33%;
  margin-right: .5%;
  margin-bottom: 1px;
  padding: 0;
  transition: .5s opacity ease;
}

.gallery-block a:hover {
  opacity: .6;
}

.gallery-block a:before {
  display: none;
}

.gallery-block a:nth-of-type(3n + 3) {
  margin-right: 0;
}

.gallery-block a img {
  vertical-align: bottom;
  max-width: 100%;
}

@media (max-width: 29.99em) {
  .gallery-block a {
    width: 49.5%;
    margin-right: 1%;
  }
  .gallery-block a:nth-of-type(3n + 3) {
    margin-right: 1%;
  }
  .gallery-block a:nth-of-type(2n + 2) {
    margin-right: 0;
  }
  .gallery-block a:last-of-type {
    margin-right: 0;
  }
}

@media (min-width: 30em) and (max-width: 47.99em) {
  .gallery-block a {
    width: 33%;
    margin-right: .5%;
  }
}

.content-share {
  margin-top: 50px;
  border-top: 1px solid #EBEBEB;
  padding-top: 20px;
}

.content-share:before, .content-share:after {
  content: ' ';
  display: table;
}

.content-share:after {
  clear: both;
}

.social-print {
  padding: 0 0 0 58px;
  line-height: 32px;
  color: #0A0A0A;
  float: left;
  position: relative;
  display: block;
  background-color: transparent;
  border: 0;
}

.social-print:after {
  content: '';
  display: block;
  background-image: url(../img/social-print.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: auto 100%;
  height: 32px;
  width: 38px;
  position: absolute;
  left: 0;
  top: 0;
  transition: .5s transform ease;
}

.social-print:hover:after {
  transform: translate(0, -3px);
}

.social-share {
  float: right;
}

.social-share:before, .social-share:after {
  content: ' ';
  display: table;
}

.social-share:after {
  clear: both;
}

.social-share > span {
  float: left;
  margin-right: 10px;
  line-height: 32px;
}

.social-share button,
.social-share a {
  border: 0;
  padding: 0;
  margin-left: 10px;
  background-color: transparent;
  text-indent: 115%;
  overflow: hidden;
  white-space: nowrap;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  width: 38px;
  height: 32px;
  display: block;
  float: left;
  position: relative;
  transition: .5s transform ease;
}

.social-share button:hover,
.social-share a:hover {
  transform: translate(0, -3px);
}

.social-email {
  background-image: url(../img/social-email.png);
}

@media (max-width: 47.99em) {
  .social-print,
  .social-share {
    font-size: 16px;
  }
  .social-print .additional-title,
  .social-share .additional-title {
    display: none;
  }
  .social-share > span {
    margin-right: 0;
  }
}

.information-disclaimer {
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid #EBEBEB;
}

.search-info {
  padding-bottom: 20px;
  margin-bottom: 50px;
  border-bottom: 1px solid #EBEBEB;
}

.search-pagination:before, .search-pagination:after {
  content: ' ';
  display: table;
}

.search-pagination:after {
  clear: both;
}

.search-pagination li {
  float: left;
  padding: 0;
  margin-right: 10px;
}

.search-pagination li:before {
  display: none;
}

.search-pagination a,
.search-pagination span {
  line-height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  display: block;
  text-align: center;
}

.search-pagination a {
  background-color: #EE5F1C;
  color: #fff;
  transition: .5s background-color ease;
}

.search-pagination a:hover {
  color: #fff;
  background-color: #3474BD;
}

.search-pagination span {
  background-color: #EBEBEB;
  color: #0A0A0A;
}

.downloads-grid {
  display: flex;
  flex-wrap: wrap;
  /*@include mq($from: tablet, $until: desktop) {
        .latest-block-item {
            min-height: 520px;
        }
    }

    @include mq($from: mobileLandscape, $until: tablet) {
        .latest-block-item {
            min-height: 626px;
        }
    }*/
}

.downloads-grid > li {
  padding: 0;
  margin-right: 2%;
  flex: 0 1 32%;
  display: flex;
  align-items: stretch;
}

.downloads-grid > li:nth-child(3n + 3) {
  margin-right: 0;
}

.downloads-grid > li:before {
  display: none;
}

.downloads-grid .latest-block-item {
  border: 1px solid #3474BD;
  flex: 1 1 100%;
}

@media (min-width: 30em) and (max-width: 63.99em) {
  .downloads-grid > li {
    flex: 0 1 49%;
  }
  .downloads-grid > li:nth-child(3n + 3) {
    margin-right: 2%;
  }
  .downloads-grid > li:nth-child(2n+2) {
    margin-right: 0;
  }
}

@media (max-width: 29.99em) {
  .downloads-grid > li {
    margin-right: 0;
    flex: 1 1 100%;
  }
  .downloads-grid > li .latest-news-item {
    flex: 1 1 auto;
  }
}

.content-aside {
  float: right;
  width: 348px;
  padding-top: 140px;
  padding-bottom: 50px;
  position: relative;
}

.content-aside:before, .content-aside:after {
  content: ' ';
  display: table;
}

.content-aside:after {
  clear: both;
}

.content-aside > .link-button {
  clear: both;
  margin-bottom: 10px;
}

.content-aside > .link-button + .link-button {
  margin-top: 0;
}

.content-aside.content-aside-short, .content-aside.content-aside-project, .content-aside.content-aside-contact, .content-aside.content-side-news {
  padding-top: 50px;
}

.content-aside.mobile-only {
  display: none;
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .content-aside {
    padding-top: 110px;
    width: 270px;
  }
}

@media (max-width: 47.99em) {
  .content-aside {
    float: none;
    clear: both;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 0;
    border-bottom: 1px solid #EBEBEB;
  }
  .content-aside .link-button-back {
    display: none;
  }
  .content-aside.mobile-only {
    display: block;
  }
}

.secondary-nav {
  padding-left: 20px;
  border-left: 2px solid #EE5F1C;
  margin-bottom: 40px;
}

.secondary-nav:before, .secondary-nav:after {
  content: ' ';
  display: table;
}

.secondary-nav:after {
  clear: both;
}

.secondary-nav h2 {
  color: #3474BD;
  font-size: 22px;
  padding-bottom: 10px;
  border-bottom: 1px solid #EBEBEB;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 15px;
}

.secondary-nav h2 span {
  display: block;
  font-size: 17px;
  font-weight: normal;
}

.secondary-nav ul {
  list-style: none;
}

.secondary-nav ul ul {
  margin: 10px 0 20px 0;
}

.secondary-nav ul ul a {
  color: rgba(10, 10, 10, 0.8);
  font-size: 16px;
}

.secondary-nav ul ul a:before, .secondary-nav ul ul a:after {
  display: none;
}

.secondary-nav ul ul a:hover {
  color: #EE5F1C;
}

.secondary-nav li {
  margin-bottom: 20px;
}

.secondary-nav li:last-of-type {
  margin-bottom: 0;
}

.secondary-nav li li {
  margin-bottom: 10px;
}

.secondary-nav .active {
  position: relative;
}

.secondary-nav .active:before, .secondary-nav .active:after {
  content: '';
  display: block;
  position: absolute;
}

.secondary-nav .active:before {
  top: 1px;
  width: 20px;
  height: 18px;
  background-color: #fff;
  left: -30px;
}

.secondary-nav .active:after {
  top: 7px;
  width: 12px;
  height: 6px;
  background-color: #EE5F1C;
  left: -27px;
}

.secondary-nav .active > a {
  color: #EE5F1C;
  font-weight: bold;
  position: relative;
}

.secondary-nav .active > a:before, .secondary-nav .active > a:after {
  content: '';
  display: block;
  position: absolute;
  left: -27px;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}

.secondary-nav .active > a:before {
  border-bottom: 4px solid #EE5F1C;
  top: 3px;
}

.secondary-nav .active > a:after {
  border-top: 4px solid #EE5F1C;
  top: 13px;
}

.secondary-nav .active > a:hover {
  color: #3474BD;
}

.secondary-nav .active .active:before {
  top: 3px;
  width: 10px;
  height: 11px;
  background-color: #fff;
  left: -26px;
}

.secondary-nav .active .active:after {
  top: 7px;
  width: 8px;
  height: 3px;
  background-color: #EE5F1C;
  left: -25px;
}

.secondary-nav .active .active a:before, .secondary-nav .active .active a:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  left: -25px;
}

.secondary-nav .active .active a:before {
  top: 4px;
  border-bottom: 3px solid #EE5F1C;
}

.secondary-nav .active .active a:after {
  top: 10px;
  border-top: 3px solid #EE5F1C;
}

.secondary-nav .active .active a:hover {
  color: #3474BD;
}

.secondary-nav a {
  display: block;
  color: #0A0A0A;
  font-size: 17px;
}

.patient-stories-callout {
  background-color: #3474BD;
  color: #fff;
  clear: both;
}

@media (max-width: 47.99em) {
  .patient-stories-callout {
    display: none;
  }
}

.patient-stories-callout-image-grid:before, .patient-stories-callout-image-grid:after {
  content: ' ';
  display: table;
}

.patient-stories-callout-image-grid:after {
  clear: both;
}

.patient-stories-callout-image-grid img {
  float: left;
  width: 33.333333%;
}

.patient-stories-callout-content {
  padding: 25px 20px;
}

.patient-stories-callout-content:before, .patient-stories-callout-content:after {
  content: ' ';
  display: table;
}

.patient-stories-callout-content:after {
  clear: both;
}

.patient-stories-callout-content h2 {
  font-size: 30px;
  line-height: 1;
  margin-bottom: 20px;
}

.patient-stories-callout-content p {
  margin-bottom: 20px;
}

.patient-stories-callout-content .link-button:hover {
  background-color: #E5007A;
}

.patient-stories-callout-content .link-button:hover:after {
  border-left-color: #E5007A;
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .patient-stories-callout-content h2 {
    font-size: 24px;
  }
}

.patient-stories-more-information {
  padding: 25px 20px;
  background-color: #3474BD;
  color: #fff;
  margin-bottom: 40px;
}

.patient-stories-more-information:before, .patient-stories-more-information:after {
  content: ' ';
  display: table;
}

.patient-stories-more-information:after {
  clear: both;
}

.patient-stories-more-information h2 {
  font-size: 30px;
  line-height: 1;
  margin-bottom: 20px;
  clear: both;
}

.patient-stories-more-information p {
  margin-bottom: 20px;
}

.patient-stories-more-information .link-button:hover {
  background-color: #E5007A;
}

.patient-stories-more-information .link-button:hover:after {
  border-left-color: #E5007A;
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .patient-stories-more-information h2 {
    font-size: 24px;
  }
}

.event-logo {
  text-align: left;
  margin-bottom: 30px;
  margin-top: 0;
  clear: both;
}

.event-logo img {
  vertical-align: bottom;
  max-width: 100%;
}

.content-aside-apply .event-logo {
  margin-top: 0;
}

.event-information,
.contact-information {
  clear: both;
  /*.content-aside-apply & {
        ul {
            margin-bottom: 0;
        }
    }*/
}

.event-information > span,
.contact-information > span {
  margin-bottom: 20px;
  color: #EE5F1C;
}

.event-information ul,
.contact-information ul {
  list-style: none;
  margin-bottom: 30px;
}

.event-information ul:last-of-type,
.contact-information ul:last-of-type {
  margin-bottom: 50px;
}

.event-information li,
.contact-information li {
  padding-left: 68px;
  position: relative;
  line-height: 22px;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: auto 38px;
}

.event-information li:not(:last-child),
.contact-information li:not(:last-child) {
  margin-bottom: 26px;
}

.event-information li.contact-phone,
.contact-information li.contact-phone {
  line-height: 38px;
}

.event-information li.contact-location span,
.contact-information li.contact-location span {
  line-height: 38px;
}

.event-information span,
.contact-information span {
  font-weight: bold;
  display: block;
}

@media (min-width: 30em) and (max-width: 63.99em) {
  .content-aside-apply .event-information ul + ul, .content-aside-apply
  .contact-information ul + ul {
    padding-top: 40px;
  }
  .content-aside-apply .event-information ul:last-of-type, .content-aside-apply
  .contact-information ul:last-of-type {
    margin-bottom: 40px;
  }
}

@media (max-width: 29.99em) {
  .content-aside-apply .event-information ul + ul, .content-aside-apply
  .contact-information ul + ul {
    padding-top: 40px;
  }
  .content-aside-apply .event-information ul:last-of-type, .content-aside-apply
  .contact-information ul:last-of-type {
    margin-bottom: 40px;
  }
}

.event-date {
  background-image: url(../img/event-date.png);
}

.event-location,
.contact-location {
  background-image: url(../img/event-location.png);
}

.event-target {
  background-image: url(../img/event-target.png);
}

.event-fee {
  background-image: url(../img/event-fee.png);
}

.event-distance {
  background-image: url(../img/event-distance.png);
}

.contact-phone {
  background-image: url(../img/icon-phone.png);
}

.project-information,
.tribute-information {
  padding-left: 20px;
  padding-top: 190px;
  border-left: 2px solid #EE5F1C;
  margin-bottom: 40px;
  position: relative;
}

.project-information:before, .project-information:after,
.tribute-information:before,
.tribute-information:after {
  content: ' ';
  display: table;
}

.project-information:after,
.tribute-information:after {
  clear: both;
}

.project-information.no-image,
.tribute-information.no-image {
  padding-top: 0;
}

.project-information ul,
.tribute-information ul {
  list-style: none;
}

.project-information li,
.tribute-information li {
  margin-bottom: 20px;
}

.project-information li:last-of-type,
.tribute-information li:last-of-type {
  margin-bottom: 0;
}

.project-information li > span,
.tribute-information li > span {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.project-lead-researcher,
.tribute-image {
  position: absolute;
  top: 0;
  left: -42px;
  width: 182px;
  height: 156px;
  overflow: hidden;
}

.project-lead-researcher:before, .project-lead-researcher:after,
.tribute-image:before,
.tribute-image:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  border-top: 78px solid #fff;
  border-bottom: 78px solid #fff;
  height: 0;
  z-index: 2;
}

.project-lead-researcher:before,
.tribute-image:before {
  left: 0;
  border-right: 40px solid transparent;
}

.project-lead-researcher:after,
.tribute-image:after {
  right: 0;
  border-left: 40px solid transparent;
}

.project-lead-researcher img,
.tribute-image img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
}

@media (max-width: 47.99em) {
  .project-lead-researcher,
  .tribute-image {
    left: 20px;
  }
}

.content-accordion-section {
  border-top: 1px solid #EBEBEB;
  transition: .5s height ease;
}

.content-accordion-section:last-of-type {
  border-bottom: 1px solid #EBEBEB;
}

.content-accordion-toggle {
  border: 0;
  padding: 15px 0 0 58px;
  font-size: 22px;
  line-height: 33px;
  margin: 0;
  text-align: left;
  background-color: #fff;
  color: #EE5F1C;
  font-weight: bold;
  width: 100%;
  display: block;
  position: relative;
  transition: .5s color ease;
}

.content-accordion-toggle:hover {
  color: #3474BD;
}

.content-accordion-toggle:before {
  width: 38px;
  height: 33px;
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 15px;
  background-image: url(../img/hex-open.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
  transition: .5s background ease;
}

.content-accordion-toggle.active:before {
  background-image: url(../img/hex-close.png);
}

.content-accordion-text {
  padding: 15px 0 0 58px;
  overflow: hidden;
  transition: .5s height ease, .5s visibility;
}

.content-accordion-text[aria-hidden="true"] {
  visibility: hidden;
}

.content-accordion-text[aria-hidden="false"] {
  visibility: visible;
}

.content-accordion-text img {
  max-width: 100%;
}

.content-accordion-text p:first-child {
  padding-top: 15px;
}

.content-accordion-text h2 {
  font-size: 20px;
}

.content-accordion-text h3 {
  font-size: 18px;
  color: #0A0A0A;
  margin-bottom: 5px;
}

@media (max-width: 29.99em) {
  .content-accordion-text {
    padding: 15px 0 0 0;
  }
}

.no-js .content-accordion-text {
  padding: 15px 0 0 15px;
}

.no-js .content-accordion-text[aria-hidden="true"] {
  visibility: visible;
  height: auto;
}

.no-js .content-accordion-toggle {
  padding-left: 15px;
}

.no-js .content-accordion-toggle:before {
  display: none;
}

.no-js .content-accordion-toggle.active:before {
  display: none;
}

.link-button-wrapper,
.link-button {
  float: left;
  display: block;
  clear: both;
  text-transform: uppercase;
  line-height: 40px;
  padding-left: 20px;
  padding-right: 10px;
  padding-top: 0;
  padding-bottom: 0;
  border: 0;
  margin: 0 20px 0 0;
  position: relative;
  background-color: #EE5F1C;
  color: #fff;
  appearance: none;
  transition: .5s background-color ease;
}

.link-button-wrapper + .link-button,
.link-button + .link-button {
  margin-top: 10px;
}

.link-button-wrapper > input[type=submit],
.link-button > input[type=submit] {
  background-color: transparent;
}

.link-button-wrapper > input[type=submit]:hover,
.link-button > input[type=submit]:hover {
  background-color: transparent;
}

.link-button-wrapper:after,
.link-button:after {
  content: '';
  display: block;
  height: 0;
  width: 0;
  border-left: 16px solid #EE5F1C;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  position: absolute;
  right: -16px;
  top: 0;
  transition: .5s border-color ease;
}

.link-button-wrapper:hover,
.link-button:hover {
  background-color: #3474BD;
}

.link-button-wrapper:hover:after,
.link-button:hover:after {
  border-left-color: #3474BD;
}

.link-button-wrapper .additional-title,
.link-button .additional-title {
  display: inline;
}

@media (max-width: 29.99em) {
  .link-button-wrapper .additional-title,
  .link-button .additional-title {
    display: none;
  }
}

.link-button-white {
  background-color: #fff;
  color: #EE5F1C;
}

.link-button-white:after {
  border-left-color: #fff;
}

.link-button-white:hover {
  color: #fff;
}

.link-button-white.link-button-back:after {
  border-right-color: #fff;
}

.link-button-pink {
  background-color: #E5007A;
}

.link-button-pink:after {
  border-left-color: #E5007A;
}

.link-button-pink.link-button-back:after {
  border-right-color: #E5007A;
}

.link-button-back {
  margin: 0 0 0 20px;
  padding-left: 10px;
  padding-right: 20px;
}

.link-button-back:after {
  border-left: 0;
  border-right: 16px solid #EE5F1C;
  right: auto;
  left: -16px;
}

.link-button-back:hover:after {
  border-right-color: #3474BD;
}

.link-button-large {
  line-height: 50px;
}

.link-button-large:after {
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-left: 20px solid #EE5F1C;
  right: -20px;
}

.link-button-large.link-button-white:after {
  border-left: 20px solid #fff;
}

.link-button-large.link-button-white:hover:after {
  border-left-color: #3474BD;
}

p > .link-button {
  display: inline-block;
  float: none;
}

p > .link-button:hover {
  color: #fff;
}

.newsletter-signup {
  background-color: #3474BD;
  background-image: url(../img/mesh-white-2.png);
  background-repeat: no-repeat;
  background-position: -50px 0;
  background-size: auto 130%;
  padding-top: 60px;
  padding-bottom: 60px;
}

.newsletter-signup legend {
  color: #fff;
  font-size: 28px;
  font-weight: bold;
  line-height: 1;
  display: block;
  margin-bottom: 40px;
}

.newsletter-signup fieldset {
  padding: 0;
  border: 0;
}

.newsletter-signup fieldset:before, .newsletter-signup fieldset:after {
  content: ' ';
  display: table;
}

.newsletter-signup fieldset:after {
  clear: both;
}

.newsletter-signup input {
  border: 2px solid #fff;
  line-height: 46px;
  height: 50px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;
  transition: .5s border-color ease;
  border-radius: 0;
}

.newsletter-signup input:-moz-placeholder {
  color: #0A0A0A;
}

.newsletter-signup input::-moz-placeholder {
  color: #0A0A0A;
}

.newsletter-signup input:-ms-input-placeholder {
  color: #0A0A0A;
}

.newsletter-signup input::-webkit-input-placeholder {
  color: #0A0A0A;
}

.newsletter-signup input:focus {
  border-color: #E5007A;
}

.newsletter-signup input:focus:-moz-placeholder {
  color: #fff;
}

.newsletter-signup input:focus::-moz-placeholder {
  color: #fff;
}

.newsletter-signup input:focus:-ms-input-placeholder {
  color: #fff;
}

.newsletter-signup input:focus::-webkit-input-placeholder {
  color: #fff;
}

.newsletter-signup button {
  width: calc(100% - 20px);
}

.newsletter-signup button:hover {
  background-color: #fff;
  color: #EE5F1C;
}

.newsletter-signup button:hover:after {
  border-left-color: #fff;
}

.newsletter-signup div {
  width: 22.5%;
  float: left;
  margin-right: 3.333%;
}

.newsletter-signup div:last-child {
  margin-right: 0;
}

.newsletter-signup.slim {
  background-image: none;
  padding: 30px 30px 10px;
}

.newsletter-signup.slim form {
  padding: 0;
}

.newsletter-signup.slim fieldset {
  margin-bottom: 0;
}

.newsletter-signup.slim input {
  border: 2px solid #fff;
  width: 100%;
}

.newsletter-signup.slim input:focus {
  border-color: #E5007A;
}

.newsletter-signup.slim button {
  width: calc(100% - 20px);
}

.newsletter-signup.slim div {
  width: 47.183%;
  margin-right: 5.634%;
  margin-bottom: 20px;
}

.newsletter-signup.slim div:nth-child(2n+2) {
  margin-right: 0;
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .newsletter-signup.slim div {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

@media (min-width: 30em) and (max-width: 47.99em) {
  .newsletter-signup div {
    width: 48%;
    margin-bottom: 20px;
  }
  .newsletter-signup div:nth-child(odd) {
    margin-right: 4%;
  }
  .newsletter-signup div:nth-child(even) {
    margin-right: 0;
  }
}

@media (max-width: 29.99em) {
  .newsletter-signup div {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0;
  }
  .newsletter-signup.slim div {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.latest-block {
  padding-top: 50px;
  padding-bottom: 50px;
}

.latest-block > .link-button {
  left: 50%;
  width: 240px;
  margin-left: -120px;
}

.latest-block ul {
  list-style: none;
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
}

.latest-block ul li {
  flex: 0 1 22.5%;
  margin-right: 3.333%;
  padding-left: 0;
  background-color: #3474BD;
  display: flex;
  align-items: stretch;
}

.latest-block ul li:before {
  display: none;
}

.latest-block ul li:last-child, .latest-block ul li:nth-of-type(4n + 4) {
  margin-right: 0;
}

.latest-block.latest-block-home ul li {
  background-color: #fff;
  display: block;
}

.latest-block.latest-block-slim ul li {
  flex: 0 1 47.183%;
  margin-right: 5.634%;
}

.latest-block.latest-block-slim ul li:last-child, .latest-block.latest-block-slim ul li:nth-of-type(2n + 2) {
  margin-right: 0;
}

.no-flexbox .latest-block ul:before, .no-flexbox .latest-block ul:after {
  content: ' ';
  display: table;
}

.no-flexbox .latest-block ul:after {
  clear: both;
}

.no-flexbox .latest-block ul li {
  float: left;
  min-height: 640px;
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .latest-block ul li {
    flex: 0 1 32%;
    margin-right: 2%;
    margin-bottom: 40px;
  }
  .latest-block ul li:nth-of-type(4n + 4) {
    margin-right: 2%;
  }
  .latest-block ul li:nth-of-type(3n + 3) {
    margin-right: 0;
  }
  .latest-block.latest-block-home ul li:nth-of-type(3) {
    margin-right: 0;
  }
  .latest-block.latest-block-home ul li:last-of-type {
    display: none;
  }
}

@media (min-width: 30em) and (max-width: 47.99em) {
  .latest-block ul li {
    flex: 0 1 48%;
    margin-right: 4%;
    margin-bottom: 40px;
  }
  .latest-block ul li:nth-child(even) {
    margin-right: 0;
  }
  .latest-block.latest-block-slim ul li {
    flex: 0 1 48%;
    margin-right: 4%;
  }
  .latest-block.latest-block-slim ul li:last-child, .latest-block.latest-block-slim ul li:nth-of-type(2n + 2) {
    margin-right: 0;
  }
}

@media (max-width: 29.99em) {
  .latest-block ul li {
    float: none;
    flex: 1 1 100%;
    margin-right: 0;
    margin-bottom: 40px;
  }
  .latest-block.latest-block-slim ul li {
    float: none;
    flex: 1 1 100%;
    margin-right: 0;
    margin-bottom: 40px;
  }
  .latest-block.latest-block-slim ul li:last-child, .latest-block.latest-block-slim ul li:nth-of-type(2n + 2) {
    margin-right: 0;
  }
}

.latest-block-item {
  display: block;
  color: #fff;
  background-color: #3474BD;
  padding-bottom: 80px;
  position: relative;
  flex: 1 1 100%;
  /*.news &,
    .patient-stories & {
        min-height: 500px;
    }

    .research & {
        min-height: 530px;
    }*/
}

.latest-block-item img {
  vertical-align: bottom;
  width: 100%;
}

.latest-block-item img + h2 {
  margin-top: 20px;
}

.latest-block-item time {
  display: block;
  left: 50%;
  position: relative;
  transform: translate(-50%, 0);
  clear: both;
  background-color: #EE5F1C;
  color: #fff;
  width: 44px;
  height: 74px;
  line-height: 1;
  padding-top: 10px;
  font-size: 15px;
  text-transform: uppercase;
  text-align: center;
  margin-top: -37px;
  margin-bottom: 15px;
  transition: .5s background-color ease;
}

.latest-block-item time:before, .latest-block-item time:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  width: 0;
  height: 0;
  border-top: 37px solid transparent;
  border-bottom: 37px solid transparent;
  transition: .5s border-color ease;
}

.latest-block-item time:before {
  left: -20px;
  border-right: 20px solid #EE5F1C;
}

.latest-block-item time:after {
  right: -20px;
  border-left: 20px solid #EE5F1C;
}

.latest-block-item time span {
  display: block;
  font-size: 20px;
  font-weight: bold;
}

.latest-block-item p,
.latest-block-item h2,
.latest-block-item h3 {
  padding-left: 20px;
  padding-right: 20px;
  color: #fff;
}

.latest-block-item p {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 143.75%;
}

.latest-block-item h2 {
  color: #fff;
  margin-bottom: 10px;
  font-size: 22px;
  line-height: 113.636%;
  transition: .5s color ease;
}

.latest-block-item h3 {
  color: #fff;
  margin-bottom: 10px;
  margin-top: 0;
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
}

.latest-block-item .link-button {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.latest-block-item.latest-block-item-home {
  background-color: #fff;
  padding-bottom: 0;
}

.latest-block-item.latest-block-item-home p,
.latest-block-item.latest-block-item-home h2 {
  padding-left: 0;
  padding-right: 0;
}

.latest-block-item.latest-block-item-home p {
  color: #0A0A0A;
}

.latest-block-item.latest-block-item-home h2 {
  color: #EE5F1C;
  margin-bottom: 10px;
}

.latest-block-item.latest-block-item-home:hover p {
  color: #0A0A0A;
}

.latest-block-item.latest-block-item-home:hover h2 {
  color: #3474BD;
}

.latest-block-item.latest-block-item-home:hover time {
  background-color: #3474BD;
}

.latest-block-item.latest-block-item-home:hover time:before {
  border-right-color: #3474BD;
}

.latest-block-item.latest-block-item-home:hover time:after {
  border-left-color: #3474BD;
}

.latest-block-item:hover h2,
.latest-block-item:hover p {
  color: #fff;
}

.latest-block-item:hover .link-button {
  background-color: #fff;
  color: #EE5F1C;
}

.latest-block-item:hover .link-button:after {
  border-left-color: #fff;
}

.no-flexbox .latest-block-item {
  padding-bottom: 20px;
}

.no-flexbox .latest-block-item:before, .no-flexbox .latest-block-item:after {
  content: ' ';
  display: table;
}

.no-flexbox .latest-block-item:after {
  clear: both;
}

.no-flexbox .latest-block-item .link-button {
  margin-left: 20px;
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .latest-block-item h2 {
    font-size: 18px;
    line-height: 21px;
  }
}

@media (max-width: 47.99em) {
  .latest-block-item {
    flex: 1 1 auto;
  }
  .news .latest-block-item,
  .patient-stories .latest-block-item,
  .research .latest-block-item {
    min-height: 0;
  }
}

.video-list-item {
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #EBEBEB;
}

.video-list-item:last-child {
  border: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.video-list-item blockquote {
  margin-bottom: 0;
}

.video-list-item blockquote p {
  margin-bottom: 0;
}

.video-list-item-embed {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  overflow: hidden;
  margin-bottom: 30px;
}

.video-list-item-embed > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.job-listing {
  padding-bottom: 40px;
  border-bottom: 1px solid #EBEBEB;
  margin-bottom: 40px;
}

.job-listing:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0;
}

.job-detail-title {
  font-weight: bold;
}

.donate-feature {
  position: relative;
  padding: 40px;
  background-color: #3474BD;
  color: #fff;
  margin: 0 0 40px;
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .donate-feature {
    padding: 20px 20px 100px;
    margin: 0 0 20px;
  }
}

@media (max-width: 47.99em) {
  .donate-feature {
    padding: 10px 10px 100px;
    margin: 0 0 20px;
  }
}

.donate-feature.active-regular .donate-row:nth-child(1),
.donate-feature.active-regular .donate-row:nth-child(2),
.donate-feature.active-regular .donate-row:nth-child(3) {
  display: inline-block;
}

.donate-feature.active-regular .donate-row:nth-child(4),
.donate-feature.active-regular .donate-row:nth-child(5),
.donate-feature.active-regular .donate-row:nth-child(6) {
  display: none;
}

.donate-feature.active-one .donate-row:nth-child(1),
.donate-feature.active-one .donate-row:nth-child(2),
.donate-feature.active-one .donate-row:nth-child(3) {
  display: none;
}

.donate-feature.active-one .donate-row:nth-child(4),
.donate-feature.active-one .donate-row:nth-child(5),
.donate-feature.active-one .donate-row:nth-child(6) {
  display: inline-block;
}

.donate-buttons {
  padding-bottom: 20px;
  clear: both;
}

.donate-buttons:before, .donate-buttons:after {
  content: ' ';
  display: table;
}

.donate-buttons:after {
  clear: both;
}

.donate-button {
  font-size: 32px;
  text-transform: uppercase;
  font-weight: bold;
  float: left;
  width: 49.762%;
  display: block;
  text-align: center;
  height: 60px;
  line-height: 60px;
  position: relative;
  background-color: #EE5F1C;
  color: #fff;
  border: 0;
  padding: 0;
  margin: 0 0 15px;
  transition: .5s background-color ease, .5s color ease;
}

.donate-button::after {
  content: '';
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 0;
  background-color: transparent;
  background-image: url(../img/donate-arrow-down.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 50% 100%;
  transition: .25s height ease;
}

.donate-button:last-of-type {
  float: right;
}

.donate-button:hover, .donate-button:focus {
  background-color: #fff;
  color: #EE5F1C;
  outline: 0;
}

.donate-button.active {
  background-color: #fff;
  color: #EE5F1C;
}

.donate-button.active::after {
  height: 15px;
}

.donate-button.active:hover, .donate-button.active:focus {
  color: #3474BD;
}

@media (max-width: 47.99em) {
  .donate-button {
    font-size: 20px;
    height: 46px;
    line-height: 46px;
  }
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .donate-button {
    font-size: 24px;
    height: 46px;
    line-height: 46px;
  }
}

.donate-amount {
  padding-bottom: 35px;
  clear: both;
}

.donate-amount:before, .donate-amount:after {
  content: ' ';
  display: table;
}

.donate-amount:after {
  clear: both;
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .donate-amount {
    padding: 0 0 25px;
  }
}

@media (max-width: 47.99em) {
  .donate-amount {
    padding: 0 10px 15px;
  }
}

.donate-row {
  display: inline-block;
  position: relative;
  overflow: hidden;
  margin-right: 34px;
}

.donate-row.donate-row--other {
  margin-right: 0;
}

.donate-row--other {
  float: right;
  clear: right;
}

.donate-row--other .donate-radio + label {
  text-indent: 115%;
  overflow: hidden;
  white-space: nowrap;
  width: 35px;
  float: left;
}

@media (min-width: 48em) and (max-width: 79.99em) {
  .donate-row {
    width: 48%;
    margin-right: 0;
  }
  .donate-row:nth-of-type(4n+2) {
    float: right;
  }
  .donate-row--other {
    width: 48%;
  }
}

@media (max-width: 47.99em) {
  .donate-row {
    width: 31%;
    margin-right: 0;
  }
  .donate-row--other {
    width: 100%;
    clear: both;
    float: none;
  }
}

.donate-radio {
  width: 1px;
  height: 1px;
  position: absolute;
  top: -1px;
  left: -1px;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
}

.donate-radio + label {
  position: relative;
  padding-left: 35px;
  display: block;
  font-size: 40px;
  line-height: 50px;
  height: 50px;
  cursor: pointer;
  transition: .5s font-weight ease;
  font-weight: lighter;
}

.donate-radio + label::before, .donate-radio + label::after {
  content: '';
  display: block;
  position: absolute;
  border-radius: 50%;
  top: 50%;
  transform: translate(0, -50%);
}

.donate-radio + label::before {
  width: 20px;
  height: 20px;
  left: 0;
  background-color: #fff;
  opacity: .2;
  transition: .5s opacity ease;
}

.donate-radio + label::after {
  width: 10px;
  height: 10px;
  left: 5px;
  background-color: #3474BD;
  transition: .5s background-color ease;
}

.donate-radio:checked + label::before {
  opacity: 1;
}

.donate-radio:checked + label::after {
  background-color: #EE5F1C;
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .donate-radio + label {
    font-size: 24px;
    height: 44px;
    line-height: 44px;
    padding-left: 30px;
  }
}

@media (max-width: 47.99em) {
  .donate-radio + label {
    font-size: 20px;
    height: 44px;
    line-height: 44px;
    padding-left: 30px;
  }
}

.donate-input {
  height: 50px;
  line-height: 50px;
  color: #3474BD;
  background-color: #fff;
  padding: 0 5px;
  font-size: 40px;
  text-transform: uppercase;
  font-weight: bold;
  float: left;
  max-width: 154px;
  border: 0;
  font-weight: bold;
  letter-spacing: -2px;
  appearance: textfield;
}

.donate-input::placeholder {
  color: #3474BD;
  opacity: 1;
}

.donate-input:focus {
  outline: 0;
  opacity: 1;
}

.donate-input:invalid {
  background-color: #E5007A;
  color: #fff;
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .donate-input {
    height: 44px;
    line-height: 44px;
    font-size: 24px;
    max-width: calc(100% - 35px);
  }
}

@media (max-width: 47.99em) {
  .donate-input {
    height: 44px;
    line-height: 44px;
    font-size: 20px;
    max-width: calc(100% - 35px);
  }
}

.donate-content {
  clear: both;
}

.donate-content:before, .donate-content:after {
  content: ' ';
  display: table;
}

.donate-content:after {
  clear: both;
}

.donate-text {
  float: left;
  width: 61.685%;
  position: relative;
  height: 50px;
  font-size: 16px;
  line-height: 118.75%;
}

.donate-text p {
  margin: 0;
}

@media (max-width: 63.99em) {
  .donate-text {
    float: none;
    width: 100%;
    height: auto;
    overflow: visible;
    padding: 0 10px;
  }
}

.donate-link {
  float: right;
  width: 31.797%;
  background-color: #E5007A;
  color: #fff;
  text-align: center;
  display: block;
  font-size: 18px;
  height: 50px;
  line-height: 50px;
  text-transform: uppercase;
  font-weight: bold;
  transition: .5s background-color ease;
}

.donate-link:hover, .donate-link:focus {
  background-color: #EE5F1C;
  outline: 0;
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .donate-link {
    float: none;
    width: calc(100% - 40px);
    position: absolute;
    bottom: 20px;
    left: 20px;
  }
}

@media (max-width: 47.99em) {
  .donate-link {
    float: none;
    width: calc(100% - 40px);
    position: absolute;
    bottom: 20px;
    left: 20px;
  }
}

.carousel {
  position: relative;
  height: 530px;
}

@media (min-width: 64em) and (max-width: 79.99em) {
  .carousel {
    height: 430px;
  }
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .carousel {
    height: 380px;
  }
}

@media (min-width: 30em) and (max-width: 47.99em) {
  .carousel {
    height: 640px;
  }
}

@media (max-width: 29.99em) {
  .carousel {
    height: 580px;
  }
}

.carousel-slide-images {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 68.75%;
  z-index: 1;
}

.carousel-slide-images:after {
  content: '';
  display: block;
  height: 0;
  width: 0;
  border-left: 330px solid transparent;
  border-bottom: 550px solid #fff;
  position: absolute;
  right: 0;
  top: 0;
}

.carousel-slide-images .carousel-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-image: url(../img/slide-placeholder.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  transition: 1.5s opacity ease;
  opacity: 0;
}

.carousel-slide-images .carousel-slide-active {
  opacity: 1;
}

.no-js .carousel-slide-images .carousel-slide:first-of-type {
  opacity: 1;
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .carousel-slide-images {
    width: 85%;
  }
}

@media (min-width: 64em) and (max-width: 79.99em) {
  .carousel-slide-images {
    width: 78.125%;
  }
}

@media (min-width: 30em) and (max-width: 47.99em) {
  .carousel-slide-images {
    position: relative;
    left: auto;
    top: auto;
    bottom: auto;
    height: 320px;
    width: 100%;
    overflow: hidden;
  }
  .carousel-slide-images:after {
    display: none;
  }
}

@media (max-width: 29.99em) {
  .carousel-slide-images {
    position: relative;
    left: auto;
    top: auto;
    bottom: auto;
    height: 200px;
    width: 100%;
    overflow: hidden;
  }
  .carousel-slide-images:after {
    display: none;
  }
}

.carousel-slide-contents {
  position: absolute;
  right: 0;
  top: 40px;
  bottom: 80px;
  width: 53.125%;
  overflow: hidden;
  z-index: 2;
  color: #fff;
  background-color: #EE5F1C;
}

.carousel-slide-contents .carousel-slide {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 40px 50px;
  background-color: #EE5F1C;
  left: 100%;
  transition: .5s left ease, .5s opacity ease;
  opacity: 0;
}

.carousel-slide-contents .carousel-slide-active {
  left: 0;
  opacity: 1;
  z-index: 2;
}

.carousel-slide-contents .carousel-slide-inactive {
  opacity: 0;
  z-index: -1;
}

.no-js .carousel-slide-contents .carousel-slide:first-of-type {
  left: 0;
  opacity: 1;
  z-index: 0;
}

.carousel-slide-contents h2,
.carousel-slide-contents p {
  max-width: 650px;
}

.carousel-slide-contents h2 {
  font-size: 44px;
}

.carousel-slide-contents .link-button {
  position: absolute;
  float: none;
  left: 50px;
  bottom: 40px;
}

@media (min-width: 64em) and (max-width: 79.99em) {
  .carousel-slide-contents {
    width: 53.711%;
    bottom: 40px;
  }
  .carousel-slide-contents h2 {
    font-size: 28px;
  }
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .carousel-slide-contents {
    width: 52.083%;
    top: 30px;
    bottom: 30px;
  }
  .carousel-slide-contents .carousel-slide {
    padding: 30px;
  }
  .carousel-slide-contents h2 {
    font-size: 28px;
    margin-bottom: 10px;
  }
  .carousel-slide-contents p {
    line-height: 125%;
    margin-bottom: 10px;
  }
  .carousel-slide-contents .link-button {
    bottom: 30px;
    left: 30px;
  }
}

@media (max-width: 47.99em) {
  .carousel-slide-contents {
    position: relative;
    top: auto;
    right: auto;
    width: 100%;
    padding: 20px;
    top: auto;
    bottom: auto;
  }
  .carousel-slide-contents .carousel-slide {
    padding: 20px;
  }
  .carousel-slide-contents h2 {
    font-size: 28px;
  }
  .carousel-slide-contents .link-button {
    bottom: 20px;
    left: 20px;
  }
}

@media (min-width: 30em) and (max-width: 47.99em) {
  .carousel-slide-contents {
    height: 320px;
  }
}

@media (max-width: 29.99em) {
  .carousel-slide-contents {
    height: 380px;
  }
}

.carousel-progress {
  position: absolute;
  right: 50px;
  bottom: 110px;
  z-index: 4;
}

.carousel-progress:before, .carousel-progress:after {
  content: ' ';
  display: table;
}

.carousel-progress:after {
  clear: both;
}

.carousel-progress > span {
  display: block;
  float: left;
  margin-right: 5px;
  text-indent: 115%;
  white-space: nowrap;
  overflow: hidden;
  width: 16px;
  height: 16px;
  background-color: transparent;
  background-image: url(../img/carousel-hex.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 16px auto;
  position: relative;
}

.carousel-progress > span.active {
  background-image: url(../img/carousel-hex-filled.png);
}

.no-js .carousel-progress {
  display: none;
}

@media (min-width: 64em) and (max-width: 79.99em) {
  .carousel-progress {
    bottom: 80px;
  }
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .carousel-progress {
    bottom: 60px;
    right: 30px;
  }
}

@media (max-width: 47.99em) {
  .carousel-progress {
    bottom: 20px;
    right: 20px;
  }
}

.fundraising-feature {
  padding-top: 80px;
  padding-bottom: 90px;
  margin-top: -80px;
  margin-bottom: -80px;
  position: relative;
  z-index: 4;
  min-height: 500px;
}

.fundraising-feature:before, .fundraising-feature:after {
  content: ' ';
  display: table;
}

.fundraising-feature:after {
  clear: both;
}

.fundraising-feature .contain {
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (min-width: 64em) and (max-width: 79.99em) {
  .fundraising-feature {
    padding-top: 40px;
    padding-bottom: 40px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .fundraising-feature .contain {
    padding-top: 30px;
  }
}

@media (max-width: 63.99em) {
  .fundraising-feature {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.fundraising-feature-text {
  float: left;
  width: 500px;
  z-index: 5;
  position: relative;
}

.fundraising-feature-text h2 {
  color: #3474BD;
  font-size: 44px;
}

@media (min-width: 64em) and (max-width: 79.99em) {
  .fundraising-feature-text {
    width: 400px;
  }
  .fundraising-feature-text h2 {
    font-size: 28px;
  }
}

@media (max-width: 63.99em) {
  .fundraising-feature-text {
    width: 100%;
    float: none;
    clear: both;
    margin-bottom: 40px;
  }
  .fundraising-feature-text:before, .fundraising-feature-text:after {
    content: ' ';
    display: table;
  }
  .fundraising-feature-text:after {
    clear: both;
  }
  .fundraising-feature-text h2 {
    font-size: 28px;
  }
}

.fundraising-feature-events {
  position: absolute;
  top: -20px;
  right: 20px;
  width: 600px;
  height: 440px;
  background-image: url(../img/faded-hexagons.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 100% 0;
}

@media (min-width: 64em) and (max-width: 79.99em) {
  .fundraising-feature-events {
    top: 0;
  }
}

@media (max-width: 63.99em) {
  .fundraising-feature-events {
    position: relative;
    top: auto;
    right: auto;
    width: 100%;
    clear: both;
  }
  .fundraising-feature-events:before, .fundraising-feature-events:after {
    content: ' ';
    display: table;
  }
  .fundraising-feature-events:after {
    clear: both;
  }
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .fundraising-feature-events {
    height: 300px;
  }
}

@media (max-width: 47.99em) {
  .fundraising-feature-events {
    height: auto;
    background-image: none;
  }
}

.fundraising-feature-event {
  display: block;
  height: 100%;
  text-align: center;
}

.fundraising-feature-event:hover .fundraising-feature-event-image {
  top: 20px;
  left: 40px;
}

.fundraising-feature-event:hover .fundraising-feature-event-detail {
  background-color: #295b95;
  left: 310px;
  top: 110px;
}

.fundraising-feature-event:hover .fundraising-feature-event-detail:before {
  border-right-color: #295b95;
}

.fundraising-feature-event:hover .fundraising-feature-event-detail:after {
  border-left-color: #295b95;
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .fundraising-feature-event:hover .fundraising-feature-event-image,
  .fundraising-feature-event:hover .fundraising-feature-event-detail {
    top: auto;
  }
  .fundraising-feature-event:hover .fundraising-feature-event-image {
    left: 33%;
    transform: translate(-50%, 0);
  }
  .fundraising-feature-event:hover .fundraising-feature-event-detail {
    left: 66%;
    transform: translate(-50%, 0);
  }
}

@media (max-width: 47.99em) {
  .fundraising-feature-event:hover .fundraising-feature-event-image,
  .fundraising-feature-event:hover .fundraising-feature-event-detail {
    left: 50%;
    top: auto;
  }
}

.fundraising-feature-event-image {
  width: 334px;
  height: 290px;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  transition: .5s left ease, .5s top ease;
}

.fundraising-feature-event-image:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../img/hex-mask.png);
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: contain;
  z-index: 2;
}

.fundraising-feature-event-image img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  z-index: 1;
}

@media (max-width: 29.99em) {
  .fundraising-feature-event-image {
    width: 280px;
    height: 232px;
  }
  .fundraising-feature-event-image:after {
    background-size: cover;
  }
}

.fundraising-feature-event-detail {
  background-color: #3474BD;
  color: #fff;
  padding: 30px 0;
  width: 174px;
  height: 290px;
  text-align: center;
  position: absolute;
  z-index: 3;
  left: 350px;
  top: 150px;
  transition: .5s background-color ease, .5s left ease, .5s top ease;
}

.fundraising-feature-event-detail:before, .fundraising-feature-event-detail:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  width: 0;
  height: 0;
  border-top: 145px solid transparent;
  border-bottom: 145px solid transparent;
  transition: .5s border-color ease;
}

.fundraising-feature-event-detail:before {
  left: -80px;
  border-right: 80px solid #3474BD;
}

.fundraising-feature-event-detail:after {
  right: -80px;
  border-left: 80px solid #3474BD;
}

.fundraising-feature-event-detail time {
  display: block;
  left: 50%;
  position: relative;
  transform: translate(-50%, 0);
  clear: both;
  background-color: #fff;
  color: #3474BD;
  width: 44px;
  height: 74px;
  line-height: 1;
  padding-top: 17px;
  text-transform: uppercase;
  text-align: center;
}

.fundraising-feature-event-detail time:before, .fundraising-feature-event-detail time:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  width: 0;
  height: 0;
  border-top: 37px solid transparent;
  border-bottom: 37px solid transparent;
  z-index: 1;
}

.fundraising-feature-event-detail time:before {
  left: -20px;
  border-right: 20px solid #fff;
}

.fundraising-feature-event-detail time:after {
  right: -20px;
  border-left: 20px solid #fff;
}

.fundraising-feature-event-detail time span {
  display: block;
  font-size: 24px;
  font-weight: bold;
}

.fundraising-feature-event-detail time,
.fundraising-feature-event-detail h3,
.fundraising-feature-event-detail p {
  position: relative;
  z-index: 2;
}

.fundraising-feature-event-detail h3 {
  font-size: 20px;
  line-height: 1;
  margin-bottom: 5px;
  margin-left: -20px;
  margin-right: -20px;
}

.fundraising-feature-event-detail p {
  margin: 0;
  font-size: 16px;
  margin-left: -20px;
  margin-right: -20px;
}

@media (max-width: 29.99em) {
  .fundraising-feature-event-detail {
    width: 140px;
    height: 232px;
    padding: 10px 0 0;
  }
  .fundraising-feature-event-detail:before, .fundraising-feature-event-detail:after {
    border-top: 116px solid transparent;
    border-bottom: 116px solid transparent;
  }
  .fundraising-feature-event-detail:before {
    left: -70px;
    border-right: 70px solid #3474BD;
  }
  .fundraising-feature-event-detail:after {
    right: -70px;
    border-left: 70px solid #3474BD;
  }
  .fundraising-feature-event-detail h3 {
    font-size: 16px;
  }
  .fundraising-feature-event-detail p {
    font-size: 14px;
  }
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .fundraising-feature-event-image,
  .fundraising-feature-event-detail {
    position: absolute;
    top: auto;
  }
  .fundraising-feature-event-image {
    left: 33%;
    transform: translate(-50%, 0);
  }
  .fundraising-feature-event-detail {
    left: 66%;
    transform: translate(-50%, 0);
  }
}

@media (max-width: 47.99em) {
  .fundraising-feature-event-image,
  .fundraising-feature-event-detail {
    position: relative;
    left: 50%;
    top: auto;
    clear: both;
    margin-bottom: 20px;
    transform: translate(-50%, 0);
  }
}

.quote-block {
  background-image: url(../img/hex-bottom-right.png);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: auto 160px;
}

@media (max-width: 47.99em) {
  .quote-block {
    background-image: none;
  }
}

.quote {
  position: relative;
  height: 530px;
}

@media (min-width: 64em) and (max-width: 79.99em) {
  .quote {
    height: 430px;
  }
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .quote {
    height: 480px;
  }
}

@media (min-width: 30em) and (max-width: 47.99em) {
  .quote {
    height: auto;
  }
}

@media (max-width: 29.99em) {
  .quote {
    height: auto;
  }
}

.quote-image {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 68.75%;
  z-index: 1;
  background-image: url(../img/quote-placeholder.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.quote-image:after {
  content: '';
  display: block;
  height: 0;
  width: 0;
  border-left: 330px solid transparent;
  border-top: 550px solid #fff;
  position: absolute;
  right: 0;
  bottom: 0;
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .quote-image {
    width: 85%;
  }
}

@media (min-width: 64em) and (max-width: 79.99em) {
  .quote-image {
    width: 78.125%;
  }
}

@media (max-width: 47.99em) {
  .quote-image {
    position: relative;
    left: auto;
    top: auto;
    bottom: auto;
    height: 200px;
    width: 100%;
  }
  .quote-image:after {
    border-left: 180px solid transparent;
    border-bottom: 0;
    border-top: 300px solid #fff;
    left: 85%;
    right: auto;
    top: 0;
  }
}

.quote-content {
  position: absolute;
  right: 0;
  top: 50px;
  bottom: 0;
  width: 53.125%;
  padding: 40px 50px;
  z-index: 2;
  background-color: #EE5F1C;
  color: #fff;
}

.quote-content:before, .quote-content:after {
  content: ' ';
  display: table;
}

.quote-content:after {
  clear: both;
}

.quote-content blockquote {
  margin-bottom: 30px;
}

.quote-content p {
  font-size: 42px;
  line-height: 45px;
  margin-bottom: 15px;
  font-weight: bold;
  max-width: 650px;
}

.quote-content p:before {
  content: '“';
}

.quote-content p:after {
  content: '”';
}

.quote-content cite {
  display: block;
}

@media (min-width: 64em) and (max-width: 79.99em) {
  .quote-content {
    width: 53.711%;
  }
  .quote-content p {
    font-size: 28px;
    line-height: 31px;
  }
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .quote-content {
    width: 52.083%;
    padding: 30px;
    top: 30px;
  }
  .quote-content p {
    font-size: 28px;
    line-height: 31px;
  }
  .quote-content cite {
    font-size: 16px;
    line-height: 125%;
  }
}

@media (max-width: 47.99em) {
  .quote-content {
    position: relative;
    top: auto;
    right: auto;
    width: 100%;
    padding: 20px;
    top: auto;
    bottom: auto;
  }
  .quote-content p {
    font-size: 30px;
    line-height: 33px;
  }
}

@media (min-width: 30em) and (max-width: 47.99em) {
  .quote-content {
    height: auto;
  }
}

@media (max-width: 29.99em) {
  .quote-content {
    height: auto;
  }
}

.quote-slim {
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
}

.quote-slim:before, .quote-slim:after {
  content: ' ';
  display: table;
}

.quote-slim:after {
  clear: both;
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .quote-slim {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media (max-width: 47.99em) {
  .quote-slim {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.quote-slim-image {
  float: right;
  width: 270px;
  height: 234px;
  background-image: url(../img/quote-placeholder.jpg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  overflow: hidden;
}

.quote-slim-image > div {
  background-image: url(../img/hex-mask.png);
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: cover;
  width: 100%;
  height: 100%;
}

@media (max-width: 47.99em) {
  .quote-slim-image {
    float: none;
    clear: both;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    margin-bottom: 30px;
  }
}

.quote-slim-content {
  width: 74.167%;
  max-width: calc(100% - 310px);
  float: left;
}

.quote-slim-content:before, .quote-slim-content:after {
  content: ' ';
  display: table;
}

.quote-slim-content:after {
  clear: both;
}

.quote-slim-content blockquote {
  margin-bottom: 15px;
}

.quote-slim-content p {
  font-size: 28px;
  font-weight: bold;
  line-height: 30px;
  margin-bottom: 10px;
  position: relative;
  padding-top: 40px;
  color: #3474BD;
}

.quote-slim-content p:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 30px;
  background-image: url(../img/quote-mark.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
}

.quote-slim-content cite {
  display: block;
  font-size: 16px;
  margin-bottom: 0;
}

@media (min-width: 64em) and (max-width: 79.99em) {
  .quote-slim-content h2 {
    font-size: 28px;
  }
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .quote-slim-content p {
    font-size: 28px;
  }
  .quote-slim-content cite {
    font-size: 16px;
    line-height: 125%;
  }
}

@media (max-width: 47.99em) {
  .quote-slim-content {
    width: 100%;
    max-width: 100%;
    float: none;
    clear: both;
  }
  .quote-slim-content p {
    font-size: 30px;
  }
}

@media (min-width: 30em) and (max-width: 47.99em) {
  .quote-slim-content {
    height: auto;
  }
}

@media (max-width: 29.99em) {
  .quote-slim-content {
    height: auto;
  }
}

.callout {
  position: relative;
  height: 440px;
}

@media (min-width: 30em) and (max-width: 47.99em) {
  .callout {
    height: auto;
  }
}

@media (max-width: 29.99em) {
  .callout {
    height: auto;
  }
}

.callout-image {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 68.75%;
  z-index: 1;
  background-image: url(../img/callout-placeholder.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.callout-image:after {
  content: '';
  display: block;
  height: 0;
  width: 0;
  border-left: 330px solid transparent;
  border-top: 550px solid #fff;
  position: absolute;
  right: 0;
  bottom: 0;
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .callout-image {
    width: 85%;
  }
}

@media (min-width: 64em) and (max-width: 79.99em) {
  .callout-image {
    width: 78.125%;
  }
}

@media (max-width: 47.99em) {
  .callout-image {
    position: relative;
    left: auto;
    top: auto;
    bottom: auto;
    height: 200px;
    width: 100%;
  }
  .callout-image:after {
    border-left: 180px solid transparent;
    border-bottom: 0;
    border-top: 300px solid #fff;
    left: 85%;
    right: auto;
    top: 0;
  }
}

.callout-content {
  position: absolute;
  right: 0;
  top: 80px;
  bottom: 40px;
  width: 53.125%;
  padding: 40px 50px;
  z-index: 2;
  background-color: #EE5F1C;
  color: #fff;
}

.callout-content:before, .callout-content:after {
  content: ' ';
  display: table;
}

.callout-content:after {
  clear: both;
}

.callout-content h2 {
  font-size: 44px;
}

@media (min-width: 64em) and (max-width: 79.99em) {
  .callout-content {
    width: 53.711%;
    top: 40px;
  }
  .callout-content h2 {
    font-size: 28px;
  }
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .callout-content {
    width: 52.083%;
    padding: 30px;
    top: 30px;
    bottom: 30px;
  }
  .callout-content h2 {
    font-size: 28px;
  }
}

@media (max-width: 47.99em) {
  .callout-content {
    position: relative;
    top: auto;
    right: auto;
    width: 100%;
    padding: 20px;
    top: auto;
    bottom: auto;
  }
  .callout-content h2 {
    font-size: 30px;
  }
}

@media (min-width: 30em) and (max-width: 47.99em) {
  .callout-content {
    height: auto;
  }
}

@media (max-width: 29.99em) {
  .callout-content {
    height: auto;
  }
}

.justgiving-title,
.paypal-title {
  float: right;
  clear: right;
  margin-top: 0;
}

@media (max-width: 63.99em) {
  .justgiving-title,
  .paypal-title {
    float: none;
    clear: both;
  }
}

.justgiving-title {
  display: block;
  text-indent: 110%;
  white-space: nowrap;
  overflow: hidden;
  width: 250px;
  height: 44px;
  background-color: transparent;
  background-image: url("../img/logo-justgiving.png");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
}

.paypal-title {
  display: block;
  text-indent: 110%;
  white-space: nowrap;
  overflow: hidden;
  width: 362px;
  height: 52px;
  background-color: transparent;
  background-image: url("../img/paypal-logo-smaller.png");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
}

@media (max-width: 29.99em) {
  .paypal-title {
    width: 240px;
    height: 35px;
  }
}

.link-button-contain {
  float: left;
  clear: left;
}

.link-button-contain .link-button {
  clear: left;
}

@media (max-width: 63.99em) {
  .link-button-contain {
    float: none;
    clear: both;
  }
}

.discount-code {
  font-size: 20px;
  line-height: 24px;
}

.transaction-id,
.transaction-total,
.fundraising-page-title {
  font-weight: bold;
}

.transaction-id {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}

.breadcrumb {
  height: 50px;
  margin-bottom: 50px;
}

.breadcrumb-step {
  float: left;
  display: block;
  background-color: #EE5F1C;
  position: relative;
  height: 100%;
  padding: 0 20px 0 60px;
  margin-right: 23px;
  line-height: 50px;
  color: #fff;
}

.breadcrumb-step:before, .breadcrumb-step:after {
  content: '';
  position: absolute;
  top: 0;
}

.breadcrumb-step:before {
  left: -20px;
  display: none;
  z-index: 1;
  border-top: 25px solid #EE5F1C;
  border-bottom: 25px solid #EE5F1C;
  border-left: 20px solid transparent;
}

.breadcrumb-step:after {
  right: -20px;
  z-index: 2;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-left: 20px solid #EE5F1C;
}

.breadcrumb-step:not(:first-of-type):before {
  display: block;
}

.breadcrumb-step .breadcrumb-step-text {
  display: none;
}

@media (min-width: 80em) {
  .breadcrumb-step .breadcrumb-step-text {
    display: block;
  }
}

@media (max-width: 47.99em) {
  .breadcrumb-step {
    padding: 0 0 0 40px;
    font-size: 16px;
  }
}

@media (min-width: 48em) and (max-width: 79.99em) {
  .breadcrumb-step {
    padding: 0 0 0 60px;
  }
}

.breadcrumb-step-current,
.breadcrumb-step-complete {
  background-color: #3474BD;
}

.breadcrumb-step-current:after,
.breadcrumb-step-complete:after {
  border-left-color: #3474BD;
}

.breadcrumb-step-current .breadcrumb-step-index,
.breadcrumb-step-complete .breadcrumb-step-index {
  color: #3474BD;
}

.breadcrumb-step-current + .breadcrumb-step-complete,
.breadcrumb-step-current + .breadcrumb-step-current,
.breadcrumb-step-complete + .breadcrumb-step-complete,
.breadcrumb-step-complete + .breadcrumb-step-current {
  background-color: #3474BD;
}

.breadcrumb-step-current + .breadcrumb-step-complete:after,
.breadcrumb-step-current + .breadcrumb-step-current:after,
.breadcrumb-step-complete + .breadcrumb-step-complete:after,
.breadcrumb-step-complete + .breadcrumb-step-current:after {
  border-left-color: #3474BD;
}

.breadcrumb-step-current + .breadcrumb-step-complete:before,
.breadcrumb-step-current + .breadcrumb-step-current:before,
.breadcrumb-step-complete + .breadcrumb-step-complete:before,
.breadcrumb-step-complete + .breadcrumb-step-current:before {
  border-top-color: #3474BD;
  border-bottom-color: #3474BD;
}

@media (max-width: 79.99em) {
  .breadcrumb-step-current .breadcrumb-step-text {
    display: block;
  }
}

.breadcrumb-step-index {
  position: absolute;
  background-color: #fff;
  color: #EE5F1C;
  height: 34px;
  width: 20px;
  text-align: center;
  display: block;
  top: 8px;
  left: 20px;
  line-height: 35px;
}

.breadcrumb-step-index:before, .breadcrumb-step-index:after {
  content: '';
  display: block;
  height: 0;
  position: absolute;
  top: 0;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
}

.breadcrumb-step-index:before {
  left: -10px;
  border-right: 10px solid #fff;
}

.breadcrumb-step-index:after {
  right: -10px;
  border-left: 10px solid #fff;
}

@media (max-width: 47.99em) {
  .breadcrumb-step-index {
    height: 20px;
    width: 10px;
    font-size: 12px;
    line-height: 20px;
    top: 15px;
  }
  .breadcrumb-step-index:before, .breadcrumb-step-index:after {
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
  }
  .breadcrumb-step-index:before {
    left: -6px;
    border-right: 6px solid #fff;
  }
  .breadcrumb-step-index:after {
    right: -6px;
    border-left: 6px solid #fff;
  }
}

.field-required {
  color: #E5007A;
  font-weight: bold;
  font-size: 16px;
  padding-left: 10px;
  line-height: 1;
  display: inline-block;
}

.field-required-off {
  display: none;
}

.event-application-form .error,
.contact-form .error,
.bcrt-form .error,
article form .error {
  display: block;
  font-size: 14px;
  color: #EE5F1C;
  font-weight: bold;
  margin-bottom: 10px;
  line-height: 1;
  clear: left;
  float: left;
}

.event-application-form p,
.contact-form p,
.bcrt-form p,
article form p {
  margin-bottom: 10px;
}

.event-application-form fieldset,
.contact-form fieldset,
.bcrt-form fieldset,
article form fieldset {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 2px solid #EE5F1C;
  padding: 0 0 40px 0;
  margin: 0 0 30px 0;
}

.event-application-form fieldset > div:last-of-type,
.contact-form fieldset > div:last-of-type,
.bcrt-form fieldset > div:last-of-type,
article form fieldset > div:last-of-type {
  margin-bottom: 0;
}

.event-application-form legend,
.contact-form legend,
.bcrt-form legend,
article form legend {
  font-size: 30px;
  line-height: 1;
  display: block;
  clear: both;
  font-weight: bold;
  color: #EE5F1C;
  margin-bottom: 30px;
}

.event-application-form label,
.contact-form label,
.bcrt-form label,
article form label {
  display: block;
  clear: both;
  margin-bottom: 10px;
  cursor: pointer;
}

.event-application-form label a,
.contact-form label a,
.bcrt-form label a,
article form label a {
  color: #EE5F1C;
  transition: .5s color ease;
}

.event-application-form label a:hover,
.contact-form label a:hover,
.bcrt-form label a:hover,
article form label a:hover {
  color: #3474BD;
}

.event-application-form input[type=submit],
.contact-form input[type=submit],
.bcrt-form input[type=submit],
article form input[type=submit] {
  appearance: none;
}

.event-application-form input[type=number]::-webkit-inner-spin-button,
.event-application-form input[type=number]::-webkit-outer-spin-button,
.contact-form input[type=number]::-webkit-inner-spin-button,
.contact-form input[type=number]::-webkit-outer-spin-button,
.bcrt-form input[type=number]::-webkit-inner-spin-button,
.bcrt-form input[type=number]::-webkit-outer-spin-button,
article form input[type=number]::-webkit-inner-spin-button,
article form input[type=number]::-webkit-outer-spin-button {
  appearance: none;
}

.event-application-form input[type=number]::-webkit-inner-spin-button,
.event-application-form input[type=number]::-webkit-outer-spin-button,
.contact-form input[type=number]::-webkit-inner-spin-button,
.contact-form input[type=number]::-webkit-outer-spin-button,
.bcrt-form input[type=number]::-webkit-inner-spin-button,
.bcrt-form input[type=number]::-webkit-outer-spin-button,
article form input[type=number]::-webkit-inner-spin-button,
article form input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.event-application-form input[type=number],
.contact-form input[type=number],
.bcrt-form input[type=number],
article form input[type=number] {
  -moz-appearance: textfield;
}

.event-application-form input[type=text],
.event-application-form input[type=email],
.event-application-form input[type=number],
.event-application-form input[type=tel],
.event-application-form input[type=date],
.event-application-form textarea,
.contact-form input[type=text],
.contact-form input[type=email],
.contact-form input[type=number],
.contact-form input[type=tel],
.contact-form input[type=date],
.contact-form textarea,
.bcrt-form input[type=text],
.bcrt-form input[type=email],
.bcrt-form input[type=number],
.bcrt-form input[type=tel],
.bcrt-form input[type=date],
.bcrt-form textarea,
article form input[type=text],
article form input[type=email],
article form input[type=number],
article form input[type=tel],
article form input[type=date],
article form textarea {
  border: 2px solid #ccc;
  background-color: #fff;
  font-family: "proxima-nova", "Proxima Nova", "Helvetica Neue", helvetica, arial, verdana, sans-serif;
  color: #0A0A0A;
  font-size: 18px;
  transition: .5s border-color ease;
}

.event-application-form input[type=text]:-moz-placeholder,
.event-application-form input[type=email]:-moz-placeholder,
.event-application-form input[type=number]:-moz-placeholder,
.event-application-form input[type=tel]:-moz-placeholder,
.event-application-form input[type=date]:-moz-placeholder,
.event-application-form textarea:-moz-placeholder,
.contact-form input[type=text]:-moz-placeholder,
.contact-form input[type=email]:-moz-placeholder,
.contact-form input[type=number]:-moz-placeholder,
.contact-form input[type=tel]:-moz-placeholder,
.contact-form input[type=date]:-moz-placeholder,
.contact-form textarea:-moz-placeholder,
.bcrt-form input[type=text]:-moz-placeholder,
.bcrt-form input[type=email]:-moz-placeholder,
.bcrt-form input[type=number]:-moz-placeholder,
.bcrt-form input[type=tel]:-moz-placeholder,
.bcrt-form input[type=date]:-moz-placeholder,
.bcrt-form textarea:-moz-placeholder,
article form input[type=text]:-moz-placeholder,
article form input[type=email]:-moz-placeholder,
article form input[type=number]:-moz-placeholder,
article form input[type=tel]:-moz-placeholder,
article form input[type=date]:-moz-placeholder,
article form textarea:-moz-placeholder {
  color: #c5c5c5;
}

.event-application-form input[type=text]::-moz-placeholder,
.event-application-form input[type=email]::-moz-placeholder,
.event-application-form input[type=number]::-moz-placeholder,
.event-application-form input[type=tel]::-moz-placeholder,
.event-application-form input[type=date]::-moz-placeholder,
.event-application-form textarea::-moz-placeholder,
.contact-form input[type=text]::-moz-placeholder,
.contact-form input[type=email]::-moz-placeholder,
.contact-form input[type=number]::-moz-placeholder,
.contact-form input[type=tel]::-moz-placeholder,
.contact-form input[type=date]::-moz-placeholder,
.contact-form textarea::-moz-placeholder,
.bcrt-form input[type=text]::-moz-placeholder,
.bcrt-form input[type=email]::-moz-placeholder,
.bcrt-form input[type=number]::-moz-placeholder,
.bcrt-form input[type=tel]::-moz-placeholder,
.bcrt-form input[type=date]::-moz-placeholder,
.bcrt-form textarea::-moz-placeholder,
article form input[type=text]::-moz-placeholder,
article form input[type=email]::-moz-placeholder,
article form input[type=number]::-moz-placeholder,
article form input[type=tel]::-moz-placeholder,
article form input[type=date]::-moz-placeholder,
article form textarea::-moz-placeholder {
  color: #c5c5c5;
}

.event-application-form input[type=text]:-ms-input-placeholder,
.event-application-form input[type=email]:-ms-input-placeholder,
.event-application-form input[type=number]:-ms-input-placeholder,
.event-application-form input[type=tel]:-ms-input-placeholder,
.event-application-form input[type=date]:-ms-input-placeholder,
.event-application-form textarea:-ms-input-placeholder,
.contact-form input[type=text]:-ms-input-placeholder,
.contact-form input[type=email]:-ms-input-placeholder,
.contact-form input[type=number]:-ms-input-placeholder,
.contact-form input[type=tel]:-ms-input-placeholder,
.contact-form input[type=date]:-ms-input-placeholder,
.contact-form textarea:-ms-input-placeholder,
.bcrt-form input[type=text]:-ms-input-placeholder,
.bcrt-form input[type=email]:-ms-input-placeholder,
.bcrt-form input[type=number]:-ms-input-placeholder,
.bcrt-form input[type=tel]:-ms-input-placeholder,
.bcrt-form input[type=date]:-ms-input-placeholder,
.bcrt-form textarea:-ms-input-placeholder,
article form input[type=text]:-ms-input-placeholder,
article form input[type=email]:-ms-input-placeholder,
article form input[type=number]:-ms-input-placeholder,
article form input[type=tel]:-ms-input-placeholder,
article form input[type=date]:-ms-input-placeholder,
article form textarea:-ms-input-placeholder {
  color: #c5c5c5;
}

.event-application-form input[type=text]::-webkit-input-placeholder,
.event-application-form input[type=email]::-webkit-input-placeholder,
.event-application-form input[type=number]::-webkit-input-placeholder,
.event-application-form input[type=tel]::-webkit-input-placeholder,
.event-application-form input[type=date]::-webkit-input-placeholder,
.event-application-form textarea::-webkit-input-placeholder,
.contact-form input[type=text]::-webkit-input-placeholder,
.contact-form input[type=email]::-webkit-input-placeholder,
.contact-form input[type=number]::-webkit-input-placeholder,
.contact-form input[type=tel]::-webkit-input-placeholder,
.contact-form input[type=date]::-webkit-input-placeholder,
.contact-form textarea::-webkit-input-placeholder,
.bcrt-form input[type=text]::-webkit-input-placeholder,
.bcrt-form input[type=email]::-webkit-input-placeholder,
.bcrt-form input[type=number]::-webkit-input-placeholder,
.bcrt-form input[type=tel]::-webkit-input-placeholder,
.bcrt-form input[type=date]::-webkit-input-placeholder,
.bcrt-form textarea::-webkit-input-placeholder,
article form input[type=text]::-webkit-input-placeholder,
article form input[type=email]::-webkit-input-placeholder,
article form input[type=number]::-webkit-input-placeholder,
article form input[type=tel]::-webkit-input-placeholder,
article form input[type=date]::-webkit-input-placeholder,
article form textarea::-webkit-input-placeholder {
  color: #c5c5c5;
}

.event-application-form input[type=text]:focus,
.event-application-form input[type=email]:focus,
.event-application-form input[type=number]:focus,
.event-application-form input[type=tel]:focus,
.event-application-form input[type=date]:focus,
.event-application-form textarea:focus,
.contact-form input[type=text]:focus,
.contact-form input[type=email]:focus,
.contact-form input[type=number]:focus,
.contact-form input[type=tel]:focus,
.contact-form input[type=date]:focus,
.contact-form textarea:focus,
.bcrt-form input[type=text]:focus,
.bcrt-form input[type=email]:focus,
.bcrt-form input[type=number]:focus,
.bcrt-form input[type=tel]:focus,
.bcrt-form input[type=date]:focus,
.bcrt-form textarea:focus,
article form input[type=text]:focus,
article form input[type=email]:focus,
article form input[type=number]:focus,
article form input[type=tel]:focus,
article form input[type=date]:focus,
article form textarea:focus {
  border-color: #3474BD;
  outline: 0;
}

.event-application-form input[type=text]:focus:-moz-placeholder,
.event-application-form input[type=email]:focus:-moz-placeholder,
.event-application-form input[type=number]:focus:-moz-placeholder,
.event-application-form input[type=tel]:focus:-moz-placeholder,
.event-application-form input[type=date]:focus:-moz-placeholder,
.event-application-form textarea:focus:-moz-placeholder,
.contact-form input[type=text]:focus:-moz-placeholder,
.contact-form input[type=email]:focus:-moz-placeholder,
.contact-form input[type=number]:focus:-moz-placeholder,
.contact-form input[type=tel]:focus:-moz-placeholder,
.contact-form input[type=date]:focus:-moz-placeholder,
.contact-form textarea:focus:-moz-placeholder,
.bcrt-form input[type=text]:focus:-moz-placeholder,
.bcrt-form input[type=email]:focus:-moz-placeholder,
.bcrt-form input[type=number]:focus:-moz-placeholder,
.bcrt-form input[type=tel]:focus:-moz-placeholder,
.bcrt-form input[type=date]:focus:-moz-placeholder,
.bcrt-form textarea:focus:-moz-placeholder,
article form input[type=text]:focus:-moz-placeholder,
article form input[type=email]:focus:-moz-placeholder,
article form input[type=number]:focus:-moz-placeholder,
article form input[type=tel]:focus:-moz-placeholder,
article form input[type=date]:focus:-moz-placeholder,
article form textarea:focus:-moz-placeholder {
  color: #fff;
}

.event-application-form input[type=text]:focus::-moz-placeholder,
.event-application-form input[type=email]:focus::-moz-placeholder,
.event-application-form input[type=number]:focus::-moz-placeholder,
.event-application-form input[type=tel]:focus::-moz-placeholder,
.event-application-form input[type=date]:focus::-moz-placeholder,
.event-application-form textarea:focus::-moz-placeholder,
.contact-form input[type=text]:focus::-moz-placeholder,
.contact-form input[type=email]:focus::-moz-placeholder,
.contact-form input[type=number]:focus::-moz-placeholder,
.contact-form input[type=tel]:focus::-moz-placeholder,
.contact-form input[type=date]:focus::-moz-placeholder,
.contact-form textarea:focus::-moz-placeholder,
.bcrt-form input[type=text]:focus::-moz-placeholder,
.bcrt-form input[type=email]:focus::-moz-placeholder,
.bcrt-form input[type=number]:focus::-moz-placeholder,
.bcrt-form input[type=tel]:focus::-moz-placeholder,
.bcrt-form input[type=date]:focus::-moz-placeholder,
.bcrt-form textarea:focus::-moz-placeholder,
article form input[type=text]:focus::-moz-placeholder,
article form input[type=email]:focus::-moz-placeholder,
article form input[type=number]:focus::-moz-placeholder,
article form input[type=tel]:focus::-moz-placeholder,
article form input[type=date]:focus::-moz-placeholder,
article form textarea:focus::-moz-placeholder {
  color: #fff;
}

.event-application-form input[type=text]:focus:-ms-input-placeholder,
.event-application-form input[type=email]:focus:-ms-input-placeholder,
.event-application-form input[type=number]:focus:-ms-input-placeholder,
.event-application-form input[type=tel]:focus:-ms-input-placeholder,
.event-application-form input[type=date]:focus:-ms-input-placeholder,
.event-application-form textarea:focus:-ms-input-placeholder,
.contact-form input[type=text]:focus:-ms-input-placeholder,
.contact-form input[type=email]:focus:-ms-input-placeholder,
.contact-form input[type=number]:focus:-ms-input-placeholder,
.contact-form input[type=tel]:focus:-ms-input-placeholder,
.contact-form input[type=date]:focus:-ms-input-placeholder,
.contact-form textarea:focus:-ms-input-placeholder,
.bcrt-form input[type=text]:focus:-ms-input-placeholder,
.bcrt-form input[type=email]:focus:-ms-input-placeholder,
.bcrt-form input[type=number]:focus:-ms-input-placeholder,
.bcrt-form input[type=tel]:focus:-ms-input-placeholder,
.bcrt-form input[type=date]:focus:-ms-input-placeholder,
.bcrt-form textarea:focus:-ms-input-placeholder,
article form input[type=text]:focus:-ms-input-placeholder,
article form input[type=email]:focus:-ms-input-placeholder,
article form input[type=number]:focus:-ms-input-placeholder,
article form input[type=tel]:focus:-ms-input-placeholder,
article form input[type=date]:focus:-ms-input-placeholder,
article form textarea:focus:-ms-input-placeholder {
  color: #fff;
}

.event-application-form input[type=text]:focus::-webkit-input-placeholder,
.event-application-form input[type=email]:focus::-webkit-input-placeholder,
.event-application-form input[type=number]:focus::-webkit-input-placeholder,
.event-application-form input[type=tel]:focus::-webkit-input-placeholder,
.event-application-form input[type=date]:focus::-webkit-input-placeholder,
.event-application-form textarea:focus::-webkit-input-placeholder,
.contact-form input[type=text]:focus::-webkit-input-placeholder,
.contact-form input[type=email]:focus::-webkit-input-placeholder,
.contact-form input[type=number]:focus::-webkit-input-placeholder,
.contact-form input[type=tel]:focus::-webkit-input-placeholder,
.contact-form input[type=date]:focus::-webkit-input-placeholder,
.contact-form textarea:focus::-webkit-input-placeholder,
.bcrt-form input[type=text]:focus::-webkit-input-placeholder,
.bcrt-form input[type=email]:focus::-webkit-input-placeholder,
.bcrt-form input[type=number]:focus::-webkit-input-placeholder,
.bcrt-form input[type=tel]:focus::-webkit-input-placeholder,
.bcrt-form input[type=date]:focus::-webkit-input-placeholder,
.bcrt-form textarea:focus::-webkit-input-placeholder,
article form input[type=text]:focus::-webkit-input-placeholder,
article form input[type=email]:focus::-webkit-input-placeholder,
article form input[type=number]:focus::-webkit-input-placeholder,
article form input[type=tel]:focus::-webkit-input-placeholder,
article form input[type=date]:focus::-webkit-input-placeholder,
article form textarea:focus::-webkit-input-placeholder {
  color: #fff;
}

.event-application-form input[type=text],
.event-application-form input[type=email],
.event-application-form input[type=number],
.event-application-form input[type=tel],
.event-application-form input[type=date],
.contact-form input[type=text],
.contact-form input[type=email],
.contact-form input[type=number],
.contact-form input[type=tel],
.contact-form input[type=date],
.bcrt-form input[type=text],
.bcrt-form input[type=email],
.bcrt-form input[type=number],
.bcrt-form input[type=tel],
.bcrt-form input[type=date],
article form input[type=text],
article form input[type=email],
article form input[type=number],
article form input[type=tel],
article form input[type=date] {
  line-height: 46px;
  padding: 0 15px;
  height: 50px;
}

.event-application-form textarea,
.contact-form textarea,
.bcrt-form textarea,
article form textarea {
  width: 100%;
  height: 150px;
  padding: 15px;
}

.event-application-form select,
.contact-form select,
.bcrt-form select,
article form select {
  appearance: none;
  background: transparent;
  border: 0;
  border-radius: 0;
  width: 100%;
  height: 100%;
  line-height: 20px;
}

.event-application-form .select-wrapper,
.contact-form .select-wrapper,
.bcrt-form .select-wrapper,
article form .select-wrapper {
  height: 50px;
  border: 2px solid #ccc;
  padding: 0 15px;
  background-color: #F9F9F9;
  position: relative;
}

.event-application-form .select-wrapper:before, .event-application-form .select-wrapper:after,
.contact-form .select-wrapper:before,
.contact-form .select-wrapper:after,
.bcrt-form .select-wrapper:before,
.bcrt-form .select-wrapper:after,
article form .select-wrapper:before,
article form .select-wrapper:after {
  content: '';
  display: block;
  position: absolute;
  pointer-events: none;
}

.event-application-form .select-wrapper:before,
.contact-form .select-wrapper:before,
.bcrt-form .select-wrapper:before,
article form .select-wrapper:before {
  top: 0;
  right: 0;
  width: 35px;
  height: 46px;
  background-color: #F9F9F9;
}

.event-application-form .select-wrapper:after,
.contact-form .select-wrapper:after,
.bcrt-form .select-wrapper:after,
article form .select-wrapper:after {
  top: 18px;
  right: 17px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 10px solid #0A0A0A;
}

@media (max-width: 29.99em) {
  .event-application-form legend,
  .contact-form legend,
  .bcrt-form legend,
  article form legend {
    font-size: 22px;
  }
}

.bcrt-formalicious.bcrt-form {
  overflow: hidden;
}

.bcrt-formalicious.bcrt-form fieldset:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0;
}

.bcrt-formalicious.bcrt-form .link-button,
.bcrt-formalicious.bcrt-form .link-button-wrapper {
  float: right;
  clear: right;
}

.bcrt-formalicious.bcrt-form .link-button-wrapper:before, .bcrt-formalicious.bcrt-form .link-button-wrapper:after {
  content: ' ';
  display: table;
}

.bcrt-formalicious.bcrt-form .link-button-wrapper:after {
  clear: both;
}

.bcrt-formalicious.bcrt-form .link-button-back {
  float: left;
  clear: left;
}

.bcrt-form-go-pay legend {
  font-weight: normal;
  color: #0A0A0A;
  line-height: 52px;
}

@media (max-width: 29.99em) {
  .bcrt-form-go-pay legend {
    line-height: 1.1;
  }
}

.bcrt-form-go-pay fieldset {
  padding-bottom: 0;
  border-bottom: 0;
}

.bcrt-form-go-pay .fee-amount {
  font-weight: bold;
  color: #EE5F1C;
}

.bcrt-form-go-pay .link-button {
  clear: left;
}

.form-field-block {
  margin-bottom: 30px;
}

.form-field-block:before, .form-field-block:after {
  content: ' ';
  display: table;
}

.form-field-block:after {
  clear: both;
}

.form-field-block.error-state {
  background-color: #EBEBEB;
  border: 2px solid #EE5F1C;
}

.form-field-block small {
  display: block;
  float: left;
  clear: left;
  margin-top: 5px;
  color: #ccc;
}

.form-field-block > input,
.form-field-block > label {
  width: 48.451%;
  float: left;
  clear: left;
}

.form-field-block > div {
  float: left;
  width: 48.451%;
}

.form-field-block > div:nth-child(2n + 1) {
  margin-right: 2.817%;
}

.form-field-block > div > input,
.form-field-block > div > label {
  width: 100%;
}

.form-field-block > .form-field-date-short,
.form-field-block > .form-field-date-long {
  float: left;
  clear: none;
  margin-right: 2.817%;
}

.form-field-block > .form-field-date-short:first-of-type,
.form-field-block > .form-field-date-long:first-of-type {
  clear: left;
}

.form-field-block > .form-field-date-short:last-of-type,
.form-field-block > .form-field-date-long:last-of-type {
  margin-right: 0;
}

.form-field-block > .form-field-date-short {
  width: 64px;
}

.form-field-block > .form-field-date-long {
  width: 80px;
}

@media (max-width: 29.99em) {
  .form-field-block > div {
    width: 100%;
    margin-bottom: 20px;
  }
  .form-field-block > div:nth-child(2n + 1) {
    margin-right: 0;
  }
  .form-field-block > input,
  .form-field-block > label {
    width: 100%;
  }
  .form-field-block .invisible {
    visibility: visible;
  }
}

.form-field-block-long {
  width: 100%;
  float: none;
}

.form-field-block .form-field-block-long > input,
.form-field-block .form-field-block-long > label,
.form-field-block .form-field-block-long > textarea,
.form-field-block .form-field-block-long > div {
  width: 100%;
  float: none;
}

.form-field-block-long > input,
.form-field-block-long > label,
.form-field-block-long > textarea,
.form-field-block-long > div {
  width: 100%;
  float: none;
}

.form-field-block-stacked > div {
  width: 100%;
  float: none;
  margin-bottom: 20px;
}

.form-field-block-stacked > div input {
  width: 48.451%;
}

@media (max-width: 29.99em) {
  .form-field-block-stacked > div input {
    width: 100%;
  }
}

.form-field-block-checkbox,
.form-field-block-radio {
  position: relative;
  margin-bottom: 20px;
  clear: left;
}

.form-field-block-checkbox:before, .form-field-block-checkbox:after,
.form-field-block-radio:before,
.form-field-block-radio:after {
  content: ' ';
  display: table;
}

.form-field-block-checkbox:after,
.form-field-block-radio:after {
  clear: both;
}

.form-field-block-checkbox input[type=checkbox],
.form-field-block-checkbox input[type=radio],
.form-field-block-radio input[type=checkbox],
.form-field-block-radio input[type=radio] {
  width: 20px;
  height: 20px;
  overflow: hidden;
  appearance: none;
  border: 0;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
}

.form-field-block-checkbox input[type=checkbox]:focus,
.form-field-block-checkbox input[type=radio]:focus,
.form-field-block-radio input[type=checkbox]:focus,
.form-field-block-radio input[type=radio]:focus {
  outline: 0;
}

.form-field-block-checkbox input[type=checkbox]:focus + label:before,
.form-field-block-checkbox input[type=radio]:focus + label:before,
.form-field-block-radio input[type=checkbox]:focus + label:before,
.form-field-block-radio input[type=radio]:focus + label:before {
  border-color: #3474BD;
}

.form-field-block-checkbox input[type=checkbox]:checked,
.form-field-block-checkbox input[type=radio]:checked,
.form-field-block-radio input[type=checkbox]:checked,
.form-field-block-radio input[type=radio]:checked {
  outline: 0;
}

.form-field-block-checkbox input[type=checkbox]:checked + label:before,
.form-field-block-checkbox input[type=radio]:checked + label:before,
.form-field-block-radio input[type=checkbox]:checked + label:before,
.form-field-block-radio input[type=radio]:checked + label:before {
  border-color: #3474BD;
}

.form-field-block-checkbox input[type=checkbox]:checked + label:after,
.form-field-block-checkbox input[type=radio]:checked + label:after,
.form-field-block-radio input[type=checkbox]:checked + label:after,
.form-field-block-radio input[type=radio]:checked + label:after {
  opacity: 1;
}

.form-field-block-checkbox > label,
.form-field-block-radio > label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  line-height: 27px;
  margin-bottom: 0;
}

.form-field-block-checkbox > label:before, .form-field-block-checkbox > label:after,
.form-field-block-radio > label:before,
.form-field-block-radio > label:after {
  display: block;
  position: absolute;
}

.form-field-block-checkbox > label:before,
.form-field-block-radio > label:before {
  content: '';
  border: 2px solid #ccc;
  background-color: #fff;
  width: 20px;
  height: 20px;
  transition: .5s border-color ease;
  left: 0;
  top: 2px;
}

.form-field-block-checkbox > label:after,
.form-field-block-radio > label:after {
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  text-align: center;
  line-height: 20px;
  transition: .5s width ease, .5s height ease, .5s opacity ease;
}

.form-field-block-checkbox > label.field-group-label,
.form-field-block-radio > label.field-group-label {
  padding-left: 0;
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
  cursor: default;
}

.form-field-block-checkbox > label.field-group-label:before, .form-field-block-checkbox > label.field-group-label:after,
.form-field-block-radio > label.field-group-label:before,
.form-field-block-radio > label.field-group-label:after {
  display: none;
}

.form-field-block-checkbox input[type=checkbox] {
  width: 20px;
  height: 20px;
  overflow: hidden;
  appearance: none;
  border: 0;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
}

.form-field-block-checkbox input[type=checkbox]:focus {
  outline: 0;
}

.form-field-block-checkbox input[type=checkbox]:focus + label:before {
  border-color: #3474BD;
}

.form-field-block-checkbox input[type=checkbox]:checked {
  outline: 0;
}

.form-field-block-checkbox input[type=checkbox]:checked + label:before {
  border-color: #3474BD;
}

.form-field-block-checkbox input[type=checkbox]:checked + label:after {
  opacity: 1;
  width: 20px;
  height: 20px;
}

.form-field-block-checkbox > label:before, .form-field-block-checkbox > label:after {
  left: 0;
  top: 2px;
}

.form-field-block-checkbox > label:after {
  content: '✔';
  color: #EE5F1C;
}

.form-field-block-radio input[type=radio]:checked {
  outline: 0;
}

.form-field-block-radio input[type=radio]:checked + label:before {
  border-color: #3474BD;
}

.form-field-block-radio input[type=radio]:checked + label:after {
  opacity: 1;
}

.form-field-block-radio > label:before, .form-field-block-radio > label:after {
  left: 0;
  top: 2px;
}

.form-field-block-radio > label:before {
  border-radius: 50%;
}

.form-field-block-radio > label:after {
  content: '';
  background-color: #EE5F1C;
  border-radius: 50%;
  top: 7px;
  left: 5px;
  width: 10px;
  height: 10px;
}

.form-block-information {
  margin-bottom: 30px;
  font-size: 22px;
  font-weight: bold;
}

.form-block-information:before, .form-block-information:after {
  content: ' ';
  display: table;
}

.form-block-information:after {
  clear: both;
}

.form-block-information > span {
  display: block;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 18px;
  font-weight: normal;
}

.form-block-information ul {
  font-weight: normal;
  font-size: 16px;
  margin: 0;
}

.form-field-block-names > div {
  margin-bottom: 20px;
  display: none;
}

.form-field-block-names > div.active {
  display: block;
}

.currency-input-wrapper {
  position: relative;
  height: 50px;
}

.currency-input-wrapper:before {
  content: '£';
  position: absolute;
  top: 14px;
  left: 15px;
  font-size: 18px;
}

.currency-input-wrapper > input[type=number] {
  padding-left: 30px;
  width: 100%;
}

.important-information {
  background-color: #EBEBEB;
  color: #fff;
  padding: 30px;
  margin-bottom: 40px;
}

.important-information h3 {
  color: #EE5F1C;
  font-size: 30px;
  margin-top: 0;
}

.important-information p {
  color: #0A0A0A;
  margin-bottom: 0;
}

#adultnames,
#childnames {
  display: none;
}

#adultnames.active,
#childnames.active {
  display: block;
}

#i-have-had,
#they-have-had,
#lost-someone,
#in-memory-block,
#special-fund-block {
  display: none;
}

#i-have-had.active,
#they-have-had.active,
#lost-someone.active,
#in-memory-block.active,
#special-fund-block.active {
  display: block;
}

@media print {
  @page {
    margin: 2cm;
  }
  body {
    color: #000;
    background-color: #fff;
    font-size: 13pt;
    line-height: 1.5;
  }
  .site-search,
  .site-nav-fake,
  .site-nav,
  .old-browser,
  .site-footer-nav,
  .site-social-links,
  .site-supporters,
  .logo-strapline,
  .secondary-nav,
  .content-share,
  .content-filters,
  .page-header-image-contain,
  .content-aside,
  .donate-feature-block {
    display: none;
  }
  .site-logo {
    text-indent: 0;
    white-space: wrap;
    overflow: visible;
    width: auto;
    height: auto;
    float: none;
    clear: both;
    background-image: none;
    font-size: 20pt;
    color: #000;
    font-weight: bold;
    padding-bottom: .5cm;
    border-bottom: 1px solid #000;
    margin-bottom: 1cm;
  }
  .page-header {
    color: #000;
    height: auto;
    background-color: #fff;
    padding-bottom: .5cm;
    border-bottom: 1px solid #000;
    margin-bottom: 1cm;
  }
  .page-header .contain {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .page-header h1,
  .page-header p {
    width: 100%;
    float: none;
    clear: both;
  }
  .page-header p {
    font-size: 15pt;
  }
  .content-accordion-section {
    border-top: 0;
  }
  .content-accordion-section:last-of-type {
    border-bottom: 0;
  }
  .content-accordion-text {
    padding: 0;
    margin: 0 0 .5cm;
  }
  .content-accordion-text[aria-hidden="true"] {
    visibility: visible !important;
    height: auto !important;
  }
  .content-accordion-toggle {
    font-size: 15pt;
    line-height: 1.5;
    margin: 0 0 .25cm;
    padding: 0;
    color: #000;
  }
  .content-accordion-toggle:before {
    display: none;
  }
  .content-accordion-toggle.active:before {
    display: none;
  }
  .content {
    float: none;
    clear: both;
    width: 100%;
    padding-top: 0;
    margin-bottom: .5cm;
  }
  .content-aside {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 1cm;
  }
  .content-aside.content-aside-project {
    display: block;
    width: 100%;
    clear: both;
    margin-bottom: .5cm;
  }
  .content-aside.content-aside-project > .link-button:only-of-type {
    display: none;
  }
  .project-information {
    padding: 0;
    border: 0;
    margin-bottom: 0;
  }
  .project-lead-researcher {
    position: static;
    top: auto;
    left: auto;
    margin-bottom: .5cm;
  }
  .project-lead-researcher:before, .project-lead-researcher:after {
    display: none;
  }
  .project-lead-researcher img {
    position: static;
    top: auto;
    left: auto;
    transform: translate(0, 0);
    min-width: 0;
    max-width: 100%;
  }
  .content-aside.content-aside-event {
    display: block;
    width: 100%;
    clear: both;
    margin-bottom: .5cm;
  }
  .content-aside.content-aside-event > .link-button {
    display: none;
  }
  .event-logo {
    clear: both;
    margin-top: 0;
    margin-bottom: .5cm;
  }
  .event-information ul {
    margin-bottom: 0;
  }
  .event-information li {
    display: inline-block;
    margin-right: 1cm;
    margin-bottom: 1cm;
  }
}

.shineastar {
  background: #3474BD;
  width: 100%;
  overflow-x: hidden;
  /* Banner section */
  /* All stars section */
  /* Donate section */
  /* Form section */
  /* Custom keyframes */
  /* Responsive */
  /*
*
*
*

START
Custom file upload CSS: https://tympanus.net/Tutorials/CustomFileInputs/

*
*
*
*/
  /* style 6 */
  /*

END
Custom file upload CSS: https://tympanus.net/Tutorials/CustomFileInputs/

*/
  /*

START
Modified from: https://codepen.io/andreasstorm/pen/yjLGGN

*/
  /*
*
*
*

END
Modified from: https://codepen.io/andreasstorm/pen/yjLGGN

*
*
*
*/
  /*
    *
    *
    *

    START
    https://codepen.io/Mattgh9152/pen/PxpBxN

    *
    *
    *
    */
  /*
*
*
*

END
https://codepen.io/Mattgh9152/pen/PxpBxN

*
*
*
*/
  /* Matched donation specific closing tag */
}

.shineastar .shineastar-logo {
  width: 1366px;
  position: relative;
  max-width: 100%;
  height: 100vh;
  background-image: url(../img/shineastar/home-star-left.png), url(../img/shineastar/home-star-right.png);
  margin: 0 auto;
  background-position: 0 -40%, 100% -35%;
  background-size: 340px, 340px;
  background-repeat: no-repeat;
  transition: background-position .4s ease-in-out, background-size .4s ease-out;
}

.shineastar .shineastar-logo .main-logo {
  opacity: 0;
  transition: opacity .4s ease-in-out;
  position: fixed;
  top: -30px;
  left: 50%;
  transform: translate(-50%);
  width: 250px;
  z-index: 999;
}

.shineastar .shineastar-logo .shineastar-logotitle-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.shineastar .shineastar-logo .shineastar-logo-container {
  background-image: url("../img/shineastar/shineastar-logo.png");
  transition: background-position .4s ease;
  height: 60%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
  z-index: 299;
}

.shineastar .banner-text {
  width: 100%;
  max-width: 550px;
  margin: 0 auto;
  text-align: center;
  padding-top: 0;
  padding-bottom: 25px;
}

.shineastar .banner-text h1 {
  font-size: 36px;
  line-height: 44px;
  padding: 0 10px;
  position: relative;
  z-index: 10;
}

@media (max-width: 47.99em) {
  .shineastar .banner-text h1 {
    margin-top: 0;
    font-size: 24px;
    line-height: 31px;
  }
}

.shineastar .banner-text a {
  clear: both;
}

.shineastar .banner-text a.seeall {
  display: block;
  text-decoration: underline;
}

.shineastar .banner-text .big-button {
  display: inline-block;
  margin: 35px 0;
  box-shadow: 0 0 10px 0 rgba(255, 242, 95, 0.75);
}

@media (max-width: 47.99em) {
  .shineastar .banner-text .big-button {
    margin: 15px 0;
    font-size: 21px;
    padding: 15px 35px;
  }
}

.shineastar .allstar {
  display: flex;
  position: relative;
  background-image: url("../img/shineastar/shineastar-background-img.jpg");
  background-size: cover;
  overflow: hidden;
  background-color: #3474BD;
  min-height: 920px;
}

.shineastar .allstar .x2020x__stars {
  display: grid;
  grid-template-columns: repeat(auto-fill, var(--vwThird));
  grid-template-rows: 1fr 1fr 1fr;
  grid-auto-flow: column;
}

@media (min-width: 48em) {
  .shineastar .allstar .x2020x__stars {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-auto-flow: column;
  }
}

.shineastar .allstar .x2020x__stars--star {
  width: 125px;
  height: 125px;
  justify-self: center;
  cursor: pointer;
  transform: translate3d(0, 0, 0);
}

@media (min-width: 30em) and (max-width: 47.99em) {
  .shineastar .allstar .x2020x__stars--star {
    width: 150px;
    height: 150px;
  }
}

@media (max-width: 47.99em) {
  .shineastar .allstar .x2020x__stars--star:nth-child(5n+1) {
    grid-column: auto;
    grid-row: 1;
  }
  .shineastar .allstar .x2020x__stars--star:nth-child(5n+2) {
    grid-column: auto;
    grid-row: 3;
  }
  .shineastar .allstar .x2020x__stars--star:nth-child(5n+3) {
    grid-column: auto;
    grid-row: 2;
  }
  .shineastar .allstar .x2020x__stars--star:nth-child(5n+4) {
    grid-column: auto;
    grid-row: 1;
  }
  .shineastar .allstar .x2020x__stars--star:nth-child(5n+5) {
    grid-column: auto;
    grid-row: 3;
  }
}

@media (min-width: 48em) {
  .shineastar .allstar .x2020x__stars--star {
    width: 175px;
    height: 175px;
  }
  .shineastar .allstar .x2020x__stars--star:nth-child(4n+1) {
    grid-column: auto;
    grid-row: 1;
  }
  .shineastar .allstar .x2020x__stars--star:nth-child(4n+2) {
    grid-column: auto;
    grid-row: 3;
  }
  .shineastar .allstar .x2020x__stars--star:nth-child(4n+3) {
    grid-column: auto;
    grid-row: 2;
    margin-top: -15px;
  }
  .shineastar .allstar .x2020x__stars--star:nth-child(4n+4) {
    grid-column: auto;
    grid-row: 4;
    margin-top: -15px;
  }
}

.shineastar .allstar .x2020x__stars--star svg {
  max-width: 100%;
  max-height: 100%;
}

@media (max-width: 47.99em) {
  .shineastar .allstar {
    margin-top: -60px;
  }
}

.shineastar .allstar:before {
  content: "";
  display: block;
  width: 100%;
  height: 300px;
  position: absolute;
  top: -2px;
  left: 0;
  z-index: 1;
  background-image: linear-gradient(180deg, rgba(52, 116, 189, 0) 3%, rgba(52, 116, 189, 0.06) 8%, rgba(52, 116, 189, 0.12) 14%, rgba(52, 116, 189, 0.19) 21%, rgba(52, 116, 189, 0.26) 28%, rgba(52, 116, 189, 0.34) 36%, rgba(52, 116, 189, 0.42) 43%, rgba(52, 116, 189, 0.5) 51%, rgba(52, 116, 189, 0.58) 59%, rgba(52, 116, 189, 0.66) 67%, rgba(52, 116, 189, 0.74) 74%, rgba(52, 116, 189, 0.81) 81%, rgba(52, 116, 189, 0.88) 88%, rgba(52, 116, 189, 0.94) 94%, #3474BD 100%);
  transform: rotate(180deg);
}

.shineastar .allstar .allstars-wrapper {
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.shineastar .allstar .allstars-header {
  order: 100;
  margin-bottom: -43px;
  margin-top: 43px;
}

@media (max-width: 47.99em) {
  .shineastar .allstar .allstars-header {
    margin-top: 20px;
    margin-bottom: 30px;
  }
}

.shineastar .allstar .currentSearch,
.shineastar .allstar .noResults {
  display: none;
  transform: translateY(-50%);
  color: #FAC52C;
  margin-bottom: 10px;
  text-align: center;
}

.shineastar .allstar.no-results .noResults {
  display: block;
}

.shineastar .allstar.filtered .currentSearch {
  display: block;
}

.shineastar .allstar.filtered .allstars-inside {
  display: flex;
}

.shineastar .allstar.filtered .large-image {
  max-width: 650px;
}

@media (max-width: 47.99em) {
  .shineastar .allstar.filtered .large-image {
    max-width: 85vw;
  }
}

.shineastar .allstar.filtered .column .star-image-container {
  width: 275px;
}

@media (max-width: 47.99em) {
  .shineastar .allstar.filtered .column .star-image-container {
    max-width: 175px;
  }
}

.shineastar .allstar.filtered .column:nth-child(2n + 2), .shineastar .allstar.filtered .column:nth-child(2n + 3), .shineastar .allstar.filtered .column-top-image, .shineastar .allstar.filtered .column-bottom-image {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.shineastar .allstar .button-container {
  position: absolute;
  height: 130px;
  width: 150px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.shineastar .allstar .button-container.left {
  left: 0;
}

.shineastar .allstar .button-container.right {
  right: 0;
}

.shineastar .allstar .button-container .button-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 130px;
  background-image: url("../img/shineastar/chevron-left-right-nobg.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-color: rgba(11, 49, 87, 0.6);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  border: 0;
  transition: background-color .4s ease, opacity .4s ease;
  cursor: pointer;
}

.shineastar .allstar .button-container .button-nav:hover {
  background-color: #0b3157;
}

.shineastar .allstar .button-container .button-nav.disabled {
  opacity: 0;
  pointer-events: none;
}

.shineastar .allstar .button-container .button-nav.right {
  left: auto;
  right: 0;
  transform: rotate(180deg) translate(0, 50%);
}

.shineastar .allstar .allstars-inside {
  margin: auto;
  padding: 0 25px 160px 0;
  max-height: 100%;
  transform: translateX(0%);
  transition: .75s ease transform;
  text-align: center;
  align-items: center;
}

@media (min-width: 48em) {
  .shineastar .allstar .allstars-inside {
    display: flex;
    padding: 85px 0 105px;
  }
}

.shineastar .allstar .allstars-inside img {
  max-width: 100%;
}

.shineastar .allstar .allstars-inside .column {
  position: relative;
  min-height: calc(100% - 150px);
}

.shineastar .allstar .allstars-inside .column .star-image-container {
  min-width: 225px;
  justify-content: center;
  align-items: center;
}

@media (max-width: 47.99em) {
  .shineastar .allstar .allstars-inside .column .star-image-container {
    min-width: 150px;
  }
}

.shineastar .allstar .allstars-inside .column .star-image-container .name {
  width: 100%;
}

.shineastar .allstar .allstars-inside .column .star-image-container svg {
  width: 225px;
  height: 225px;
}

@media (max-width: 47.99em) {
  .shineastar .allstar .allstars-inside .column .star-image-container svg {
    width: 150px;
    height: 150px;
  }
}

.shineastar .allstar .allstars-inside .column:nth-child(2n + 2) {
  bottom: 50px;
}

@media (max-width: 47.99em) {
  .shineastar .allstar .allstars-inside .column:nth-child(2n + 2) {
    bottom: 0;
  }
}

.shineastar .allstar .allstars-inside .column:nth-child(2n + 3) {
  top: 75px;
}

@media (max-width: 47.99em) {
  .shineastar .allstar .allstars-inside .column:nth-child(2n + 3) {
    top: 65px;
  }
}

.shineastar .allstar .allstars-inside .column-top-image {
  padding-bottom: 100px;
}

@media (max-width: 47.99em) {
  .shineastar .allstar .allstars-inside .column-top-image {
    padding-bottom: 35px;
  }
}

.shineastar .allstar .allstars-inside .column-bottom-image {
  padding-bottom: 125px;
}

@media (max-width: 47.99em) {
  .shineastar .allstar .allstars-inside .column-bottom-image {
    padding-bottom: 0;
  }
}

.shineastar .allstar .allstars-inside .large-image {
  padding-left: 20px;
  width: 525px;
  display: none;
}

@media (min-width: 48em) {
  .shineastar .allstar .allstars-inside .large-image {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}

.shineastar .allstar .allstars-inside .large-image svg {
  max-width: 575px;
  height: auto;
}

.shineastar .allstar .allstars-inside .large-image svg {
  display: none;
}

.shineastar .allstar .allstars-inside .large-image span.name {
  display: none;
  max-width: 575px;
}

.shineastar .allstar .allstars-inside svg,
.shineastar .allstar .allstars-inside img,
.shineastar .allstar .allstars-inside .name {
  transition: .4s ease opacity;
}

.shineastar .allstar .search-container {
  width: fit-content;
  border: 2px solid #FAC52C;
  box-shadow: 0 0 10px 0 rgba(255, 242, 95, 0.75);
  z-index: 100;
  flex-direction: row;
  display: flex;
  height: 48px;
  max-width: 100%;
}

@media (max-width: 47.99em) {
  .shineastar .allstar .search-container {
    flex-direction: column;
    height: initial;
  }
}

.shineastar .allstar .search-container-wrapper {
  position: absolute;
  bottom: 0;
  max-width: 90%;
  left: 50%;
  z-index: 99;
  transform: translateX(-50%);
}

@media (max-width: 29.99em) {
  .shineastar .allstar .search-container-wrapper {
    bottom: 10px;
  }
}

.shineastar .allstar .search-container .searchStarInput {
  background: 0;
  color: #FFFFFF;
  border: 0;
  padding: 0 14.5px;
  width: 306px;
  font-size: 20px;
}

@media (max-width: 47.99em) {
  .shineastar .allstar .search-container .searchStarInput {
    padding: 7.5px 14.5px;
  }
}

.shineastar .allstar .search-container .searchStarInput::placeholder {
  color: #FFFFFF;
}

.shineastar .allstar .searchStarSubmit {
  background: #FAC52C;
  border: 2px solid #FAC52C;
  color: #0B3B64;
  box-shadow: 0 0 10px 0 rgba(255, 242, 95, 0.75);
  font-weight: bold;
  padding: 0 14.5px;
  font-size: 21px;
  border-radius: 0;
}

@media (max-width: 47.99em) {
  .shineastar .allstar .searchStarSubmit {
    padding: 5px 14.5px;
  }
}

.shineastar .allstar .searchStarSubmit:hover::after {
  opacity: 1;
}

.shineastar .allstar .searchStarSubmit::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: radial-gradient(#FFD458 0%, #FFD458 55%, #FAC52C 75%, #ffc928 90%);
  opacity: 0;
  transition: .4s ease opacity;
  z-index: 1;
}

.shineastar .allstar .backofstar {
  display: none;
}

.shineastar .allstar .name h3 {
  font-weight: normal;
  margin: 0;
  font-size: 18px;
  line-height: 1.5;
}

.shineastar .donate {
  display: flex;
  margin-top: 50px;
  margin-bottom: 50px;
}

@media (max-width: 47.99em) {
  .shineastar .donate {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    min-height: 609px;
  }
}

@media (min-width: 48em) {
  .shineastar .donate {
    margin-top: 0;
  }
}

.shineastar .donate .donate-inside {
  background: #0B3157;
  position: relative;
  border-radius: 10px;
  width: 1366px;
  margin: auto;
  padding: 40px 50px 50px;
  max-width: 95%;
  text-align: center;
}

@media (max-width: 47.99em) {
  .shineastar .donate .donate-inside {
    min-height: 90vh;
    min-height: calc(var(--vh, 1vh) * 90);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px 25px;
  }
}

.shineastar .donate .donate-inside .donation-amounts {
  max-width: 600px;
  margin: 20px auto 0;
}

.shineastar .donate .donate-inside .donation-amount-label {
  position: relative;
  width: 80px;
  height: 80px;
  margin-right: 10px;
  padding: 0;
  font-size: 30px;
  line-height: 80px;
  font-weight: 600;
  color: #E5007A;
  background-color: white;
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  .shineastar .donate .donate-inside .donation-amount-label:nth-of-type(3) {
    margin-right: 0;
  }
}

@media screen and (max-width: 500px) {
  .shineastar .donate .donate-inside .donation-amount-label {
    width: 70px;
    height: 70px;
    line-height: 70px;
    font-size: 28px;
  }
  .shineastar .donate .donate-inside .donation-amount-label:not(.donation-amount-label-other) {
    margin-right: 5px;
  }
}

.shineastar .donate .donate-inside .donation-amount-label-other {
  width: 110px;
  margin-right: 0;
}

.shineastar .donate .donate-inside .donation-amount-label-other span {
  position: relative;
  font-size: 24px;
  color: #E5007A;
  bottom: 2px;
}

@media screen and (max-width: 767px) {
  .shineastar .donate .donate-inside .donation-amount-label-other {
    display: block;
    margin: 10px auto 0;
    font-size: 22px;
  }
  .shineastar .donate .donate-inside .donation-amount-label-other span {
    bottom: 0;
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
  .shineastar .donate .donate-inside .donation-amount-label-other {
    width: 270px;
  }
}

@media screen and (max-width: 500px) {
  .shineastar .donate .donate-inside .donation-amount-label-other {
    width: 230px;
  }
}

.shineastar .donate .donate-inside .donation-amount-label::before, .shineastar .donate .donate-inside .donation-amount-label::after {
  display: none;
}

.shineastar .donate .donate-inside #donation-amount2-other-input {
  display: none;
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: calc(100% - 20px);
  font-size: 24px;
  padding-left: 20px;
}

@media screen and (max-width: 767px) {
  .shineastar .donate .donate-inside #donation-amount2-other-input {
    width: 40%;
    bottom: 0;
    height: 100%;
    left: 80px;
    font-size: 40px;
    border: 0;
    padding-left: 2px;
  }
}

@media screen and (max-width: 550px) {
  .shineastar .donate .donate-inside #donation-amount2-other-input {
    width: 60%;
    left: 48px;
  }
}

.shineastar .donate .donate-inside .quid {
  display: none;
  position: absolute !important;
  top: 13px;
  left: 16px;
  bottom: initial !important;
  z-index: 999;
  color: #0B3B64 !important;
}

.shineastar .donate .donate-inside #donation-amount2-other:checked + .donation-amount-label .quid {
  display: block;
}

@media (max-width: 47.99em) {
  .shineastar .donate .donate-inside #donation-amount2-other:checked + .donation-amount-label .quid {
    display: none;
  }
}

.shineastar .donate .donate-inside #donation-amount2-other:checked + .donation-amount-label span {
  bottom: 11px;
  color: white;
}

.shineastar .donate .donate-inside #donation-amount2-other:checked + .donation-amount-label #donation-amount2-other-input {
  display: block;
}

.shineastar .donate .donate-inside #donation-amount2-other:checked + .donation-amount-label #donation-amount2-other-input::-webkit-inner-spin-button, .shineastar .donate .donate-inside #donation-amount2-other:checked + .donation-amount-label #donation-amount2-other-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media screen and (max-width: 767px) {
  .shineastar .donate .donate-inside #donation-amount2-other:checked + .donation-amount-label span {
    right: 45px;
    bottom: 0;
    z-index: 10;
  }
}

.shineastar .donate .donate-inside input[name="donation-amount-2"]:checked + .donation-amount-label {
  color: white;
  background-color: #E5007A;
}

@media screen and (max-width: 767px) {
  .shineastar .donate .donate-inside input[name="donation-amount-2"]:checked + .donation-amount-label {
    background-color: #fff;
    color: #0B3B64 !important;
  }
}

.shineastar .donate .donate-inside .donation-details2-container {
  position: relative;
  min-height: 75px;
  max-width: 500px;
  margin: 30px auto 0;
  padding: 15px;
  border: 1px solid #E5007A;
}

@media (max-width: 47.99em) {
  .shineastar .donate .donate-inside .donation-details2-container {
    margin: 0 auto;
    min-height: 90px;
  }
}

.shineastar .donate .donate-inside .donation-details2-container img {
  max-width: 100%;
}

.shineastar .donate .donate-inside .donation-details2-container::before, .shineastar .donate .donate-inside .donation-details2-container::after {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  transition: left .2s ease;
}

@media screen and (max-width: 767px) {
  .shineastar .donate .donate-inside .donation-details2-container::before, .shineastar .donate .donate-inside .donation-details2-container::after {
    display: none;
  }
}

.shineastar .donate .donate-inside .donation-details2-container::after {
  top: -40px;
  left: 161px;
  border-color: transparent transparent #0B3157 transparent;
  border-width: 20px;
}

.shineastar .donate .donate-inside .donation-details2-container::before {
  top: -43px;
  left: 160px;
  border-color: transparent transparent #E5007A transparent;
  border-width: 21px;
}

.shineastar .donate .donate-inside .donation-details2-container.single .text-single {
  display: block;
}

.shineastar .donate .donate-inside .donation-details2-container.monthly .text-monthly {
  display: block;
}

.shineastar .donate .donate-inside .donation-details2-container[display-detail="matched-donation"] .donation-details2-christmas-matched-donation {
  display: flex;
}

.shineastar .donate .donate-inside .donation-details2-container[display-detail="matched-donation"]::before {
  left: 56px;
}

.shineastar .donate .donate-inside .donation-details2-container[display-detail="matched-donation"]::after {
  left: 57px;
}

.shineastar .donate .donate-inside .donation-details2-container[display-detail="first"] .donation-details2-first {
  display: flex;
}

.shineastar .donate .donate-inside .donation-details2-container[display-detail="first"]::before {
  left: 56px;
}

.shineastar .donate .donate-inside .donation-details2-container[display-detail="first"]::after {
  left: 57px;
}

.shineastar .donate .donate-inside .donation-details2-container[display-detail="second"] .donation-details2-second {
  display: flex;
}

.shineastar .donate .donate-inside .donation-details2-container[display-detail="second"]::before {
  left: 160px;
}

.shineastar .donate .donate-inside .donation-details2-container[display-detail="second"]::after {
  left: 161px;
}

.shineastar .donate .donate-inside .donation-details2-container[display-detail="third"] .donation-details2-third {
  display: flex;
}

.shineastar .donate .donate-inside .donation-details2-container[display-detail="third"]::before {
  left: 265px;
}

.shineastar .donate .donate-inside .donation-details2-container[display-detail="third"]::after {
  left: 266px;
}

.shineastar .donate .donate-inside .donation-details2-container[display-detail="other"] .donation-details2-other {
  display: flex;
}

.shineastar .donate .donate-inside .donation-details2-container[display-detail="other"]::before {
  left: 385px;
}

.shineastar .donate .donate-inside .donation-details2-container[display-detail="other"]::after {
  left: 386px;
}

.shineastar .donate .donate-inside .donation-details2 {
  display: none;
  justify-content: space-around;
  align-items: center;
  position: relative;
  height: 100%;
  text-align: left;
}

.shineastar .donate .donate-inside .donation-details2 .amount {
  width: 50px;
  font-size: 30px;
  line-height: 30px;
  margin-right: 20px;
}

.shineastar .donate .donate-inside .donation-details2 .text-single,
.shineastar .donate .donate-inside .donation-details2 .text-monthly {
  display: none;
  max-width: 380px;
  margin: 0;
  font-size: 16px;
  line-height: 22px;
}

@media screen and (max-width: 500px) {
  .shineastar .donate .donate-inside .donation-details2 .text-single,
  .shineastar .donate .donate-inside .donation-details2 .text-monthly {
    font-size: 14px;
    line-height: 20px;
    margin-left: 10px;
  }
}

.shineastar .donate .donate-inside .donation-details2-other .text-single,
.shineastar .donate .donate-inside .donation-details2-other .text-monthly {
  text-align: center;
}

.shineastar .donate .donate-inside .pos-1 {
  top: 18%;
}

@media screen and (max-width: 500px) {
  .shineastar .donate .donate-inside .pos-1 {
    top: 7%;
    left: 2.5%;
  }
}

.shineastar .donate .donate-inside .pos-2 {
  top: 4%;
  left: 80%;
}

.shineastar .donate .donate-inside .pos-3 {
  top: 70%;
  left: 90%;
}

@media screen and (max-width: 500px) {
  .shineastar .donate .donate-inside .pos-3 {
    top: 80%;
    left: 72%;
  }
}

.shineastar .donate .donate-inside .pos-4 {
  left: 14%;
}

@media screen and (max-width: 800px) {
  .shineastar .donate .donate-inside .pos-4 {
    display: none;
  }
}

.shineastar .donate .donate-inside .pos-5 {
  top: 80%;
  left: 6%;
}

@media screen and (max-width: 400px) {
  .shineastar .donate .donate-inside .pos-5 {
    top: 50%;
  }
}

.shineastar .donate .donate-inside .shining-star-large {
  width: 185px;
  height: 250px;
}

.shineastar .donate .donate-inside .pos-8,
.shineastar .donate .donate-inside .pos-9 {
  background-image: url("../img/shineastar/form-starGroup.svg");
}

@media (max-width: 47.99em) {
  .shineastar .donate .donate-inside .pos-8,
  .shineastar .donate .donate-inside .pos-9 {
    display: none;
  }
}

.shineastar .donate .donate-inside .pos-9 {
  left: 12.5%;
}

.shineastar .donate h1 {
  color: #FAC52C;
  font-size: 60px;
  line-height: 72px;
  margin: 0 auto 20px;
}

@media (max-width: 47.99em) {
  .shineastar .donate h1 {
    font-size: 42px;
    line-height: 44px;
    margin: 0 auto 10px;
  }
}

.shineastar .donate p {
  font-size: 24px;
  line-height: 32px;
  max-width: 600px;
  margin: 0 auto 25px;
}

@media (max-width: 47.99em) {
  .shineastar .donate p {
    font-size: 16px;
    line-height: 21px;
  }
}

.shineastar .donate .pound {
  width: 100%;
  max-width: 375px;
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
}

.shineastar .donate .pound:before {
  content: '£';
  position: absolute;
  left: 2px;
  top: 2px;
  background: #fff;
  height: calc(100% - 4px);
  width: 50px;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0B3157;
  font-size: 40px;
  font-weight: bold;
}

.shineastar .donate input[type=text],
.shineastar .donate input[type=number] {
  font-size: 60px;
  color: #0B3157;
  position: relative;
  font-weight: bold;
  text-align: center;
  width: 100%;
}

.shineastar .donate input[type=text]::placeholder,
.shineastar .donate input[type=number]::placeholder {
  color: #0B3157;
}

.shineastar .donate input[type=text] ::-webkit-input-placeholder,
.shineastar .donate input[type=number] ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #0B3157;
}

.shineastar .donate input[type=text] ::-moz-placeholder,
.shineastar .donate input[type=number] ::-moz-placeholder {
  /* Firefox 19+ */
  color: #0B3157;
}

.shineastar .donate input[type=text] :-ms-input-placeholder,
.shineastar .donate input[type=number] :-ms-input-placeholder {
  /* IE 10+ */
  color: #0B3157;
}

.shineastar .donate input[type=text] :-moz-placeholder,
.shineastar .donate input[type=number] :-moz-placeholder {
  /* Firefox 18- */
  color: #0B3157;
}

.shineastar .donate .site-nav-donate {
  margin: 40px auto 0;
  display: inline-block;
  float: none;
}

.shineastar .form {
  display: flex;
  transition: background .4s ease;
}

.shineastar .form svg {
  max-width: 100%;
}

.shineastar .form .final-stage-form {
  display: none;
}

.shineastar .form.final-stage .form-inside {
  background: #0B3157;
  text-align: center;
}

.shineastar .form.final-stage .form-inside .form-wrapper {
  border-radius: 10px;
}

@media (max-width: 47.99em) {
  .shineastar .form.final-stage .form-inside .form-wrapper {
    padding: 50px 0;
  }
}

.shineastar .form.final-stage .form-inside .form-wrapper .form-output {
  width: 0%;
  height: 0px;
  opacity: 0;
  z-index: -1;
}

.shineastar .form.final-stage .form-inside .form-wrapper form {
  width: 100%;
}

.shineastar .form.final-stage .form-inside .form-wrapper .donate-inside {
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
}

@media (max-width: 47.99em) {
  .shineastar .form.final-stage .form-inside .form-wrapper .donate-inside p.matched-donation-visible,
  .shineastar .form.final-stage .form-inside .form-wrapper .donate-inside p.matched-donation-hidden {
    display: none;
  }
}

.shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-amounts {
  max-width: 600px;
  margin: 20px auto 0;
}

@media (max-width: 47.99em) {
  .shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-amounts {
    margin: 10px auto 0;
  }
}

.shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-amount-label {
  position: relative;
  width: 80px;
  height: 80px;
  margin-right: 20px;
  padding: 0;
  font-size: 30px;
  line-height: 80px;
  font-weight: 600;
  color: #E5007A;
  background-color: white;
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  .shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-amount-label {
    width: 70px;
    height: 70px;
    margin-right: 5px;
    line-height: 70px;
    font-size: 28px;
  }
  .shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-amount-label:nth-of-type(3) {
    margin-right: 0;
  }
}

.shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-amount-label-other {
  width: 110px;
  margin-right: 0;
}

.shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-amount-label-other span {
  position: relative;
  font-size: 24px;
  color: #E5007A;
  bottom: 2px;
}

@media screen and (max-width: 767px) {
  .shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-amount-label-other {
    width: 230px;
    height: 70px;
    margin-top: 5px;
    font-size: 22px;
  }
  .shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-amount-label-other span {
    bottom: 0;
  }
}

.shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-amount-label::before, .shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-amount-label::after {
  display: none;
}

.shineastar .form.final-stage .form-inside .form-wrapper .donate-inside #donation-amount-other-input {
  display: none;
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: calc(100% - 20px);
  padding: 0;
  font-size: 24px;
}

@media screen and (max-width: 767px) {
  .shineastar .form.final-stage .form-inside .form-wrapper .donate-inside #donation-amount-other-input {
    width: 40%;
    bottom: 11px;
    left: 70px;
    font-size: 40px;
    border: 0;
  }
}

.shineastar .form.final-stage .form-inside .form-wrapper .donate-inside #donation-amount-other:checked + .donation-amount-label span {
  bottom: 11px;
  color: white;
}

.shineastar .form.final-stage .form-inside .form-wrapper .donate-inside #donation-amount-other:checked + .donation-amount-label #donation-amount-other-input {
  display: block;
}

.shineastar .form.final-stage .form-inside .form-wrapper .donate-inside #donation-amount-other:checked + .donation-amount-label #donation-amount-other-input::-webkit-inner-spin-button, .shineastar .form.final-stage .form-inside .form-wrapper .donate-inside #donation-amount-other:checked + .donation-amount-label #donation-amount-other-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media (max-width: 29.99em) {
  .shineastar .form.final-stage .form-inside .form-wrapper .donate-inside #donation-amount-other:checked + .donation-amount-label #donation-amount-other-input {
    line-height: 24px;
  }
}

@media screen and (max-width: 767px) {
  .shineastar .form.final-stage .form-inside .form-wrapper .donate-inside #donation-amount-other:checked + .donation-amount-label span {
    bottom: 0;
    right: 45px;
  }
}

.shineastar .form.final-stage .form-inside .form-wrapper .donate-inside input[name="donation-amount"]:checked + .donation-amount-label {
  color: white;
  background-color: #E5007A;
}

@media screen and (max-width: 767px) {
  .shineastar .form.final-stage .form-inside .form-wrapper .donate-inside input[name="donation-amount"]:checked + .donation-amount-label {
    background-color: #fff;
    color: #0B3B64;
  }
}

.shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-details-container {
  position: relative;
  min-height: 75px;
  max-width: 500px;
  margin: 30px auto 0;
  padding: 15px;
  border: 1px solid #E5007A;
}

@media (max-width: 47.99em) {
  .shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-details-container {
    margin: 15px auto 0;
  }
}

@media screen and (max-width: 500px) {
  .shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-details-container {
    display: none;
  }
}

.shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-details-container::before, .shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-details-container::after {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  transition: left .2s ease;
}

@media screen and (max-width: 767px) {
  .shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-details-container::before, .shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-details-container::after {
    display: none;
  }
}

.shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-details-container::after {
  top: -40px;
  left: 161px;
  border-color: transparent transparent #0B3157 transparent;
  border-width: 20px;
}

.shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-details-container::before {
  top: -43px;
  left: 160px;
  border-color: transparent transparent #E5007A transparent;
  border-width: 21px;
}

.shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-details-container.single .text-single {
  display: block;
}

.shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-details-container.monthly .text-monthly {
  display: block;
}

.shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-details-container[display-detail="first"] .donation-details-first {
  display: flex;
}

.shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-details-container[display-detail="first"]::before {
  left: calc(56 / 500 * 100%);
}

.shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-details-container[display-detail="first"]::after {
  left: calc(57 / 500 * 100%);
}

.shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-details-container[display-detail="second"] .donation-details-second {
  display: flex;
}

.shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-details-container[display-detail="second"]::before {
  left: calc(160 / 500 * 100%);
}

.shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-details-container[display-detail="second"]::after {
  left: calc(161 / 500 * 100%);
}

.shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-details-container[display-detail="third"] .donation-details-third {
  display: flex;
}

.shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-details-container[display-detail="third"]::before {
  left: calc(265 / 500 * 100%);
}

.shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-details-container[display-detail="third"]::after {
  left: calc(266 / 500 * 100%);
}

.shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-details-container[display-detail="other"] .donation-details-other {
  display: flex;
}

.shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-details-container[display-detail="other"]::before {
  left: calc(385 / 500 * 100%);
}

.shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-details-container[display-detail="other"]::after {
  left: calc(386 / 500 * 100%);
}

.shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-details {
  display: none;
  justify-content: space-around;
  align-items: center;
  position: relative;
  height: 100%;
  text-align: left;
}

.shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-details .amount {
  width: 50px;
  font-size: 30px;
  line-height: 30px;
  margin-right: 25px;
}

.shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-details .text-single,
.shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-details .text-monthly {
  display: none;
  margin: 0;
  font-size: 16px;
  line-height: 22px;
  max-width: 100%;
}

@media screen and (max-width: 500px) {
  .shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-details .text-single,
  .shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-details .text-monthly {
    font-size: 14px;
    line-height: 20px;
    margin-left: 10px;
  }
}

.shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-details-other .text-single,
.shineastar .form.final-stage .form-inside .form-wrapper .donate-inside .donation-details-other .text-monthly {
  text-align: center;
}

.shineastar .form.final-stage .form-inside h1 {
  color: #FAC52C;
  font-size: 42px;
  line-height: 46px;
  margin: 0 auto 15px;
}

@media screen and (max-width: 1024px) {
  .shineastar .form.final-stage .form-inside h1 {
    font-size: 35px;
  }
}

.shineastar .form.final-stage .form-inside p {
  font-size: 21px;
  line-height: 26px;
  max-width: 732px;
  margin: 0 auto 15px;
}

.shineastar .form.final-stage .form-inside .pound {
  width: 100%;
  max-width: 375px;
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
}

.shineastar .form.final-stage .form-inside .pound:before {
  content: "\a3";
  position: absolute;
  left: 2px;
  top: 2px;
  background: #fff;
  height: calc(100% - 4px);
  width: 50px;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0B3157;
  font-size: 40px;
  font-weight: bold;
}

.shineastar .form.final-stage .form-inside input[type=text],
.shineastar .form.final-stage .form-inside input[type=number] {
  font-size: 60px;
  color: #0B3157;
  position: relative;
  font-weight: bold;
  text-align: center;
  width: 100%;
  padding: 5px 12.5px;
}

.shineastar .form.final-stage .form-inside input[type=text]::placeholder,
.shineastar .form.final-stage .form-inside input[type=number]::placeholder {
  color: #0B3157;
}

.shineastar .form.final-stage .form-inside input[type=text] ::-webkit-input-placeholder,
.shineastar .form.final-stage .form-inside input[type=number] ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #0B3157;
}

.shineastar .form.final-stage .form-inside input[type=text] ::-moz-placeholder,
.shineastar .form.final-stage .form-inside input[type=number] ::-moz-placeholder {
  /* Firefox 19+ */
  color: #0B3157;
}

.shineastar .form.final-stage .form-inside input[type=text] :-ms-input-placeholder,
.shineastar .form.final-stage .form-inside input[type=number] :-ms-input-placeholder {
  /* IE 10+ */
  color: #0B3157;
}

.shineastar .form.final-stage .form-inside input[type=text] :-moz-placeholder,
.shineastar .form.final-stage .form-inside input[type=number] :-moz-placeholder {
  /* Firefox 18- */
  color: #0B3157;
}

.shineastar .form.final-stage .form-inside .site-nav-donate {
  margin: 20px auto;
  display: inline-block;
  float: none;
}

@media (max-width: 47.99em) {
  .shineastar .form.final-stage .form-inside .site-nav-donate {
    margin: 10px auto;
  }
}

.shineastar .form.final-stage .buttons {
  display: none;
}

.shineastar .form.final-stage #nodonation {
  background: none;
  border: 0;
  color: #fff;
  text-decoration: underline;
  font-size: 16px;
}

.shineastar .form.final-stage .form-step-container .step-5 {
  background: #fff;
  color: #0B3157;
}

.shineastar .form.final-stage #shineastarForm {
  display: none;
}

.shineastar .form.final-stage .final-stage-form {
  height: 100%;
  display: block;
}

.shineastar .form.final-stage .final-stage-form fieldset {
  opacity: 1;
  z-index: 1;
}

.shineastar .form form {
  position: relative;
  width: 65%;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  min-height: 200px;
  transition: .4s ease min-height;
  padding-bottom: 0;
}

@media (min-width: 48em) {
  .shineastar .form form {
    min-height: 250px;
  }
}

@media (min-width: 64em) {
  .shineastar .form form {
    width: 50%;
    min-height: initial;
  }
}

.shineastar .form form h2 {
  font-size: 20px;
}

@media (min-width: 48em) {
  .shineastar .form form h2 {
    font-size: 30px;
  }
}

.shineastar .form form .inputfile + label strong,
.shineastar .form form .inputfile + label span {
  font-size: 14px;
  padding: 5.5px 12.5px;
}

@media (min-width: 48em) {
  .shineastar .form form .inputfile + label strong,
  .shineastar .form form .inputfile + label span {
    padding: 15px;
    font-size: 20px;
  }
}

@media (min-width: 64em) {
  .shineastar .form form .inputfile + label strong,
  .shineastar .form form .inputfile + label span {
    padding: 20px;
  }
}

.shineastar .form .fieldset-4-mobile {
  display: none;
}

.shineastar .form.fieldset-4-active_WRAP form {
  margin-bottom: 70px;
  min-height: 250px;
}

@media (min-width: 30em) and (max-width: 47.99em) {
  .shineastar .form.fieldset-4-active_WRAP form {
    min-height: 300px;
  }
}

@media (min-width: 48em) {
  .shineastar .form.fieldset-4-active_WRAP form {
    min-height: 350px;
  }
}

.shineastar .form.fieldset-4-active_WRAP .form-output {
  background-image: none;
}

@media (min-width: 64em) {
  .shineastar .form.fieldset-4-active_WRAP .form-output {
    background-image: url("../img/shineastar/StarShape.png");
  }
}

.shineastar .form.fieldset-4-active_WRAP .fieldset-4-mobile {
  display: flex;
}

@media (min-width: 64em) {
  .shineastar .form.fieldset-4-active_WRAP .fieldset-4-mobile {
    display: none;
  }
}

.shineastar .form.fieldset-4-active_WRAP .section-4 h2 {
  display: none;
}

@media (min-width: 64em) {
  .shineastar .form.fieldset-4-active_WRAP .section-4 h2 {
    display: block;
  }
}

.shineastar .form.final-stage form {
  margin-bottom: 0;
}

.shineastar .form .form-inside {
  background: #1661A1;
  height: 750px;
  max-height: 95%;
  position: relative;
  border-radius: 10px;
  width: 1366px;
  margin: auto auto 30px;
  padding: 50px 35px 0px;
  max-width: 95%;
  display: flex;
  flex-direction: column;
}

@media (max-width: 47.99em) {
  .shineastar .form .form-inside {
    height: 90vh;
    /**  Fallback for browsers that do not support Custom Properties **/
    height: calc(var(--vh, 1vh) * 90);
    min-height: 520px;
  }
}

.shineastar .form .form-wrapper {
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

@media (max-width: 63.99em) {
  .shineastar .form .form-wrapper {
    flex-direction: column;
    justify-content: start;
  }
  .shineastar .form .form-wrapper .fieldset-0-output {
    background-position: center;
  }
}

.shineastar .form .form-wrapper.fieldset-3-active .form-output {
  background-image: none;
}

.shineastar .form .form-output {
  position: relative;
  height: 100%;
  width: 100%;
  background-image: url("../img/shineastar/StarShape.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

@media (min-width: 64em) {
  .shineastar .form .form-output {
    width: 45%;
    z-index: 1;
  }
}

.shineastar .form .form-output .form-output-field {
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: .4s opacity ease, .4s transform ease;
  flex-direction: column;
  transform: scale(0);
  opacity: 0;
  left: 0;
}

.shineastar .form .form-output .form-output-field.active {
  transform: scale(1);
  opacity: 1;
  display: flex;
}

.shineastar .form .form-output .form-output-field.fieldset-4-output {
  display: none;
  opacity: 0;
}

@media (min-width: 64em) {
  .shineastar .form .form-output .form-output-field.fieldset-4-output {
    display: flex;
    opacity: 1;
  }
}

.shineastar .form .form-output .form-output-field.fieldset-4-mobile {
  opacity: 1;
  transform: scale(1);
}

.shineastar .form .form-output .form-output-field.fieldset-4-mobile h2 {
  color: #FAC52C;
}

.shineastar .form .form-output .form-output-field h3,
.shineastar .form .form-output .form-output-field h4,
.shineastar .form .form-output .form-output-field p {
  max-width: 45%;
  margin: 0 auto;
  word-wrap: break-word;
}

.shineastar .form .form-output .form-output-field h3, .shineastar .form .form-output .form-output-field h4 {
  color: #FAC52C;
}

.shineastar .form .form-output .form-output-field h3 {
  margin-bottom: 30px;
  margin-top: 40px;
  font-size: 26px;
}

.shineastar .form .form-output .form-output-field p {
  margin-bottom: 20px;
}

.shineastar .form .form-output .form-output-field h4 {
  font-size: 18px;
}

.shineastar .form input,
.shineastar .form textarea {
  padding: 17.5px 12.5px;
  width: 100%;
  box-sizing: border-box;
}

.shineastar .form input::placeholder,
.shineastar .form textarea::placeholder {
  color: #0B3157;
  opacity: 1;
}

.shineastar .form select {
  padding: 10px 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #0B3157;
  outline: none;
  position: relative;
  background-image: url("../img/shineastar/chevron-down.png");
  background-repeat: no-repeat;
  background-position: 93% center;
  background-size: 17px;
  border-radius: 0px;
  width: 125px;
  border: 1px solid #979797;
  background-color: #fff;
}

.shineastar .form textarea {
  height: 60px;
}

.shineastar .form fieldset {
  padding: 10px 0;
  position: absolute;
  transition: .75s ease opacity, .75s ease top, .75s ease z-index;
  opacity: 0;
  border: 0;
  width: 100%;
  z-index: -1;
}

@media (min-width: 64em) {
  .shineastar .form fieldset {
    top: -100px;
  }
  .shineastar .form fieldset.section-4 {
    top: -50px;
  }
}

.shineastar .form fieldset.next-active {
  top: 100px;
}

.shineastar .form fieldset.active {
  display: block;
  opacity: 1;
  z-index: 1;
}

.shineastar .form fieldset.section-5 {
  top: 0;
  transform: none;
}

.shineastar .form fieldset .remove-image {
  color: #fff;
  border: 0;
  background: none;
  text-decoration: underline;
  padding: 0;
  margin-top: 15px;
  font-size: 16px;
}

@media (min-width: 64em) {
  .shineastar .form fieldset .remove-image {
    font-size: 18px;
  }
}

.shineastar .form .section-4 .flex {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.shineastar .form .section-4 .flex input {
  width: 49%;
}

.shineastar .form .section-4 .flex.checkbox {
  align-items: center;
  padding-top: 0;
  display: block;
}

.shineastar .form .section-4 .flex.checkbox input[type=checkbox] {
  width: 25px;
}

.shineastar .form .section-4 .flex.checkbox span {
  flex-grow: 1;
}

.shineastar .form .fieldset-0-output {
  width: 100%;
  height: 100%;
  background-image: url("../img/shineastar/CreateAStar2.png");
  background-position: center left;
  background-size: contain;
  background-repeat: no-repeat;
  transition: .4s ease transform;
  transform: scale(0);
}

.shineastar .form .fieldset-0-output.active {
  transform: scale(1);
}

.shineastar .form .fieldset-2-output svg {
  max-width: 92.5%;
}

.shineastar .form .buttons {
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  transform: translateX(-50%);
  bottom: 50px;
  width: 100%;
}

@media (min-width: 64em) {
  .shineastar .form .buttons {
    justify-content: flex-end;
    width: calc(100% - 120px);
  }
}

.shineastar .form .buttons .back-button {
  font-size: 16px;
  color: #fff;
  background: none;
  border: 0;
  text-decoration: underline;
  padding: 12.5px 20px;
  transition: .4s ease opacity;
}

.shineastar .form .buttons .back-button.grey {
  opacity: 0;
}

.shineastar .form .form-step-container {
  width: 100%;
  height: 50px;
  margin-bottom: -25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 565px) {
  .shineastar .form .form-step-container {
    height: 30px;
    margin-bottom: -15px;
  }
}

.shineastar .form .form-step-container .form-step {
  background: #3474BD;
  color: #fff;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: 8px solid #3474BD;
  margin: 0 5px;
  transition: color .4s ease, background .4s ease;
  font-weight: 600;
}

.shineastar .form .form-step-container .form-step.active {
  background: #fff;
  color: #0B3157;
}

.shineastar .starOverlay {
  opacity: 0;
  position: fixed;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  transition: .4s ease opacity;
  pointer-events: none;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999999;
}

.shineastar .starOverlay.active {
  opacity: 1;
  pointer-events: auto;
}

.shineastar .starOverlay__Inner {
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../img/shineastar/StarShape.png");
  width: 320px;
  height: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media (min-width: 48em) {
  .shineastar .starOverlay__Inner {
    width: 560px;
    height: 560px;
  }
}

.shineastar .starOverlay__Inner h3,
.shineastar .starOverlay__Inner h4 {
  margin: 0 auto 5px;
  color: #FAC52C;
  font-size: 13px;
  line-height: 16px;
  max-width: 150px;
  text-align: center;
}

@media (min-width: 48em) {
  .shineastar .starOverlay__Inner h3,
  .shineastar .starOverlay__Inner h4 {
    font-size: 22px;
    line-height: 25px;
    margin: 0 auto 10px;
    max-width: 350px;
  }
}

.shineastar .starOverlay__Inner h3 {
  padding-top: 10px;
}

@media (min-width: 48em) {
  .shineastar .starOverlay__Inner h3 {
    padding-top: 25px;
  }
}

.shineastar .starOverlay__Inner p {
  margin: 0 auto 5px;
  max-width: 150px;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
}

@media (min-width: 48em) {
  .shineastar .starOverlay__Inner p {
    font-size: 18px;
    line-height: 20px;
    margin: 0 auto 10px;
    max-width: 280px;
  }
}

.shineastar .starOverlay__Inner h4 {
  margin-bottom: 0;
}

@media (min-width: 48em) {
  .shineastar .starOverlay__Inner h4 {
    max-width: 250px;
  }
}

@-webkit-keyframes shineYourStar {
  0% {
    opacity: 0;
    box-shadow: 0px 0px 10px 5px rgba(250, 214, 38, 0.5);
  }
  50% {
    opacity: 1;
    box-shadow: 0px 0px 20px 10px rgba(250, 214, 38, 0.5);
  }
  100% {
    opacity: 0;
    box-shadow: 0px 0px 10px 5px rgba(250, 214, 38, 0.5);
  }
}

@-moz-keyframes shineYourStar {
  0% {
    opacity: 0;
    box-shadow: 0px 0px 10px 5px rgba(250, 214, 38, 0.5);
  }
  50% {
    opacity: 1;
    box-shadow: 0px 0px 20px 10px rgba(250, 214, 38, 0.5);
  }
  100% {
    opacity: 0;
    box-shadow: 0px 0px 10px 5px rgba(250, 214, 38, 0.5);
  }
}

@-o-keyframes shineYourStar {
  0% {
    opacity: 0;
    box-shadow: 0px 0px 10px 5px rgba(250, 214, 38, 0.5);
  }
  100% {
    opacity: 1;
    box-shadow: 0px 0px 20px 10px rgba(250, 214, 38, 0.5);
  }
  100% {
    opacity: 0;
    box-shadow: 0px 0px 10px 5px rgba(250, 214, 38, 0.5);
  }
}

@keyframes shineYourStar {
  0% {
    opacity: 0;
    box-shadow: 0px 0px 10px 5px rgba(250, 214, 38, 0.5);
  }
  50% {
    opacity: 1;
    box-shadow: 0px 0px 20px 10px rgba(250, 214, 38, 0.5);
  }
  100% {
    opacity: 0;
    box-shadow: 0px 0px 10px 5px rgba(250, 214, 38, 0.5);
  }
}

.shineastar button:focus,
.shineastar input:focus,
.shineastar select:focus,
.shineastar textarea:focus,
.shineastar a:focus,
.shineastar label:focus,
.shineastar input[type=submit]:focus {
  box-shadow: 0px 0px 5px 4px #7ED321, inset 0px 0px 0px 2px white;
  outline: none;
}

.shineastar:not(.user-is-tabbing) button:focus,
.shineastar:not(.user-is-tabbing) input:focus,
.shineastar:not(.user-is-tabbing) select:focus,
.shineastar:not(.user-is-tabbing) textarea:focus,
.shineastar:not(.user-is-tabbing) a:focus,
.shineastar:not(.user-is-tabbing) label:focus {
  box-shadow: none;
  outline: none;
}

.shineastar header {
  position: fixed;
  width: 100%;
  background: transparent;
}

.shineastar header .contain {
  max-width: 1366px;
  padding-left: 0;
  padding-right: 0;
}

.shineastar header.site-header {
  padding-top: 35px;
}

.shineastar header.site-header .social-donate {
  height: 100px;
  margin-top: 0;
  transition: .5s ease height;
}

.shineastar header.site-header .both-logo {
  position: relative;
  width: 210px;
  height: 110px;
  display: inline-block;
  float: left;
  transition: .5s opacity ease, .5s width ease, .5s height ease;
}

.shineastar header.site-header .both-logo a {
  position: absolute;
  transition: .5s opacity ease, .5s width ease, .5s height ease;
}

.shineastar header.site-header .both-logo .site-logo-fixed {
  display: block;
  text-indent: 110%;
  white-space: nowrap;
  overflow: hidden;
  width: 201px;
  height: 110px;
  background-color: transparent;
  background-image: url("../img/shineastar/logo-shineastar-fixed.png");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  float: left;
  position: absolute;
  left: -1px;
  top: -3px;
  opacity: 0;
}

.shineastar header.site-header.scrolled .both-logo {
  width: 60px;
}

.shineastar header.site-header.scrolled .both-logo a {
  height: 60px;
  width: 50px;
}

.shineastar header.site-header.scrolled .both-logo .site-logo {
  opacity: 0;
}

.shineastar header.site-header.scrolled .both-logo .site-logo-fixed {
  opacity: 1;
}

.shineastar header.site-header.scrolled .social-donate {
  height: 60px;
}

.shineastar .background-video {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
}

.shineastar main h1,
.shineastar main h2,
.shineastar main h3,
.shineastar main h4,
.shineastar main h5,
.shineastar main h6,
.shineastar main p,
.shineastar main a,
.shineastar main span {
  color: #fff;
}

.shineastar main h1.thin,
.shineastar main h2.thin,
.shineastar main h3.thin,
.shineastar main h4.thin,
.shineastar main h5.thin,
.shineastar main h6.thin,
.shineastar main p.thin,
.shineastar main a.thin,
.shineastar main span.thin {
  font-weight: 400;
}

.shineastar section {
  min-height: 100vh;
}

.shineastar .landing {
  background-image: url("../img/shineastar/shineastar-background-img.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  min-height: 540px;
  height: 100vh;
  /**  Fallback for browsers that do not support Custom Properties **/
  height: calc(var(--vh, 1vh) * 100);
}

.shineastar .big-button {
  background-color: #FAC52C;
  text-transform: uppercase;
  line-height: 1;
  padding: 20px 40px;
  font-size: 30px;
  font-weight: bold;
  background: radial-gradient(#FAC52C 0%, #FAC52C 50%, #FAC52C 75%, #FAC52C 90%);
  transition: .5s background-color ease, .5s background ease;
  color: #0B3157;
  border: 0;
  position: relative;
}

.shineastar .big-button:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: radial-gradient(#FFD458 0%, #FFD458 55%, #FAC52C 75%, #ffc928 90%);
  opacity: 0;
  transition: .4s ease opacity;
  z-index: 1;
}

.shineastar .big-button .text {
  color: #0B3157;
  position: relative;
  z-index: 2;
  display: block;
}

.shineastar .big-button:hover {
  background-color: transparent;
}

.shineastar .big-button:hover:after {
  opacity: 1;
}

.shineastar .big-button.smaller {
  font-size: 20px;
  padding: 12.5px 20px;
}

.shineastar .big-button:disabled, .shineastar .big-button[disabled] {
  background: #3474BD;
  color: rgba(255, 255, 255, 0.5);
  border: 0;
}

.shineastar .big-button:disabled span, .shineastar .big-button[disabled] span {
  color: rgba(255, 255, 255, 0.5);
}

.shineastar .big-button:disabled:hover, .shineastar .big-button[disabled]:hover {
  background-color: #3474BD;
}

.shineastar .big-button:disabled:hover:after, .shineastar .big-button[disabled]:hover:after {
  opacity: 0;
}

.shineastar label,
.shineastar legend {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.shineastar .site-header {
  background-color: transparent;
  background-image: linear-gradient(0deg, rgba(8, 1, 62, 0) 0%, #08013E 100%);
}

.shineastar .site-header .site-logo {
  background-image: url("../img/shineastar/logo-shineastar.png");
}

.shineastar .site-header .social-donate {
  float: right;
  clear: right;
  line-height: 32px;
  margin-top: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shineastar .site-header .site-nav-donate {
  margin-left: 10px;
}

.shineastar .site-header .shineastar-social {
  margin-top: 0;
}

.shineastar .site-header .shineastar-social .social-facebook {
  background-image: url("../img/shineastar/shineastar-social-facebook.png");
  display: none;
}

.shineastar .site-header .shineastar-social .social-twitter {
  background-image: url("../img/shineastar/shineastar-social-twitter.png");
  display: none;
}

.shineastar .shining-star {
  width: 50px;
  height: 50px;
  position: absolute;
  background-image: url("../img/shineastar/shineastar-small-star.png");
  background-size: cover;
  background-repeat: no-repeat;
  top: 20%;
  left: 12.5%;
}

.shineastar .shining-star:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1px;
  height: 1px;
  border-radius: 50%;
  -webkit-animation: shineYourStar 2.5s infinite;
  /* Safari 4+ */
  -moz-animation: shineYourStar 2.5s infinite;
  /* Fx 5+ */
  -o-animation: shineYourStar 2.5s infinite;
  /* Opera 12+ */
  animation: shineYourStar 2.5s infinite;
  /* IE 10+, Fx 29+ */
}

.shineastar .shining-star.shining-star-large {
  background-image: url("../img/shineastar/banner-starGroup.svg");
  width: 150px;
  height: 150px;
}

.shineastar .shining-star.pos-2 {
  top: 85%;
  left: 5%;
}

.shineastar .shining-star.pos-2:after {
  -webkit-animation: shineYourStar 3s infinite;
  /* Safari 4+ */
  -moz-animation: shineYourStar 3s infinite;
  /* Fx 5+ */
  -o-animation: shineYourStar 3s infinite;
  /* Opera 12+ */
  animation: shineYourStar 3s infinite;
  /* IE 10+, Fx 29+ */
}

.shineastar .shining-star.pos-3 {
  top: 80%;
  left: 60%;
}

.shineastar .shining-star.pos-3:after {
  -webkit-animation: shineYourStar 4s infinite;
  /* Safari 4+ */
  -moz-animation: shineYourStar 4s infinite;
  /* Fx 5+ */
  -o-animation: shineYourStar 4s infinite;
  /* Opera 12+ */
  animation: shineYourStar 4s infinite;
  /* IE 10+, Fx 29+ */
}

.shineastar .shining-star.pos-4 {
  top: 9%;
  left: 36%;
}

.shineastar .shining-star.pos-4:after {
  -webkit-animation: shineYourStar 5s infinite;
  /* Safari 4+ */
  -moz-animation: shineYourStar 5s infinite;
  /* Fx 5+ */
  -o-animation: shineYourStar 5s infinite;
  /* Opera 12+ */
  animation: shineYourStar 5s infinite;
  /* IE 10+, Fx 29+ */
}

.shineastar .shining-star.pos-5 {
  top: 40%;
  left: 80%;
}

.shineastar .shining-star.pos-5:after {
  -webkit-animation: shineYourStar 1s infinite;
  /* Safari 4+ */
  -moz-animation: shineYourStar 1s infinite;
  /* Fx 5+ */
  -o-animation: shineYourStar 1s infinite;
  /* Opera 12+ */
  animation: shineYourStar 1s infinite;
  /* IE 10+, Fx 29+ */
}

.shineastar .shining-star.pos-6 {
  top: 25%;
  left: 70%;
}

.shineastar .shining-star.pos-6:after {
  -webkit-animation: shineYourStar 1.75s infinite;
  /* Safari 4+ */
  -moz-animation: shineYourStar 1.75s infinite;
  /* Fx 5+ */
  -o-animation: shineYourStar 1.75s infinite;
  /* Opera 12+ */
  animation: shineYourStar 1.75s infinite;
  /* IE 10+, Fx 29+ */
}

.shineastar .shining-star.pos-7 {
  top: 75%;
  left: 85%;
}

.shineastar .shining-star.pos-7:after {
  -webkit-animation: shineYourStar 1s infinite;
  /* Safari 4+ */
  -moz-animation: shineYourStar 1s infinite;
  /* Fx 5+ */
  -o-animation: shineYourStar 1s infinite;
  /* Opera 12+ */
  animation: shineYourStar 1s infinite;
  /* IE 10+, Fx 29+ */
}

.shineastar .shining-star.pos-8 {
  top: 55%;
  left: 70%;
}

@media (max-width: 47.99em) {
  .shineastar .shining-star.pos-8 {
    display: none;
  }
}

.shineastar .shining-star.pos-8::after {
  top: 78%;
  left: 60%;
}

.shineastar .shining-star.pos-9 {
  transform: scaleX(-1);
  top: 55%;
  left: 17%;
}

@media (max-width: 47.99em) {
  .shineastar .shining-star.pos-9 {
    display: none;
  }
}

.shineastar .shining-star.pos-9::after {
  top: 78%;
  left: 61%;
}

@media (min-width: 20em) and (max-width: 47.99em) {
  .shineastar section.form {
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 63.99em) {
  .shineastar header .site-social-links {
    position: none;
    float: none;
    clear: none;
    text-align: inherit;
    font-size: inherit;
    height: auto;
    margin-top: 0;
    left: auto;
    width: auto;
    -ms-transform: none;
    transform: none;
    padding-top: 0;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 63.99em) and (min-width: 48em) {
  .shineastar .site-nav-donate {
    padding-left: 40px;
    padding-right: 40px;
    font-size: 19px;
  }
}

@media screen and (max-width: 47.99em) {
  .shineastar .site-nav-donate {
    border-bottom: 0;
  }
  .shineastar section .site-nav-donate {
    padding: 12.5px 20px;
    width: auto;
  }
}

@media screen and (max-width: 1366px) {
  .shineastar header .contain {
    padding-left: 20px;
    padding-right: 20px;
  }
  .shineastar .shineastar-logo {
    background-image: none;
  }
}

@media screen and (max-width: 1023px) {
  .shineastar .form .form-inside {
    padding: 30px 25px 0px;
  }
  .shineastar .form.final-stage form.final-stage-form {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .shineastar .form.final-stage form.final-stage-form h1 {
    padding: 0 20px;
  }
  .shineastar .form.final-stage fieldset.section-5 {
    padding-top: 10px;
    top: 0;
    position: static;
  }
  .shineastar .form.final-stage .form-output {
    height: 0px;
  }
  .shineastar .form input,
  .shineastar .form textarea,
  .shineastar .form select {
    padding: 5.5px 12.5px;
  }
  .shineastar .form select {
    margin-top: 10px;
  }
  .shineastar .form p {
    margin-bottom: 0;
  }
  .shineastar .form h2 {
    margin-bottom: 12.5px;
  }
  .shineastar .allstar {
    min-height: 900px;
  }
}

@media screen and (max-width: 1023px) and (max-width: 47.99em) {
  .shineastar .allstar {
    min-height: 650px;
  }
}

@media screen and (max-width: 1023px) {
  .shineastar .allstar .button-container {
    width: 75px;
    height: 75px;
  }
  .shineastar .allstar .button-container .button-nav {
    width: 75px;
    height: 75px;
    background-size: 30px;
  }
}

@media screen and (max-width: 767px) {
  .shineastar .form #donate-title {
    font-size: 21px;
    line-height: 24px;
    max-width: 85%;
    padding-top: 10px;
  }
  .shineastar .shineastar-logo {
    background-position: 0 0, 100% 0 !important;
    background-size: 340px, 340px !important;
  }
  .shineastar .shineastar-logo .main-logo {
    display: none;
  }
  .shineastar .form form {
    width: 85%;
  }
  .shineastar .form p {
    font-size: 16px;
    line-height: 17px;
  }
  .shineastar .allstar {
    min-height: 650px;
  }
}

@media screen and (max-width: 565px) {
  .shineastar header {
    position: absolute;
  }
  .shineastar header.site-header.scrolled .both-logo .site-logo {
    opacity: 1;
    width: 128px;
    height: 70px;
  }
  .shineastar header.site-header.scrolled .both-logo .site-logo-fixed {
    opacity: 0;
  }
  .shineastar header.site-header.scrolled .social-donate {
    height: 100px;
  }
  .shineastar .donate h1,
  .shineastar .form.final-stage form.final-stage-form h1,
  .shineastar h1 {
    font-size: 34px;
    padding: 0;
    line-height: 41px;
  }
  .shineastar .form.final-stage form.final-stage-form p,
  .shineastar p {
    font-size: 18px;
    line-height: 21px;
  }
  .shineastar .form.final-stage form.final-stage-form .section-5 {
    padding-top: 0;
  }
  .shineastar .form.final-stage .form-output {
    height: 0;
  }
  .shineastar .form .form-wrapper.fieldset-3-active .form-output svg {
    width: 300px;
  }
  .shineastar .big-button.smaller {
    padding: 9.5px 20px;
  }
  .shineastar .form .form-step-container .form-step {
    height: 25px;
    width: 25px;
    border: 3px solid #3474BD;
    font-size: 12px;
  }
  .shineastar .form form {
    width: 100%;
  }
  .shineastar .form h2 {
    font-size: 20px;
    text-align: center;
  }
  .shineastar .form p,
  .shineastar .form span {
    font-size: 13px;
  }
  .shineastar .form p,
  .shineastar .form span,
  .shineastar .form select {
    text-align: center;
  }
  .shineastar .form input,
  .shineastar .form textarea {
    font-size: 16px;
  }
  .shineastar .form .form-wrapper .form-output .form-output-field h3 {
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 10px;
  }
  .shineastar .form .form-wrapper .form-output .form-output-field p {
    margin-bottom: 5px;
    font-size: 12px;
  }
  .shineastar .form .form-wrapper .form-output .form-output-field h4 {
    font-size: 14px;
  }
  .shineastar .form.final-stage .form-inside .form-wrapper {
    padding: 0;
  }
  .shineastar .form.final-stage .form-inside p {
    margin-bottom: 15px;
  }
  .shineastar .allstar .allstars-inside .large-image {
    min-width: 200px;
  }
  .shineastar .allstar .allstars-inside .large-image svg {
    max-width: 300px;
  }
}

@media (max-width: 29.99em) {
  .shineastar header {
    position: absolute;
  }
  .shineastar header.site-header .both-logo {
    width: 80px;
  }
  .shineastar header.site-header .both-logo a {
    width: 70px;
  }
  .shineastar header.site-header .both-logo .site-logo {
    text-indent: 300%;
    width: 70px;
  }
  .shineastar .shineastar-logo {
    background-size: 340px, 340px !important;
  }
  .shineastar header.site-header .social-donate {
    height: 50px;
  }
  .shineastar .site-nav-donate {
    width: 73px;
    font-size: 14px;
    border: 0;
    line-height: 26px;
  }
  .shineastar .form .form-inside {
    padding: 10px;
  }
  .shineastar .form .section-4 .flex {
    margin: 0 0 5px;
  }
  .shineastar .form select {
    margin-bottom: 5px;
  }
  .shineastar .form input[type=email] {
    margin-bottom: 5px;
  }
  .shineastar .form.final-stage form.final-stage-form input[type=text],
  .shineastar .form.final-stage form.final-stage-form input[type=number] {
    font-size: 42px;
    line-height: 48px;
  }
  .shineastar .form.final-stage form.final-stage-form h1 {
    font-size: 22px;
    line-height: 24px;
  }
  .shineastar .form.final-stage form.final-stage-form p {
    font-size: 16px;
    line-height: 18px;
  }
  .shineastar .form.final-stage form.final-stage-form #nodonation {
    font-size: 14px;
  }
  .shineastar .form.final-stage form.final-stage-form [type="radio"]:checked + label.donation-single-label,
  .shineastar .form.final-stage form.final-stage-form [type="radio"]:checked + label.donation-single-label {
    margin-right: 0;
  }
  .shineastar .allstar .allstars-inside .large-image svg {
    max-width: 285px;
  }
}

@media screen and (max-width: 390px) {
  .shineastar .allstar .allstars-inside .large-image {
    padding-left: 0;
  }
  .shineastar .allstar .allstars-inside .large-image svg {
    max-width: 275px;
    width: 100%;
  }
}

.shineastar .inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.shineastar .inputfile + label {
  max-width: 80%;
  font-size: 1.25rem;
  /* 20px */
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: 0.625rem 1.25rem;
  /* 10px 20px */
  border: 0;
  clip: auto;
  height: auto;
  margin: auto;
  position: static;
  width: auto;
  text-align: right;
  display: flex;
  flex-direction: row-reverse;
  max-width: 100%;
  background: #0B3157;
  color: #FAC52C;
  padding: 0;
}

.shineastar .inputfile + label span {
  background: white;
  color: red;
  flex-grow: 1;
  text-align: left;
  padding: 20px;
  color: #0B3157;
  font-weight: 400;
  font-size: 20px;
}

.shineastar .inputfile + label strong {
  padding: 20px 30px;
  text-transform: uppercase;
}

.shineastar .no-js .inputfile + label {
  display: none;
}

.shineastar .inputfile:focus + label,
.shineastar .inputfile.has-focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.shineastar .inputfile + label * {
  /* pointer-events: none; */
  /* in case of FastClick lib use */
}

.shineastar .inputfile + label svg {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
  margin-top: -0.25em;
  /* 4px */
  margin-right: 0.25em;
  /* 4px */
}

.shineastar .inputfile-6 + label {
  color: #d3394c;
}

.shineastar .inputfile-6 + label {
  border: 1px solid #d3394c;
  background-color: #f1e5e6;
  padding: 0;
}

.shineastar .inputfile-6:focus + label,
.shineastar .inputfile-6.has-focus + label,
.shineastar .inputfile-6 + label:hover {
  border-color: #722040;
}

.shineastar .inputfile-6 + label span,
.shineastar .inputfile-6 + label strong {
  padding: 0.625rem 1.25rem;
  /* 10px 20px */
}

.shineastar .inputfile-6 + label span {
  width: 200px;
  min-height: 2em;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: top;
}

.shineastar .inputfile-6 + label strong {
  height: 100%;
  color: #f1e5e6;
  background-color: #d3394c;
  display: inline-block;
}

.shineastar .inputfile-6:focus + label strong,
.shineastar .inputfile-6.has-focus + label strong,
.shineastar .inputfile-6 + label:hover strong {
  background-color: #722040;
}

@media screen and (max-width: 50em) {
  .shineastar .inputfile-6 + label strong {
    display: block;
  }
}

.shineastar .cbx {
  margin: auto;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  border: 0;
  clip: auto;
  height: auto;
  overflow: hidden;
  padding: auto;
  position: auto;
  width: auto;
}

.shineastar .cbx span {
  display: inline-block;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}

.shineastar .cbx span:first-child {
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 0px;
  transform: scale(1);
  vertical-align: middle;
  border: 0;
  background: #fff;
  transition: all .2s ease;
}

.shineastar .cbx span:first-child svg {
  position: absolute;
  top: 3px;
  left: 2px;
  fill: none;
  stroke: #FFFFFF;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all .3s ease;
  transition-delay: .1s;
  transform: translate3d(0, 0, 0);
}

.shineastar .cbx span:first-child:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #0B3157;
  display: block;
  transform: scale(0);
  opacity: 1;
  border-radius: 50%;
}

.shineastar .cbx span:last-child {
  padding-left: 8px;
}

.shineastar .cbx:hover span:first-child {
  border-color: #0B3157;
}

.shineastar .inp-cbx:checked + .cbx span:first-child {
  background: #0B3157;
  border-color: #0B3157;
  animation: wave 0.4s ease;
}

.shineastar .inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}

.shineastar .inp-cbx:checked + .cbx span:first-child:before {
  transform: scale(3.5);
  opacity: 0;
  transition: all 0.6s ease;
}

@keyframes wave {
  50% {
    transform: scale(0.9);
  }
}

.shineastar [type="radio"]:checked,
.shineastar [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.shineastar [type="radio"]:checked + label,
.shineastar [type="radio"]:not(:checked) + label {
  margin: auto;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  border: 0;
  clip: auto;
  height: auto;
  margin: auto;
  overflow: hidden;
  padding: auto;
  position: auto;
  width: auto;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 24px;
  display: inline-block;
  color: #fff;
}

.shineastar [type="radio"]:checked + label.donation-single-label,
.shineastar [type="radio"]:not(:checked) + label.donation-single-label {
  margin-right: 15px;
}

.shineastar [type="radio"]:checked + label:before,
.shineastar [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 22px;
  height: 22px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}

.shineastar [type="radio"]:checked + label:after,
.shineastar [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 14px;
  height: 14px;
  background: #E5007A;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
}

.shineastar [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.shineastar [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.shineastar html.no-cssgrid .shineastar .allstar .allstars-inside {
  display: flex;
}

.shineastar html.no-cssgrid .shineastar .allstar .allstars-inside .large-image {
  max-width: 650px;
}

.shineastar html.no-cssgrid .shineastar .allstar .allstars-inside .large-image img {
  width: 650px;
  height: 628px;
}

.shineastar:not(.matched-donation) .matched-donation-visible {
  display: none !important;
}

.shineastar .donation-details-flex {
  display: flex;
}

.shineastar.matched-donation .matched-donation-hide {
  display: none !important;
}

.shineastar.matched-donation .matched-donation-visible {
  display: initial;
}

.shineastar.matched-donation .matched-donation-explanation {
  text-align: center;
}

.shineastar.matched-donation .form.final-stage .form-inside .form-wrapper .donate-inside .donation-details-container {
  display: block;
  border-color: #FAC52C;
  margin-bottom: 10px;
  max-width: 97%;
  width: 475px;
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  .shineastar.matched-donation .form.final-stage .form-inside .form-wrapper .donate-inside .donation-details-container {
    width: 300px;
    border: 0;
    padding: 0;
  }
}

.shineastar.matched-donation .form.final-stage .form-inside .form-wrapper .donate-inside .donation-details-container img {
  max-width: 100%;
}

.shineastar.matched-donation .form.final-stage .form-inside .form-wrapper .donate-inside .donation-details-container::before {
  border-color: transparent transparent #FAC52C transparent;
}

.shineastar.matched-donation .form.final-stage .form-inside .site-nav-donate {
  line-height: 2.5;
}

.shineastar.matched-donation .form.final-stage .form-inside p {
  margin-bottom: 25px;
  display: block;
}

.shineastar.matched-donation .donate p {
  display: block;
  max-width: 732px;
}

.shineastar.matched-donation .donate .site-nav-donate {
  display: none;
}

@media screen and (max-width: 768px) {
  .shineastar.matched-donation .donate .site-nav-donate {
    line-height: 1.25;
  }
}

.shineastar.matched-donation .donate .site-nav-donate.matched-donation-visible {
  display: inline-block;
  margin-top: 15px;
}

.shineastar.matched-donation .donate .donate-inside .donation-details2-container {
  border-color: #FAC52C;
  background: #0B3157;
  position: relative;
  z-index: 2;
}

@media screen and (max-width: 767px) {
  .shineastar.matched-donation .donate .donate-inside .donation-details2-container {
    border: 0;
    background: transparent;
  }
  .shineastar.matched-donation .donate .donate-inside .donation-details2-container::before {
    border: 0;
  }
}

.shineastar.matched-donation .donate .donate-inside .donation-details2-container::before {
  border-color: transparent transparent #FAC52C transparent;
}

.shineastar .donation-amount-other-label .mobile {
  display: none;
}

.shineastar .donation-amount-other-label .desktop {
  display: inline;
}

@media screen and (max-width: 767px) {
  .shineastar .donation-amount-first,
  .shineastar .donation-amount2-first,
  .shineastar .donation-amount-second,
  .shineastar .donation-amount2-second,
  .shineastar .donation-amount-third,
  .shineastar .donation-amount2-third {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .shineastar .donation-amount-other-label .mobile {
    display: inline;
    color: #0B3B64 !important;
  }
  .shineastar .donation-amount-other-label .desktop {
    display: none;
  }
  .shineastar .donation-details-second,
  .shineastar .donation-details2-second {
    display: none !important;
  }
  .shineastar .donation-details-other,
  .shineastar .donation-details2-other {
    display: block !important;
    margin: 0 auto;
  }
  .shineastar .donation-details-other p,
  .shineastar .donation-details2-other p {
    margin: 0 auto !important;
  }
}

.shineastar .ie-error-p {
  display: none;
}

.shineastar.ie-error main section,
.shineastar.ie-error .site-header {
  display: none;
}

.shineastar.ie-error main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.shineastar.ie-error .ie-error-p {
  width: 650px;
  max-width: 95%;
  text-align: center;
  margin: 0 auto;
}
