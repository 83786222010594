.starOverlay {
    opacity: 0;
    position: fixed;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    transition: .4s ease opacity;
    pointer-events: none;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(black, .8);
    z-index: 999999;

    &.active {
        opacity: 1;
        pointer-events: auto;
    }

    &__Inner {
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url("../img/shineastar/StarShape.png");
        width: 320px;
        height: 320px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @include mq($from: tablet) {
            width: 560px;
            height: 560px;
        }

        h3,
        h4 {
            margin: 0 auto 5px;
            color: $yellow;
            font-size: 13px;
            line-height: 16px;
            max-width: 150px;
            text-align: center;

            @include mq($from: tablet) {
                font-size: 22px;
                line-height: 25px;
                margin: 0 auto 10px;
                max-width: 350px;
            }
        }

        h3 {
            padding-top: 10px;

            @include mq($from: tablet) {
                padding-top: 25px;
            }
        }

        p {
            margin: 0 auto 5px;
            max-width: 150px;
            font-size: 10px;
            line-height: 12px;
            text-align: center;

            @include mq($from: tablet) {
                font-size: 18px;
                line-height: 20px;
                margin: 0 auto 10px;
                max-width: 280px;
            }
        }

        h4 {
            margin-bottom: 0;

            @include mq($from: tablet) {
                max-width: 250px;
            }
        }
    }
}
